import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { SpanCustom } from 'components/HelpPermission/HelpPermission';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { validationInformation } from 'helpers/formats/StringFormats';
import React, { ReactNode } from 'react';
import { IVinculoFuncional } from 'services/datasets/datasets.interface';

type TooltipWarrantyProductSelectedProps = {
    values: IVinculoFuncional;
    rubrica: {
        DsRubrica: string;
        CdRubrica: string;
    };
};

interface IChildren {
    children: ReactNode;
}

export const TooltipSiape: React.FC<TooltipWarrantyProductSelectedProps> = ({
    values,
    rubrica,
}) => {
    const ListItem = ({ children }: IChildren) => {
        return <Typography style={{ margin: '5px 0 ', fontSize: '14px' }}>{children}</Typography>;
    };

    return (
        <Stack sx={{ margin: '5px 0' }} spacing={2}>
            <Typography variant="md" fontWeight="bold" textAlign="center">
                Informações
            </Typography>

            <ListItem>
                <SpanCustom>Nome: </SpanCustom>
                {validationInformation(values?.NmOrgao)}
            </ListItem>

            <ListItem>
                <SpanCustom>Código do órgão: </SpanCustom>
                {validationInformation(values?.CodOrgao)}
            </ListItem>

            <ListItem>
                <SpanCustom>CNPJ: </SpanCustom>
                {validationInformation(formatDocumentNumber(values?.CnpjOrgao) ?? 'N/D')}
            </ListItem>

            <ListItem>
                <SpanCustom>Siatuação funcional: </SpanCustom>
                {validationInformation(values?.CodSituacaoFuncional)}:{' '}
                {validationInformation(values?.DescSituacaoFuncional)}
            </ListItem>
            <ListItem>
                <SpanCustom>Código de classificação: </SpanCustom>
                {validationInformation(values?.CodClassificacao)}
            </ListItem>
            <ListItem>
                <SpanCustom>Descrição de classificação: </SpanCustom>
                {validationInformation(values?.DescClassificacao)}
            </ListItem>
            <ListItem>
                <SpanCustom>Código rúbrica: </SpanCustom>
                {validationInformation(rubrica?.CdRubrica)}
            </ListItem>
            <ListItem>
                <SpanCustom>Rúbrica: </SpanCustom>
                {validationInformation(rubrica?.DsRubrica)}
            </ListItem>
        </Stack>
    );
};
