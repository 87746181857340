import { FormMode, FormProvider } from 'contexts/formContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ApiResponseError,
    IBase,
    ToastType,
    showErrorToast,
    showSuccessToast,
    showWarningToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { RefreshProgress } from 'components/RefreshProgress';
import { Error } from 'components/Errors/Error';
import CreditProductForm from './CreditProductForm';
import {
    CreditProductFormSchema,
    defaultValuesCreditProductForm,
    validationSchemaCreditProductForm,
} from './CreditProductSchema';
import { useCreditProductMutation, useCreditProductData } from 'contexts/creditProductContext';
import React, { useState } from 'react';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { createCreditProduct, deleteCreditProductById } from 'services/creditProduct/creditProduct';
import { useIdentity } from 'contexts/identityContext';
import AssignDrawer from 'components/Assign/AssignDrawer';
import { Button, DeleteIcon, Modal, Popup } from '@uy3/web-components';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import Toast from 'components/Toast/Toast';
import { activeTheme } from 'services/theme';
import { GenericFormSkeleton } from 'components/Skeleton/GenericFormSkeleton';
import { useUserPermissionData } from 'contexts/userContext';
import { FieldValues } from 'react-hook-form';
import { CloneRegisterContainer } from 'components/CloneRegister/CloneRegisterContainer';
import { hasCustomPermission } from 'helpers';
import { CreditProductReadModel } from 'services/creditProduct/types/creditProductReadModel';
import { useTenant } from 'contexts/tenantContext';
import { PayrollAgreementsList } from './Tabs/PayrollAgreements/PayrollAgreementsList';

const theme = activeTheme();
export const CreditProductFormContainer = () => {
    const { token } = useIdentity();
    const { productId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [action, setAction] = useState<string | undefined>(undefined);
    const formMode: FormMode = productId !== 'novo' ? 'update' : 'create';
    const {
        creditProductData,
        creditProductErr,
        creditProductStatus,
        refetch,
        creditProductFetch,
        creditProductLoading,
    } = useCreditProductData(productId!);
    const { submitError, submitting, setSubmitError } = useApiRequest();
    const { setFormFieldsErrors } = useFormFieldsError();
    const { hasPermission } = useUserPermissionData();
    const { isRootTenancy } = useTenant();

    const onClose = () => setAction(undefined);

    const isReadOnly = (): boolean => {
        const resource = 'CreditProduct';
        const typePermission = formMode === 'create' ? 'Create' : 'Update';
        const permissionCustom = hasCustomPermission(resource, typePermission, hasPermission);

        if (!permissionCustom || !isRootTenancy) return true;
        return false;
    };

    const navigate = useNavigate();

    const onCloneCreditProduct = async (values: FieldValues) => {
        const currentData = {
            ...creditProductData,
            name: values?.keepSameName === true ? creditProductData?.name : values?.name,
        } as CreditProductFormSchema & CreditProductReadModel;
        return await createCreditProduct(
            currentData as CreditProductFormSchema & CreditProductReadModel,
            values?.tenant!,
            token!
        )
            .then(({ data }: any) => {
                const successTitle = 'Produto clonado com sucesso';
                const descriptionSuccess =
                    'Em aluns instantes você será redirecionado para o produto clonado.';
                showSuccessToast(successTitle, descriptionSuccess, setToast);
                onClose();
                return setTimeout(() => {
                    navigate(`/cadastro/produtos-credito/${data!}`);
                    setToast({ open: false, severity: 'success', title: successTitle });
                }, 2000);
            })
            .catch((error: ApiResponseError) => {
                const { errorMessage } = mapErrorResponse(error as ApiResponseError);
                showErrorToast('Ops, ocorreu um erro ao clonar o produto', errorMessage, setToast);
            });
    };

    const onSuccess = (data: any) => {
        if (formMode === 'create') {
            const id = data?.id ?? data !== '' ? data : productId;
            navigate(`/cadastro/produtos-credito/${id}`);
        }
        if (productId !== 'novo') {
            refetch();
        }
        setSubmitError(undefined);
        setFormFieldsErrors([]);
        setToast({
            title: 'Produto de Crédito salvo com sucesso!',
            severity: 'success',
            open: true,
        });
    };

    const onError = (response: ApiResponseError) => {
        const { errorMessage, fieldErrors, warningToastError } = mapErrorResponse(response);
        setSubmitError(response);
        setFormFieldsErrors(fieldErrors ?? []);
        if (warningToastError && warningToastError?.length > 0) {
            const title = 'Atenção!';
            showWarningToast(title, warningToastError, setToast);
        } else {
            const title = 'Ops, ocorreu um erro!';
            const description = errorMessage;
            showErrorToast(title, description, setToast);
        }
    };

    const { mutateAsync } = useCreditProductMutation(productId!, onSuccess, onError);

    const onSubmit = async (values: CreditProductFormSchema) => {
        const fieldName: Array<keyof CreditProductFormSchema> = [
            'timeLimitToSubmitApproval',
            'timeLimitToApprove',
            'timeLimitToSign',
            'timeToSendToSignatures',
        ];

        var data = { ...values };
        fieldName.forEach((item) => {
            if (data[item] !== null && data[item] !== undefined) {
                //@ts-ignore
                data[item] = parseInt(data[item].replace(/[^0-9]/g, ''));
            }
        });

        const payrollAgreementIds = values?.payrollAgreements?.map((x) => x.id);

        const payload: CreditProductFormSchema = {
            ...data,
            payrollAgreementIds,
        };

        await mutateAsync(payload);
    };

    const schema = validationSchemaCreditProductForm();
    const defaultValues = creditProductData ?? defaultValuesCreditProductForm;

    if (creditProductStatus === 'error') return <Error error={creditProductErr} />;

    const onDeleteProduct = async () => {
        await deleteCreditProductById(productId!, token!)
            .then(() => {
                navigate(`/cadastro/produtos-credito`);
            })
            .catch((response: ApiResponseError) => {
                setSubmitError(response);
            });
    };

    return (
        <GenericFormSkeleton isLoading={creditProductLoading && formMode === 'update'}>
            <React.Fragment>
                <Toast toast={toast} setToast={setToast} />
                <ApiErrorAlert error={submitError} />
                <RefreshProgress refreshing={submitting} />
                <FormProvider
                    validationSchema={schema}
                    defaultValues={defaultValues}
                    onSubmit={onSubmit}
                    readOnly={isReadOnly()}
                >
                    <CreditProductForm
                        setAction={setAction}
                        hasPermission={hasPermission}
                        mode={formMode}
                        productId={productId!}
                        isFetching={creditProductFetch}
                        refetch={refetch}
                        setToast={setToast}
                    />
                </FormProvider>
                <AssignDrawer
                    recordId={productId!}
                    recordType="Product"
                    openDrawer={action === 'assign'}
                    onClose={onClose}
                    navigate="/cadastro/produtos-credito"
                />
                <Popup
                    open={action === 'delete'}
                    onClose={onClose}
                    title="Excluir Produto"
                    text="Tem certeza que deseja excluir este produto?"
                    children={
                        <Grid2 container justifyContent="flex-end" spacing={2}>
                            <Grid2>
                                <Button variant="outlined" onClick={onClose}>
                                    Cancelar
                                </Button>
                            </Grid2>
                            <Grid2>
                                <Button
                                    variant="contained"
                                    startIcon={
                                        <DeleteIcon htmlColor={theme.palette.common.white} />
                                    }
                                    onClick={onDeleteProduct}
                                >
                                    Excluir
                                </Button>
                            </Grid2>
                        </Grid2>
                    }
                />

                <Modal
                    open={action === 'cloneProduct'}
                    align="left"
                    title="Clonar produto"
                    onClose={onClose}
                    description="Realize uma cópia do produto, com a possibilidade de manter ou alterar o nome do mesmo."
                    children={
                        <CloneRegisterContainer
                            data={creditProductData! as IBase}
                            onClose={onClose}
                            resource="product"
                            onSubmit={(values: FieldValues) => onCloneCreditProduct(values)}
                        />
                    }
                />
            </React.Fragment>
        </GenericFormSkeleton>
    );
};
