import { FieldValues } from 'react-hook-form';
import { object, string } from 'yup';

export function updateCommunicationSettingSchema(row: FieldValues) {
    return object().shape({
        communicationSettingId: string()
        .typeError('Nova régua de comunicação: precisa ser selecionada')
        .required('Nova régua de comunicação: precisa ser selecionada') 
        .test('equal-wallet', 'Selecione uma régua diferente da atual', (value: any) => {            
            if (value === row?.communicationSettingId?.toString()) return false
            return true ;
        })
    });
}

export const defaultValuesUpdateCommunicationSettingSchema = {
    communicationSettingId: null
};
