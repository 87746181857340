import { Stack } from '@mui/material';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { DatePickerFormField, TextFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { useFormContext } from 'contexts/formContext';

type ChangeCalendarFormProps = {
    payrollLoanList: AutocompleteOptionList;
};

const ChangeCalendarForm: React.FC<ChangeCalendarFormProps> = ({ payrollLoanList }) => {
    const { watch } = useFormContext();

    const creditNoteId = watch('creditNoteId');
    return (
        <Stack spacing={2}>
            <AutocompleteField
                name="creditNoteId"
                label="Operação de crédito"
                displayName="creditNoteIdDisplay"
                required
                {...payrollLoanList!}
            />

            {creditNoteId && (
                <>
                    <DatePickerFormField
                        name="referenceDate"
                        label="Data de referência"
                        fullWidth
                        required
                    />
                    <TextFormField
                        name="numberOfDays"
                        label="Número de dias"
                        type="number"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                        fullWidth
                        inputProps={{ min: 0, max: 1000 }}
                    />
                </>
            )}
        </Stack>
    );
};

export default ChangeCalendarForm;
