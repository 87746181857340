import { boolean, object, string } from 'yup';


export function validationSchemaConcilicaoLiquidacao() {
    return object().shape({
        transferDate: string()
            .required('Data repasse: precisa ser preenchida')
            .typeError('Data repasse: precisa ser preenchida'),
        dueDate: string().nullable(),
        persist: boolean().nullable(),
        earlySettilement: boolean().nullable(),
        queryCaixa: boolean().nullable()
    });
};

export const defaultValuesConcilicaoLiquidacao = {
    transferDate: null,
    dueDate: null,
    persist: false,
    earlySettilement: false,
    queryCaixa: false
};