import { DataTable } from '@uy3/web-components';
import { GridColDef } from '@mui/x-data-grid';
import { formatCurrencyInCents, formatDate } from 'helpers';
import { CalculateDuePaymentResponse } from 'services/creditNote';
import { useState } from 'react';

interface ChangeCalendarListProps {
    queryData: CalculateDuePaymentResponse[];
}

const ChangeCalendarList: React.FC<ChangeCalendarListProps> = ({ queryData }) => {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const columns: GridColDef[] = [
        {
            field: 'liquidationDate',
            headerName: `Data de quitação`,
            hideSortIcons: true,
            minWidth: 60,
            editable: false,
            renderCell: ({ row }) => formatDate(row.liquidationDate),
        },
        {
            field: 'dueValue',
            headerName: `Valor devido`,
            hideSortIcons: true,
            minWidth: 110,
            editable: false,
            renderCell: ({ row }) => formatCurrencyInCents(row.dueValue),
        },
        {
            field: 'liquidValue',
            headerName: 'Valor líquido',
            hideSortIcons: true,
            editable: false,
            renderCell: ({ row }) => formatCurrencyInCents(row.liquidValue),
        },
        {
            field: 'changeValue',
            headerName: 'Valor de troco',
            hideSortIcons: true,
            minWidth: 140,
            editable: false,
            renderCell: ({ row }) => formatCurrencyInCents(row.changeValue),
        },
    ];

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    return (
        <DataTable
            columns={columns}
            rows={queryData}
            getRowId={(row) => Math.random().toString()}
            page={page}
            rowCount={queryData?.length}
            rowsPerPage={rowsPerPage}
            paginationMode="client"
            setPage={setPage}
            setRowsPerPage={onChangeRowsPerPage}
            enableJumpAction={false}
            sx={{
                '& .MuiDataGrid-cell:first-child': {
                    fontWeight: 800,
                },
            }}
        />
    );
};

export default ChangeCalendarList;
