import { Stack } from '@mui/material';
import { Button, CardData, CloseIcon, Typography } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react';
import { activeTheme } from 'services/theme';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { IServidores } from 'services/zetra';

type SiapeMarginDetailsProps = {
    data: IServidores;
    onClose: () => void;
};

const theme = activeTheme();
export const ZetraMarginQueryDetails: React.FC<SiapeMarginDetailsProps> = ({ data, onClose }) => {
    const margensLength = data?.margens?.length;

    return (
        <Stack spacing={2}>
            <Typography variant="h5" fontSize={20} fontWeight="bold">
                Informações
            </Typography>

            <CardData
                listItem={[
                    { id: '1', title: 'Código órgão', value: data?.orgaoCodigo ?? 'N/D' },
                    { id: '3', title: 'Matrícula', value: data?.matricula ?? 'N/D' },

                    {
                        id: '4',
                        title: 'Cidade/UF',
                        value: data?.cidade && data?.uf ? `${data?.cidade}/${data?.uf}` : 'N/D',
                    },
                    { id: '5', title: 'Banco', value: data?.banco ?? 'N/D' },
                    { id: '6', title: 'Agência', value: data?.agencia ?? 'N/D' },
                    { id: '7', title: 'Conta', value: data?.conta ?? 'N/D' },
                ]}
            />

            <Stack spacing={2}>
                <Typography variant="h5" fontSize={20} fontWeight="bold">
                    {margensLength > 1 ? `Margens (${margensLength})` : 'Margen'}
                </Typography>
                {data?.margens.map((x) => {
                    return (
                        <CardData
                            listItem={[
                                { id: '1', title: 'Código do Orgão', value: x.codigo ?? 'N/D' },
                                {
                                    id: '2',
                                    title: 'Valor margem disponível',
                                    value: formatCurrencyInCents(x.valorDisponivel),
                                },
                            ]}
                        />
                    );
                })}
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="right">
                <Button
                    startIcon={<CloseIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
                    onClick={onClose}
                    variant="outlined"
                >
                    Fechar
                </Button>
            </Stack>
        </Stack>
    );
};
