import { Grid, ListItem, ListItemAvatar, ListItemText, Stack, styled } from '@mui/material';
import { Alert, Avatar, BankingIcon, CalendarIcon, LegalPersonIcon, MoneyIcomeIcon, Typography } from '@uy3/web-components';
import {
    AccountBalanceOutlined,
    AccessAlarmOutlined,
    Brightness4Outlined,
    Update,
    AccountBalance,
    TrendingUp,
    Timeline as TimelineIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { AmortizationReadModel } from 'services/creditNote/types/creditNoteReadModel';
import { useFormContext } from 'contexts/formContext';
import { Theme } from '@emotion/react';
import { IconView } from 'components/AuthenticatedLayout/Menu/SideMenu';
import { activeTheme } from 'services/theme';
import './index.css';
import { useAppConfig } from 'contexts/appConfig';
import { useLocation } from 'react-router';
import { iconSx } from 'contexts/apiRequestContext';
import { useCreditNoteFormContext } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import GetLabelForField from 'components/GetLabelForField';
import { isEmpty } from 'lodash';
import { getDefaultValueByFieldName, dateDiff, formatCurrencyInCents, formatPercentage, formatPercentageWithPlaces } from 'helpers';
import _ from 'lodash';

const StyledInfo = styled('div')(() => ({
    '@media print': {
        width: '700px',
        margin: 'auto'
    },
    width: '100%',
    margin: '50px 0'
}));

const ShowElements = styled('div')(() => ({
    '@media print': {
        display: 'block'
    },
    display: 'none'
}));

interface IResultOfOpProps {
    simulationResponse: AmortizationReadModel;
    removedFromPrintList?: string[];
    simulationId?: string;
};

export const muiListItemTextStyle = (theme: Theme) => {
    return {
        '& .MuiListItemText-primary': {
            fontSize: { xs: '16px', md: '16px' },
            fontWeight: { xs: '400', md: '400' },
            fontStyle: { xs: 'normal', md: 'normal' },
            lineHeight: { xs: '16px', md: '22px' },
            fontFamily: { xs: '"Lato", sans-serif' },
        },
        '& .MuiListItemText-secondary': {
            fontSize: { xs: '16px', md: '16px' },
            fontWeight: { xs: '400', md: '400' },
            fontStyle: { xs: 'normal', md: 'normal' },
            lineHeight: { xs: '2rem', md: '2rem' },
            fontFamily: { xs: '"Lato", sans-serif' },
            mt: 0.5,
        },
    };
};

const theme = activeTheme();
const AmortizationQuery: React.FC<IResultOfOpProps> = ({
    simulationResponse,
    removedFromPrintList,
    simulationId
}) => {
    const { getValues } = useFormContext();
    const { product } = useCreditNoteFormContext();
    const amortizationType = product?.amortizationType?.toLowerCase() ?? 'cleanprice';
    const { appConfig } = useAppConfig();
    const useManualCalculation = appConfig.USE_MANUAL_CALCULATION === 1;
    const location = useLocation();
    const isMenuSimular = location.pathname.includes('simular');

    const formFieldsConfig = appConfig?.FORM_FIELDS;
    const defaultValueByConfig = getDefaultValueByFieldName("numberOfPayments", formFieldsConfig);
    const hasMonthlyDefaultValue = !isEmpty(defaultValueByConfig) && defaultValueByConfig === 'Monthly';

    const getValuesIndexer = ({ hasPlus, isIndexerValue }: { hasPlus: boolean, isIndexerValue?: boolean }) => {
        const isIndexer = getValues('amortization.indexerValue');
        if (isIndexer) {
            const indx = getValues('amortization.indexer');
            return `${hasPlus ? '+ ' : ''}${isIndexerValue ? isIndexer * 100 : isIndexer}% ${indx}`;
        } else if (hasPlus) {
            return '';
        }
        return 'Sem indexador pós-fixado';
    };

    const getPeriodDescription = () => {
        const first = simulationResponse?.startDate;
        const last = simulationResponse?.dueDate;

        return dateDiff(first, last);
    };

    const showTermValue = (): string => {
        if (hasMonthlyDefaultValue && simulationResponse.periodicity === "Monthly") {
            return `${simulationResponse.numberOfPayments} meses`;
        }
        return getPeriodDescription();
    }

    const isFieldsCommHidden = (fieldName: string): boolean | undefined => {
        const fieldValue = _.get(formFieldsConfig, fieldName);
        return fieldValue?.Hidden;
    }
    
    var paymentScheduleItems = simulationResponse?.paymentScheduleItems ?? [] 
    const totalValuePaymentScheduleItems = paymentScheduleItems?.length && paymentScheduleItems?.map((x) => x.payment)?.reduce((a, b) => a + b);

    const isHiddenGrossValue = isFieldsCommHidden("amortization.grossValue");
    const isHiddenCetAM = isFieldsCommHidden("amortization.cet_am");
    const isHiddenContractValue = isFieldsCommHidden("amortization.initialValue");
    const isHiddenComm = isFieldsCommHidden("amortization.comm");
    const isHiddenCommTc = isFieldsCommHidden("amortization.commTc") ?? true;
    const isHiddenCommNonTc = isFieldsCommHidden("amortization.commNonTc") ?? true;

    const simultationResponseEmpty = !Object.values(simulationResponse || {}).filter(item=> item).length

    return (
        <>
            <ShowElements>
                <Grid sx={{ display: 'block', margin: 'auto', width: 260 }}>{
                    IconView(
                        //@ts-ignore
                        theme?.components?.Icon, 260
                    )}
                </Grid>
            </ShowElements>
            <ShowElements>
                <Typography
                    variant='h5'
                    sx={{
                        display: 'block',
                        textAlign: 'center'
                    }}
                >
                    Id da simulação: {simulationId}
                </Typography>
            </ShowElements>

            <ShowElements>
                {simulationId?.length === 0 &&
                    <Typography sx={{ display: 'block', textAlign: 'center' }}>
                        Para recuperar essa simulação, use o ID
                    </Typography>
                }
            </ShowElements>

            <Grid container alignItems="center" justifyContent="center" mt={2}>
                {simultationResponseEmpty && (
                    <Alert severity="warning" text={`Clique em "Simular" para visualizar a operação! ${useManualCalculation && !isMenuSimular ? 'ou em "Alterar plano do pagamento".' : ''
                        }`} />
                )}
            </Grid>
            {!simultationResponseEmpty && (
                <>
                    <Typography
                        variant="xxl"
                        color="neutral.dark"
                        sx={{
                            lineHeight: { xs: '24px', md: '24px' },
                            fontWeight: { xs: '700', md: '700' },
                            fontStyle: { xs: 'normal', md: 'normal' },
                        }}
                    >
                        Resultados da operação
                        <Typography
                            variant="h6"
                            color="neutral.medium"
                            sx={{
                                lineHeight: { xs: '20px', md: '20px' },
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '12px',
                                opacity: '87%',
                            }}
                        >
                            Informações essenciais da operação
                        </Typography>
                    </Typography>
                    <StyledInfo>
                        <Stack direction={{ xs: 'row', md: 'row' }} justifyContent='space-evenly' mt={5} mb={5}>
                            <Grid container columns={16} direction='row' justifyContent='left' spacing={2}>
                                <Grid item sm={4} className={`${!removedFromPrintList?.includes('startDate') ? 'print-visible' : 'print-hidden'}`}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: '#E6E6E6' }}>
                                                <CalendarIcon htmlColor='#707070' sx={iconSx} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={GetLabelForField("amortization.startDate", "Data de Emissão")}
                                            sx={muiListItemTextStyle}
                                            secondary={moment(simulationResponse?.startDate).format('DD/MM/YYYY')}
                                        />
                                    </ListItem>
                                </Grid>
                                <Grid item sm={4} className={!removedFromPrintList?.includes('dueDate') ? 'print-visible' : 'print-hidden'}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: '#E6E6E6' }}>
                                                <AccessAlarmOutlined sx={{ color: '#707070', height: 20, width: 20 }} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={GetLabelForField("amortization.dueDate", "Data de Vencimento")}
                                            sx={muiListItemTextStyle}
                                            secondary={moment(simulationResponse?.dueDate).format('DD/MM/YYYY')}
                                        />
                                    </ListItem>
                                </Grid>
                                <Grid item sm={4} className={!removedFromPrintList?.includes('termInMonths') ? 'print-visible' : 'print-hidden'}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: '#E6E6E6' }}>
                                                <Brightness4Outlined sx={{ color: '#707070', height: 20, width: 20 }} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="Prazo"
                                            sx={muiListItemTextStyle}
                                            secondary={showTermValue()}
                                        />
                                    </ListItem>
                                </Grid>
                                <Grid item sm={4} className={!removedFromPrintList?.includes('indexer') ? 'print-visible' : 'print-hidden'}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: '#E6E6E6' }}>
                                                <Update sx={{ color: '#707070', height: 20, width: 20 }} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={GetLabelForField("amortization.indexerValue", "Indexador")}
                                            sx={muiListItemTextStyle}
                                            secondary={getValuesIndexer({ hasPlus: false, isIndexerValue: true })}
                                        />
                                    </ListItem>
                                    <br />
                                </Grid>
                                {!isHiddenContractValue &&
                                    <Grid item sm={4} className={!removedFromPrintList?.includes('initialValue') ? 'print-visible' : 'print-hidden'}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar sx={{ background: '#E6E6E6' }}>
                                                    <AccountBalanceOutlined sx={{ color: '#707070', height: 20, width: 20 }} />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={GetLabelForField("amortization.initialValue", "Valor do Contrato")}
                                                sx={muiListItemTextStyle}
                                                secondary={formatCurrencyInCents(simulationResponse?.initialValue)}
                                            />
                                        </ListItem>
                                    </Grid>}
                                {!isHiddenComm && <Grid item sm={4} className={!removedFromPrintList?.includes('comm') ? 'print-visible' : 'print-hidden'}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: '#E6E6E6' }}>
                                                <LegalPersonIcon htmlColor='#707070' sx={iconSx} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={GetLabelForField("amortization.comm", "Custo da emissão")}
                                            sx={muiListItemTextStyle}
                                            secondary={formatCurrencyInCents(simulationResponse?.comm)}
                                        />
                                    </ListItem>
                                </Grid>}

                                <Grid item sm={4} className={!removedFromPrintList?.includes('finTax') ? 'print-visible' : 'print-hidden'}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: '#E6E6E6' }}>
                                                <AccountBalance sx={{ color: '#707070', height: 20, width: 20 }} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={GetLabelForField("amortization.finTax", "IOF")}
                                            sx={muiListItemTextStyle}
                                            secondary={formatCurrencyInCents(simulationResponse?.finTax)}
                                        />
                                    </ListItem>
                                </Grid>

                                <Grid item sm={4} className={!removedFromPrintList?.includes('liquidValue') ? 'print-visible' : 'print-hidden'}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: '#E6E6E6' }}>
                                                <BankingIcon htmlColor='#707070' sx={iconSx} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={GetLabelForField("amortization.liquidValue", "Valor Líquido")}
                                            sx={muiListItemTextStyle}
                                            secondary={formatCurrencyInCents(simulationResponse?.liquidValue)}
                                        />
                                    </ListItem>
                                    <br />
                                </Grid>

                                {!isHiddenGrossValue &&
                                    <Grid item sm={4} className={!removedFromPrintList?.includes('grossValue') ? 'print-visible' : 'print-hidden'}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar sx={{ background: '#E6E6E6' }}>
                                                    <AccountBalanceOutlined sx={{ color: '#707070', height: 20, width: 20 }} />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={GetLabelForField("amortization.grossValue", "Valor futuro")}
                                                sx={muiListItemTextStyle}
                                                secondary={formatCurrencyInCents(totalValuePaymentScheduleItems)}
                                            />
                                        </ListItem>
                                    </Grid>}

                                {!isHiddenCommTc && <Grid item sm={4} className={!removedFromPrintList?.includes('commTc') ? 'print-visible' : 'print-hidden'}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: '#E6E6E6' }}>
                                                <LegalPersonIcon htmlColor='#707070' sx={iconSx} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={GetLabelForField("amortization.commTc", "Tarifa de Cadastro")}
                                            sx={muiListItemTextStyle}
                                            secondary={formatCurrencyInCents(simulationResponse?.commTc)}
                                        />
                                    </ListItem>
                                </Grid>}

                                {!isHiddenCommNonTc &&
                                    <Grid item sm={4} className={!removedFromPrintList?.includes('commNonTc') ? 'print-visible' : 'print-hidden'}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar sx={{ background: '#E6E6E6' }}>
                                                    <LegalPersonIcon htmlColor='#707070' sx={iconSx} />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={GetLabelForField("amortization.commNonTc", "Tarifas/Seguro")}
                                                sx={muiListItemTextStyle}
                                                secondary={formatCurrencyInCents(simulationResponse?.commNonTc)}
                                            />
                                        </ListItem>
                                    </Grid>}

                                <Grid item sm={4} className={!removedFromPrintList?.includes('monthlyInterest') ? 'print-visible' : 'print-hidden'}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: '#E6E6E6' }}>
                                                <TrendingUp sx={{ color: '#707070', height: 20, width: 20 }} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={GetLabelForField("amortization.monthlyInterest", "Taxa A.M")}
                                            sx={muiListItemTextStyle}
                                            secondary={formatPercentageWithPlaces(simulationResponse?.monthlyInterest)}
                                        />
                                    </ListItem>
                                </Grid>
                                <Grid item sm={4} className={!removedFromPrintList?.includes('yearlyInterest') ? 'print-visible' : 'print-hidden'}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: '#E6E6E6' }}>
                                                <TimelineIcon sx={{ color: '#707070', height: 20, width: 20 }} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={GetLabelForField("amortization.yearlyInterest", "Taxa A.A")}
                                            sx={muiListItemTextStyle}
                                            secondary={formatPercentage(simulationResponse?.yearlyInterest)}
                                        />
                                    </ListItem>
                                </Grid>
                                {!isHiddenCetAM && <Grid item sm={4} className={!removedFromPrintList?.includes('effectiveMonthlyCost') ? 'print-visible' : 'print-hidden'}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: '#E6E6E6' }}>
                                                <TrendingUp sx={{ color: '#707070', height: 20, width: 20 }} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={GetLabelForField("amortization.effectiveMonthlyCost", "CET A.M.")}
                                            sx={muiListItemTextStyle}
                                            secondary={formatPercentage(simulationResponse?.effectiveMonthlyCost)}
                                        />
                                    </ListItem>
                                </Grid>}
                                <Grid item sm={4} className={!removedFromPrintList?.includes('effectiveYearlyCost') ? 'print-visible' : 'print-hidden'}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: '#E6E6E6' }}>
                                                <TimelineIcon sx={{ color: '#707070', height: 20, width: 20 }} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={GetLabelForField("amortization.effectiveYearlyCost", "CET A.A.")}
                                            sx={muiListItemTextStyle}
                                            secondary={formatPercentage(simulationResponse?.effectiveYearlyCost)}
                                        />
                                    </ListItem>
                                </Grid>
                                {amortizationType === 'price' && getValues('amortization.includePaymentFixedCosts') === true && (!!product && product?.paymentFixedCosts > 0) &&
                                    <Grid item sm={4} className={!removedFromPrintList?.includes('paymentFixedCosts') ? 'print-visible' : 'print-hidden'}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar sx={{ background: '#E6E6E6' }}>
                                                    <MoneyIcomeIcon sx={{ color: '#707070', height: 20, width: 20 }} />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={GetLabelForField("addedFixedCosts", "Tarifas por parcela")}
                                                sx={muiListItemTextStyle}
                                                secondary={formatCurrencyInCents(simulationResponse?.paymentFixedCosts)}
                                            />
                                        </ListItem>
                                    </Grid>}
                            </Grid>
                        </Stack>
                    </StyledInfo>
                </>
            )}
        </>
    );
};
export default AmortizationQuery;