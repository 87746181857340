import { number } from 'yup';
import { object, string } from 'yup';

const genericMessage = 'Precisa ser preenchido.';

export function validationSchemaEditBilling() {
    return object().shape({
        documentNumber: string().required(`Número do documento: ${genericMessage}`).typeError(`Número do documento: ${genericMessage}`),
        dueDate: string().required(`Data de vencimento: ${genericMessage}`).typeError(`Data de vencimento: ${genericMessage}`),
        description: string().notRequired().nullable(),
        amount: number()
            .required(`Valor da cobrança: ${genericMessage}`)
            .typeError('Valor precisa ser um número.')
            .moreThan(-1, 'Valor inválido.')
            .min(0.01, 'Valor principal: Precisa ter um valor maior que 0 (zero).'),
        creditTypeValue: string().required(`Espécie título: ${genericMessage}`).typeError(`Espécie título: ${genericMessage}`),
        discountLimitDate: string().when('discount', {
            is: (v: number) => v > 0,
            then: string()
                .typeError('Data limite desconto precisa ser preechida.')
                .required('Data limite desconto: precisa ser preechida.'),
            otherwise: string().nullable(),
        }),
    });
}

export const defaultValuesEditBilling = {
    documentNumber: null,
    dueDate: null,
    description: null,
    amount: 0,
    creditTypeValue: null,
    discountLimitDate: null, 
    discount: null
};
