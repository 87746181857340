import { Grid, Stack, useMediaQuery } from '@mui/material';
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import {
    DatePickerFormField,
    SelectFormField,
    SelectOption,
    TextFormField,
} from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField';
import TagField from 'components/Forms/FormFields/TagField/TagField';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { cnpjMask, cpfMaskAndSpace, zipCodeMask } from 'utils/constants';
import { useFormContext } from 'contexts/formContext';
import { useCreditNoteFormContext } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import { activeTheme } from 'services/theme';
import { queryZipCodeAndFillForm } from 'services/zipCode';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { isZetraProduct } from 'services/zetra';
import { PublicPayrollForm } from '../PublicPayrollForm';

type WarrantyFormProps = {
    onClose: () => void;
    ufList: SelectOption[];
    employmentStatus: AutocompleteOptionList;
};

const theme = activeTheme();
export const WarrantyForm = ({ onClose, ufList, employmentStatus }: WarrantyFormProps) => {
    const { product } = useCreditNoteFormContext();
    const { submitting, watch, setValue } = useFormContext();
    const isZetra = isZetraProduct(product);

    const listOptionsFromAutocomplete = product?.payrollAgreements?.map((item) => {
        return {
            label: item.name,
            value: item.id,
        };
    });

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isLegalPerson = watch('payerRegistrationNumber')?.replace(/\D/g, '').length > 11;
    const watchedEmpSttsDescription = watch('employmentStatusDescription');
    const watchedWarranty = watch('warrantyType');
    const isPublicPayroll = watchedWarranty === 'PublicPayroll';

    return (
        <Stack spacing={2}>
            <SelectFormField
                name="warrantyType"
                label="Tipo de garantia"
                required
                options={[
                    { label: 'Veículo', value: 'Vehicle' },
                    { label: 'Imóvel', value: 'HomeEquity' },
                    { label: 'Processo Judicial', value: 'JudiciaryProcess' },
                    { label: 'Antecipação de Recebíveis', value: 'Factoring' },
                    { label: 'Consignado Público', value: 'PublicPayroll' },
                    { label: 'Desconto', value: 'InvoiceFactoring' },
                    { label: 'Consórcio', value: 'Consortium' },
                ]}
            />

            {isZetra && isPublicPayroll && <PublicPayrollForm />}

            {watchedWarranty === 'Factoring' && (
                <>
                    <Grid xs={3}>
                        <TextFormField
                            name="register"
                            variant="outlined"
                            fullWidth
                            label="Registradora"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <SelectFormField
                            name="type"
                            variant="outlined"
                            fullWidth
                            label="Tipo de contrato"
                            required
                            options={[
                                { label: 'Garantia', value: 'Collateral' },
                                {
                                    label: 'Troca de Titularidade',
                                    value: 'OwnershipChange',
                                },
                            ]}
                        />
                    </Grid>
                    <Grid xs={3}>
                        <SelectFormField
                            name="splitMethodType"
                            variant="outlined"
                            fullWidth
                            label="Modelo de Divisão"
                            required
                            options={[
                                { label: 'Valor absoluto', value: 'Absolute' },
                                { label: 'Percentual', value: 'Percentage' },
                            ]}
                        />
                    </Grid>
                    {watch('splitMethodType') === 'Absolute' && (
                        <Grid item xs={3}>
                            <CurrencyFormField
                                name="totalValue"
                                variant="outlined"
                                fullWidth
                                label="Valor total"
                                required
                            />
                        </Grid>
                    )}

                    {watch('splitMethodType') === 'Percentage' && (
                        <Grid item xs={3}>
                            <PercentageFormField
                                required
                                name="totalValue"
                                label="Valor total (%)"
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                    )}

                    <Grid xs={3}>
                        <SelectFormField
                            name="constituted"
                            variant="outlined"
                            fullWidth
                            label="Recebível Performado"
                            required
                            options={[
                                { label: 'Sim', value: true },
                                { label: 'Não', value: false },
                            ]}
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TagField
                            name="paymentNetworks"
                            label="Arranjos de pagamento"
                            placeholder="Adicionar arranjos de pagamento"
                            required
                        />
                    </Grid>
                </>
            )}
            {watchedWarranty === 'Vehicle' && (
                <>
                    <Grid xs={3}>
                        <TextFormField
                            name="renavam"
                            variant="outlined"
                            fullWidth
                            label="Renavam"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="typeOfVehicle"
                            variant="outlined"
                            fullWidth
                            label="Tipo de veículo"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="chassis"
                            variant="outlined"
                            fullWidth
                            label="Chassi"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="board"
                            variant="outlined"
                            fullWidth
                            label="Placa"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="supplier"
                            variant="outlined"
                            fullWidth
                            label="Fornecedor"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="taxNumberOfSupplier"
                            variant="outlined"
                            fullWidth
                            label="CNPJ do Fornecedor"
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: { mask: cnpjMask },
                            }}
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="brand"
                            variant="outlined"
                            fullWidth
                            label="Marca"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="color"
                            variant="outlined"
                            fullWidth
                            label="Cor"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="model"
                            variant="outlined"
                            fullWidth
                            label="Modelo"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="yearOfModel"
                            variant="outlined"
                            fullWidth
                            type="number"
                            label="Ano do modelo"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="yearOfManufacture"
                            variant="outlined"
                            fullWidth
                            type="number"
                            label="Ano de fabricação"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="typeOfFuel"
                            variant="outlined"
                            fullWidth
                            label="Tipo de combustível"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="sellerCompanyName"
                            variant="outlined"
                            fullWidth
                            label="Revenda"
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="sellerRegistrationNumber"
                            variant="outlined"
                            label="CNPJ de quem efetuou a venda"
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: {
                                    mask: cnpjMask,
                                },
                            }}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <CurrencyFormField
                            label="Valor de entrada"
                            name="downPayment"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={3}>
                        <CurrencyFormField
                            label="Valor do veículo à vista"
                            name="valueInCash"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={3}>
                        <CurrencyFormField
                            label="Subtotal (Veículo + acessórios + outras despesas incluídas na operação a pedido do consumidor)"
                            name="subtotalAmount"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={3}>
                        <CurrencyFormField
                            label="Valor total a ser financiado sem impostos"
                            name="totalAmountFinancedTaxFree"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </>
            )}
            {watchedWarranty === 'HomeEquity' && (
                <>
                    <Grid xs={3}>
                        <TextFormField
                            name="registryOffice"
                            variant="outlined"
                            fullWidth
                            label="Cartório de Registro"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="registrationNumber"
                            variant="outlined"
                            fullWidth
                            label="Matrícula"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="width"
                            variant="outlined"
                            fullWidth
                            label="Largura (em metros)"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="length"
                            variant="outlined"
                            fullWidth
                            label="Comprimento (em metros)"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="zipCodeAddress"
                            variant="outlined"
                            fullWidth
                            label="CEP"
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: { mask: zipCodeMask },
                                onChange: (event) => {
                                    let keyBoard = event?.currentTarget?.value.replace(/\D/g, '');
                                    queryZipCodeAndFillForm(
                                        keyBoard,
                                        (fieldName, value) => {
                                            setValue(`${fieldName}`, value);
                                        },
                                        'zipCodeAddress'
                                    );
                                },
                            }}
                            InputLabelProps={{
                                shrink: watch('zipCodeAddress') ? true : false,
                            }}
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="addressName"
                            variant="outlined"
                            fullWidth
                            label="Endereço"
                            required
                            InputLabelProps={{
                                shrink: watch('addressName') ? true : false,
                            }}
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="addressNumber"
                            variant="outlined"
                            fullWidth
                            label="Número"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="complementAddress"
                            variant="outlined"
                            fullWidth
                            label="Complemento"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <SelectFormField
                            name="uf"
                            variant="outlined"
                            fullWidth
                            type="number"
                            label="UF"
                            required
                            options={ufList}
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="district"
                            variant="outlined"
                            fullWidth
                            label="Bairro"
                            required
                            InputLabelProps={{
                                shrink: watch('district') ? true : false,
                            }}
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="propertyFeatures"
                            variant="outlined"
                            fullWidth
                            label="Características do imóvel"
                        />
                    </Grid>
                </>
            )}
            {watchedWarranty === 'JudiciaryProcess' && (
                <>
                    <Grid xs={3}>
                        <TextFormField
                            name="number"
                            variant="outlined"
                            fullWidth
                            label="Nº do processo"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="court"
                            variant="outlined"
                            fullWidth
                            label="Vara / Tribunal"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <CurrencyFormField
                            name="valueOfCause"
                            variant="outlined"
                            fullWidth
                            label="Valor da causa"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="claimant"
                            variant="outlined"
                            fullWidth
                            label="Requerente / Autor"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="claimed"
                            variant="outlined"
                            fullWidth
                            label="Requerido / Réu"
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="typeOfProcess"
                            variant="outlined"
                            fullWidth
                            label="Tipo de processo"
                            required
                        />
                    </Grid>
                </>
            )}
            {isPublicPayroll && (
                <>
                    <Grid xs={3}>
                        <AutocompleteField
                            label="Código do Órgão"
                            name="payrollAgreementId"
                            displayName="payrollAgreementName"
                            loading={false}
                            required
                            listOptions={listOptionsFromAutocomplete ?? []}
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            label="Matrícula"
                            name="employeeCode"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    {!isZetra && (
                        <Grid xs={3}>
                            <TextFormField
                                label="Código externo do orgão"
                                name="agreementCode"
                                required
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    )}
                    <Grid xs={3}>
                        <TextFormField
                            label="Dia do repasse"
                            name="paymentTransferDay"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    {!isZetra && (
                        <>
                            <Grid xs={3}>
                                <TextFormField
                                    label="Órgão + matrícula do instituidor"
                                    name="employeerInstCode"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={3}>
                                <TextFormField
                                    label="Código de averbação"
                                    name="warrantyCode"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            {watchedEmpSttsDescription && (
                                <Grid xs={3}>
                                    <TextFormField
                                        label="Código situação vínculo funcional"
                                        name="employmentStatusCode"
                                        disabled
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                            )}
                            <Grid xs={3}>
                                <AutocompleteField
                                    label="Descrição situação vínculo funcional"
                                    name="employmentStatusDescription"
                                    displayName="employmentStatusDescription"
                                    required
                                    {...employmentStatus}
                                    onChange={(_, options) => {
                                        const selected = options as any;
                                        setValue(
                                            'employmentStatusDescription',
                                            selected?.description
                                        );
                                        setValue('employmentStatusCode', selected?.value);
                                    }}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid xs={3}>
                        <CurrencyFormField
                            label="Valor da Margem"
                            name="paymentAmountInCents"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                </>
            )}
            {watchedWarranty === 'InvoiceFactoring' && (
                <>
                    <Grid xs={3}>
                        <TextFormField
                            label="Nome do pagador"
                            name="payerName"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            name="payerRegistrationNumber"
                            variant="outlined"
                            label="CPF/CNPJ"
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: {
                                    mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace,
                                },
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            label="Número do contrato"
                            name="contractNumber"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            label="Número nominal"
                            name="invoiceNumber"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <CurrencyFormField
                            label="Valor nominal"
                            name="invoiceValue"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <CurrencyFormField
                            label="Desconto"
                            name="discountValue"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <CurrencyFormField
                            label="Valor presente"
                            name="presentValue"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <CurrencyFormField
                            label="Valor nominal no vencimento"
                            name="paymentAmountInCents"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid>
                        <DatePickerFormField
                            label="Data de vencimento"
                            name="dueDate"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            label="Número da parcela"
                            name="paymentItemIndex"
                            variant="outlined"
                            fullWidth
                            type="number"
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            label="Total de parcelas"
                            name="paymentItemTotal"
                            variant="outlined"
                            fullWidth
                            type="number"
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            label="Seu número"
                            name="yourNumber"
                            variant="outlined"
                            fullWidth
                            type="number"
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            label="Código da loja"
                            name="storeCode"
                            variant="outlined"
                            fullWidth
                            type="number"
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            label="Código do produto"
                            name="productCode"
                            variant="outlined"
                            fullWidth
                            type="number"
                        />
                    </Grid>

                    <Grid xs={3}>
                        <TextFormField
                            label="Identificação"
                            name="identification"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid>
                        <DatePickerFormField
                            label="Data de emissão do título"
                            name="emissionDate"
                            fullWidth
                        />
                    </Grid>
                    <Grid>
                        <TextFormField label="Índice" name="index" variant="outlined" fullWidth />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            label="Endereço do pagador"
                            name="payerAdress"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            label="CEP do pagador"
                            name="payerZipCodeAdress"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={3}>
                        <PercentageFormField
                            label="Taxa do contrato "
                            name="ContractFee"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </>
            )}

            {watchedWarranty === 'Consortium' && (
                <>
                    <Grid xs={3}>
                        <TextFormField
                            name="administratorRegistrationNumber"
                            variant="outlined"
                            label="CNPJ do Administrador"
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: {
                                    mask: cnpjMask,
                                },
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            label="Nome do Administrador"
                            name="administratorName"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid xs={3}>
                        <TextFormField
                            label="Grupo"
                            name="group"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TextFormField
                            label="Cota"
                            name="quota"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                </>
            )}

            <Stack
                direction={isMobile ? 'column' : 'row'}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                    fullWidth={isMobile}
                >
                    Fechar
                </Button>
                <Button
                    type="submit"
                    startIcon={<SaveIcon htmlColor={theme.palette.primary.contrastText} />}
                    variant="contained"
                    size="medium"
                    disabled={submitting}
                    fullWidth={isMobile}
                >
                    Adicionar
                </Button>
            </Stack>
        </Stack>
    );
};
