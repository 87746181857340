import { FormProvider, useFormContext } from 'contexts/formContext';
import SelectConsignmentForm from './SelectConsignmentForm';
import {
    defaultValuesSelectConsignment,
    TSelectConsignmentProps,
    validationSchemaSelectConsignment,
} from './SelectConsignmentSchema';
import { FieldErrors } from 'react-hook-form';
import { CheckPurchaseContractsReadModel } from 'services/zetra';
import { showInfoToast, ToastType } from 'contexts/apiRequestContext';
import { mapConsignmentWarranty } from 'services/creditNote';
import { CreditProductReadModel } from 'services/creditProduct';
import { setPaymentTransferAndCutoffDay } from 'pages/CreditNote/CreditNoteForm/Tabs/Operation/setFirstPaymentDate';

type SelectConsignmentProps = {
    setOpenSelectConsignment: Function;
    setUpdateConsignment: Function;
    checkPurchaseContracts: CheckPurchaseContractsReadModel | undefined;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    onClose: () => void;
    registrationNumber: string;
    productData: CreditProductReadModel | undefined;
    personId?: string;
};

function SelectConsignmentContainer({
    setOpenSelectConsignment,
    setUpdateConsignment,
    checkPurchaseContracts,
    setToast,
    onClose,
    registrationNumber,
    productData,
    personId,
}: SelectConsignmentProps) {
    const { setValue, watch } = useFormContext();

    const handleSubmit = ({ ...props }: TSelectConsignmentProps) => {
        const { selectConsignment, warrantyRegistrationCutoffDay, paymentTransferDay, ...rest } =
            props ?? {};
        const abstracts = checkPurchaseContracts?.resumos;

        const selectedItems = abstracts?.filter((f) => {
            const value = `${f?.adeNumero}-${f?.adeIdentificador}-${f?.prazo}`;
            return selectConsignment.includes(value);
        });


        if (!!selectedItems?.length) {
            const maxTermInMonths = selectedItems.reduce(
                (acc, curr) => Math.max(acc, curr.prazo),
                0
            );

            const mappedWarrantyModels = selectedItems.map((item) => {
                const additionalInformation = {
                    ...rest,
                    adeNumero: item.adeNumero,
                    paymentTransferDay,
                    maxTermInMonths
                };

                return mapConsignmentWarranty(item, additionalInformation, registrationNumber);
            });

            const cutoffDay = Number(warrantyRegistrationCutoffDay ?? 0);
            const paymentTransferDayValue = Number(paymentTransferDay ?? 0);
            const startDateString = watch('amortization.startDate');

            if (!!cutoffDay && !!paymentTransferDayValue && !!startDateString) {
                setPaymentTransferAndCutoffDay(
                    startDateString,
                    paymentTransferDayValue,
                    cutoffDay,
                    setValue
                );
            }

            const requestedAmount = selectedItems.reduce(
                (acc, curr) => acc + curr.valorParcela,
                0
            );
            setValue('amortization.requestedAmount', requestedAmount * 100);
            setValue('warranty', mappedWarrantyModels);
            setValue('amortization.calculateByValueType', 'Payment');

            
            setValue('amortization.termInMonths', maxTermInMonths);
            setValue('amortization.numberOfPayments', maxTermInMonths);
        }

        onClose();
    };

    return (
        <FormProvider
            validationSchema={validationSchemaSelectConsignment}
            defaultValues={defaultValuesSelectConsignment}
            onSubmit={handleSubmit}
            onError={(err: FieldErrors<TSelectConsignmentProps>) => {
                const erroMessage = Object.values(err)
                    .map((error) => error?.message)
                    .join('\n');
                showInfoToast('Atenção! Verifique os campos obrigatórios.', erroMessage, setToast);
            }}
        >
            <SelectConsignmentForm
                {...{
                    setOpenSelectConsignment,
                    setUpdateConsignment,
                    checkPurchaseContracts,
                    productData,
                    personId,
                }}
            />
        </FormProvider>
    );
}

export default SelectConsignmentContainer;
