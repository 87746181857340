import { Grid, Box, Stack, styled } from '@mui/material';
import {
    Typography,
    Alert,
    ShowIcon,
    ReportIcon,
    SimulationIcon,
    GraphicWithBarIcon,
    CompleteIcon,
    BankingIcon,
} from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { useFormContext } from 'contexts/formContext';
import { useUserPermissionData } from 'contexts/userContext';
import { useCreditNoteFormContext } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import { checkActionsPerms } from 'services/permissions';
import { useNavigate } from 'react-router';
import { isZetraProduct } from 'services/zetra';
import { activeTheme } from 'services/theme';

export const RemovePrintMode = styled('div')(() => ({
    '@media print': {
        display: 'none',
    },
}));

type SimulationFormProps = {
    simulationId: string | undefined;
    handleAction: (action: string) => void;
};

const theme = activeTheme().palette;
const SimulationForm = ({ simulationId, handleAction }: SimulationFormProps) => {
    const { submitting, validationErrors, watch } = useFormContext();
    const { product } = useCreditNoteFormContext();
    const instrumentType = product?.instrumentType?.toLowerCase();
    const warrantyRegistrationOffice = product?.warrantyRegistrationOffice?.toLowerCase();
    const { data: permissionsData } = useUserPermissionData();

    const hasActionPermission = (action: string): boolean => {
        if (checkActionsPerms(action, permissionsData, 'CreditNote')) {
            return true;
        }
        return false;
    };

    const enableSiapeMarginQuery = () => {
        if (
            instrumentType === 'publicpayrollloan' &&
            warrantyRegistrationOffice === 'siape' &&
            hasActionPermission('ReadDataSet')
        ) {
            return true;
        }
        return false;
    };

    const paramsId = () => {
        const warranty = watch('warranty') ?? [];
        if (warranty?.length > 0) {
            return `/ccb/operacoes/nova?simulacao=${simulationId}?warranty=${JSON.stringify(
                warranty
            )}`;
        }
        return `/ccb/operacoes/nova?simulacao=${simulationId}`;
    };

    const isZetra = isZetraProduct(product);

    const navigate = useNavigate();
    const colorBlack = theme?.common?.black;

    return (
        <RemovePrintMode>
            {validationErrors && (
                <Box mt={2} mb={2}>
                    <Alert
                        severity="error"
                        text={
                            'Não foi possível salvar as alterações. Corrija os erros do formulário e tente novamente.'
                        }
                        variant="filled"
                    />
                </Box>
            )}
            <Box>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography
                        variant="h4"
                        color="neutral.dark"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="32px"
                        lineHeight="38.4px"
                    >
                        Simulação
                    </Typography>
                    <Grid>
                        <Grid direction="row" alignItems="center">
                            <Actions
                                buttonsActionsList={[
                                    {
                                        enable: true,
                                        disabled: submitting,
                                        label: 'Simular',
                                        type: 'submit',
                                        icon: <SimulationIcon htmlColor={colorBlack} />,
                                    },
                                    {
                                        enable: true,
                                        disabled: submitting,
                                        label: 'Imprimir',
                                        action: () => handleAction('printSettting'),
                                        icon: <ReportIcon htmlColor={colorBlack} />,
                                    },
                                    {
                                        enable: simulationId !== undefined,
                                        disabled: submitting,
                                        label: 'Abrir operação',
                                        action: () => navigate(paramsId()),
                                        icon: <ShowIcon htmlColor={colorBlack} />,
                                    },
                                    {
                                        disabled: submitting,
                                        enable: enableSiapeMarginQuery() || isZetra,
                                        label: 'Consultar',
                                        icon: <CompleteIcon htmlColor={colorBlack} />,
                                        groupButtons: [
                                            {
                                                enable: true,
                                                label: 'Margem',
                                                action: () => handleAction('marginQuery'),
                                                icon: <GraphicWithBarIcon htmlColor={colorBlack} />,
                                            },
                                            {
                                                enable: isZetra,
                                                label: 'Consignação para compra',
                                                action: () => handleAction('consignment'),
                                                icon: <BankingIcon htmlColor={colorBlack} />,
                                            },
                                        ],
                                    },
                                ]}
                                numberOfButtons={4}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>
        </RemovePrintMode>
    );
};

export default SimulationForm;
