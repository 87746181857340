import React from 'react';
import { TabBar } from 'components/TabBar/TabBar';

export const VortxContainer: React.FC = () => {
    const tabs = [
        { label: `Conciliação Caixa`, href: 'fgts' },
        { label: `Relatórios de Conciliação`, href: 'gerar-arquivo-liquidacao-caixa' },
        { label: `Liquidação Antecipada`, href: 'calcular-liquidacao-antecipada' },
    ];
    return <TabBar tabs={tabs} />;
};
