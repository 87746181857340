import { FormProvider } from 'contexts/formContext';
import { useFundsList } from 'contexts/fundContext';
import { useIdentity } from 'contexts/identityContext';
import {
    CNABStarAssistenciaFinanceiraAsync,
    CNABStarPeculioAsync,
    GenerateCnabsType,
    postCNABItau,
    postCNABNCB3,
    postGenerateCnabs,
} from 'services/cnab/cnab';
import { CnabCreditNoteForm } from './CreditNoteCnab';
import {
    defaultValuesCreditNoteList,
    validationCreditNoteListSchema,
} from './CreditNoteCnabSchema';
import { useTenant } from 'contexts/tenantContext';
import { Stack } from '@mui/material';
import { Button, CloseIcon, CompleteIcon } from '@uy3/web-components';
import {
    ApiResponseError,
    ToastType,
    iconSx,
    showErrorToast,
    showSuccessToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';
import { useState } from 'react';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel';

type CreditNoteCnabContainerProps = {
    creditNoteItemsSelected: CreditNoteReadModel[];
    rowsSelected: string[];
    onClose: (open: any) => void;
};

type GenerateCnabType = {
    fundId: string;
    type: string;
    requiredFundId?: boolean;
};

const theme = activeTheme();
export const CreditNoteCnabContainer = ({
    creditNoteItemsSelected,
    rowsSelected,
    onClose,
}: CreditNoteCnabContainerProps) => {
    const [toast, setToast] = useState<ToastType>(toastState);
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { startRequest, endRequest, submitting } = useApiRequest();
    const { fundAutoCompleteProps } = useFundsList(
        { page: 0, size: 10, tenant: currentTenantId! },
        'always'
    );

    const handleResponseError = (error: ApiResponseError, type?: string) => {
        const { errorMessage } = mapErrorResponse(error);
        endRequest(true);
        const title = 'Ops, ocorreu um erro!';
        const description = type === 'Vortx' ? 'Existem operações sem fundo' : errorMessage;
        showErrorToast(title, description, setToast);
    };

    const cnabNCB3 = async () => {
        startRequest();
        await postCNABNCB3(rowsSelected, token!)
            .then((result: any) => {
                endRequest(true);
                onClose(undefined);
                return window.open(result?.data?.tempUrl, '_blank');
            })
            .catch((error: ApiResponseError) => handleResponseError(error));
    };

    const cnab400Itau = async () => {
        startRequest();
        await postCNABItau(rowsSelected, token!)
            .then((result: any) => {
                endRequest(true);
                onClose(undefined);
                return window.open(result?.data?.tempUrl, '_blank');
            })
            .catch((error: ApiResponseError) => handleResponseError(error));
    };

    const CNABStarPeculio = async () => {
        startRequest();
        await CNABStarPeculioAsync(rowsSelected, token!)
            .then((result: any) => {
                endRequest(true);
                onClose(undefined);
                return window.open(result?.data?.tempUrl, '_blank');
            })
            .catch((error: ApiResponseError) => handleResponseError(error));
    };

    const CNABStarAssistenciaFinanceira = async () => {
        startRequest();
        await CNABStarAssistenciaFinanceiraAsync(rowsSelected, token!)
            .then((result: any) => {
                endRequest(true);
                onClose(undefined);
                return window.open(result?.data?.tempUrl, '_blank');
            })
            .catch((error: ApiResponseError) => handleResponseError(error));
    };

    const generateCnabAsync = async (generateType: GenerateCnabType) => {
        const payload = {
            payload: rowsSelected,
            token,
            ...generateType,
        } as GenerateCnabsType;
        await postGenerateCnabs(payload)
            .then((result: any) => {
                endRequest(true);
                onClose(undefined);
                const title = `${generateType.type} gerado com sucesso`;
                const description = undefined;
                showSuccessToast(title, description, setToast);

                return window.open(result.data.tempUrl, '_blank');
            })
            .catch((error: ApiResponseError) => handleResponseError(error, generateType.type));
    };

    const onSubmit = async (formValues: { cnab: string; fundId: string }) => {
        const { cnab, fundId } = formValues;

        const cnabTypes: { [key: string]: string } = {
            liquidationFile: 'LiquidationFileWebCred',
            Vortx: 'Vortx',
            Cnab400: 'Cnab400',
            Cnab400B: 'Cnab400B',
            Cnab444REAG: 'Cnab444REAG',
            Cnab444: 'Cnab444',
            Cnab500: 'Cnab500',
            Cnab550: 'Cnab550',
            CnabAXA: 'CnabAXA',
            CnabAXACancel: 'CnabAXACancel',
            Cnab600: 'Cnab600',
            Cnab600Kovr: 'Cnab600Kovr',
        };

        const requiredFund = [
            'Cnab444REAG',
            'Cnab444',
            'Cnab500',
            'Cnab550',
            'Cnab600',
            'Cnab600Kovr',
        ];

        if (cnabTypes.hasOwnProperty(cnab)) {
            generateCnabAsync({
                fundId,
                type: cnabTypes[cnab],
                requiredFundId: requiredFund.includes(cnab),
            });
        } else if (cnab === 'NCB3') {
            await cnabNCB3();
        } else if (cnab === 'StarPeculio') {
            await CNABStarPeculio();
        } else if (cnab === 'StarAssistenciaFinanceira') {
            await CNABStarAssistenciaFinanceira();
        } else if (cnab === 'Cnab400itau') {
            await cnab400Itau();
        }
    };

    const schema = validationCreditNoteListSchema();
    const defaultValues = defaultValuesCreditNoteList;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <FormProvider
                validationSchema={schema}
                defaultValues={defaultValues}
                onSubmit={onSubmit}
            >
                <>
                    <CnabCreditNoteForm
                        fundAutoCompleteProps={fundAutoCompleteProps}
                        creditNotesSelected={creditNoteItemsSelected}
                    />
                    <Stack
                        direction={'row'}
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={3}
                        mt={3}
                    >
                        <Button
                            startIcon={
                                <CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />
                            }
                            variant="outlined"
                            size="medium"
                            onClick={() => onClose(undefined)}
                        >
                            Fechar
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={submitting}
                            startIcon={
                                <CompleteIcon
                                    htmlColor={theme.palette.primary.contrastText}
                                    sx={iconSx}
                                />
                            }
                            sx={{ justifyContent: 'center' }}
                        >
                            Enviar
                        </Button>
                    </Stack>
                </>
            </FormProvider>
        </>
    );
};
