import styled from '@emotion/styled'
import { Grid, Stack } from '@mui/material'
import { Button, CloseIcon, DisplayRightIcon, RightIcon, Typography } from '@uy3/web-components'
import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields'
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField'
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField'
import { MaskedInput } from 'components/Forms/MaskedInput'
import HorizontalInfo from 'components/HorizontalInfo/HorizontalInfo'
import { ListNamesType, RenderAvatarsName } from 'components/RenderAvatarsName/RenderAvatarsName'
import { cnpjMask, cpfMaskAndSpace, zipCodeMask } from 'utils/constants'
import { ApiResponseError, ToastType, iconSx } from 'contexts/apiRequestContext'
import { useFormContext } from 'contexts/formContext'
import { mapErrorResponse } from 'contexts/responseErrorContext'
import { useGetZipCodeBillingMutation } from 'contexts/wallet/zipCode/zipCoddeContext'
import { activeTheme } from 'services/theme'
import { IAddressBilling } from 'services/walletManagement/zipCode/zipCode.type'

const SectionForm = styled(Stack)(({
    display: 'block',
    padding: '5px 0 ',
}))

type EditBillingProps = {
    onClose: () => void;
    allowEditGuarantor: boolean
    allowEditTags: boolean
    handleUpdateTags: () => void;
    categories: string[];
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
}

const theme = activeTheme()

export const EditBilling = ({
    onClose,
    allowEditGuarantor = false,
    allowEditTags = false,
    handleUpdateTags,
    categories,
    setToast
}: EditBillingProps) => {
    const { watch, setValue } = useFormContext();
    const isLegalPerson = String(watch('payer.registrationNumber'))?.replace(/\D/g, "")?.length > 11;
    const isLegalPersonGuarantor = String(watch('guarantor.registrationNumber'))?.replace(/\D/g, "")?.length > 11;

    const onSuccess = (response: IAddressBilling) => {
        setValue('payer.addressName', response.address);
    }

    const onError = (error: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(error);
        setToast({
            open: true,
            severity: 'error',
            title: 'Ops, ocorreu um erro ao consultar o CEP',
            description: errorMessage
        })
    }

    const { mutate: queryZipCodeMutate, isLoading: isLoadingZipCode } = useGetZipCodeBillingMutation(onSuccess, onError);

    const personId = watch('personId');
    const amount = watch('amount');

    const categoriesFormatedFromDetails: ListNamesType[] = categories?.map((item: string, index: number) => {
        return {
            id: `${index + item}`,
            name: item,
            tenant: ""
        } as ListNamesType
    });

    const handleChangeZipCode = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = String(event.currentTarget.value)?.replace(/\D/g, '');
        setValue('payer.zipCode', value);

        if (value?.length === 8) {
            queryZipCodeMutate(value);
        }
    }

    return (
        <Stack spacing={2}>
            <SectionForm spacing={2}>
                <Typography variant="lg" color="common.black">
                    Dados da carteira
                </Typography>
                <AutocompleteField
                    label="Carteira"
                    name="walletCode"
                    displayName="walletCodeDisplay"
                    disabled
                    listOptions={[]}
                    loading={false}
                />
            </SectionForm>

            <SectionForm spacing={2}>
                <Typography variant="lg" color="common.black">
                    Dados do pagador
                </Typography>

                <SelectFormField
                    label='Espécie título'
                    name='creditTypeValue'
                    fullWidth
                    required
                    options={[
                        { label: "Duplicata", value: "Duplicata" },
                        { label: "Nota Promissória", value: "NotaPromissoria" },
                        { label: "Nota de Seguro", value: "NotaSeguro" },
                        { label: "Recibo", value: "Recibo" },
                        { label: "Letras de Câmbio", value: "LetrasCambio" },
                        { label: "Nota de Débito", value: "NotaDebito" },
                        { label: "Duplicata de Serviço", value: "DuplicataServico" },
                        { label: "Cartão de Crédito", value: "CartaoCredito" },
                        { label: "Boleto de Proposta", value: "BoletoProposta" },
                        { label: "Depósito e Aporte", value: "DepositoAporte" }
                    ]}
                />

                <AutocompleteField
                    label="Pagador"
                    name="personId"
                    displayName="personIdDisplay"
                    disabled
                    endIconType="link"
                    listOptions={[]}
                    loading={false}
                />
                <TextFormField
                    name='payer.registrationNumber'
                    variant='outlined'
                    disabled
                    label='CPF/CNPJ'
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace }
                    }}
                    fullWidth
                />
                <TextFormField
                    label="Email"
                    variant="outlined"
                    name="payer.email"
                    fullWidth
                />
                <TextFormField
                    name="payer.zipCode"
                    label="CEP"
                    variant="outlined"
                    placeholder="00000-000"
                    fullWidth
                    disabled={isLoadingZipCode}
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: zipCodeMask },
                        onChange: handleChangeZipCode
                    }}
                />
                <TextFormField
                    label="Endereço"
                    variant="outlined"
                    name="payer.addressName"
                    disabled={isLoadingZipCode}
                    fullWidth
                />
                <TextFormField
                    label="Número"
                    variant="outlined"
                    name="payer.addressNumber"
                    fullWidth
                />
                <TextFormField
                    label="Complemento"
                    variant="outlined"
                    name="payer.addressComplement"
                    fullWidth
                />

            </SectionForm>
            {personId && (
                <>
                    <SectionForm spacing={2}>
                        <Typography variant="h5" fontWeight="700">
                            Dados adicionais
                        </Typography>
                        <TextFormField
                            name="documentNumber"
                            variant="outlined"
                            label="Número do Documento"
                            fullWidth
                        />
                        <DatePickerFormField
                            name="dueDate"
                            label="Data de vencimento"
                            fullWidth
                            disablePast
                        />
                        <TextFormField
                            name="description"
                            variant="outlined"
                            label="Descrição do título"
                            fullWidth
                        />
                    </SectionForm>

                </>
            )}
            {allowEditGuarantor &&
                <SectionForm spacing={2}>
                    <Typography variant="h5" fontWeight="700">
                        Sacador avalista
                    </Typography>
                    <TextFormField
                        name="guarantor.name"
                        variant="outlined"
                        label="Nome"
                        fullWidth
                    />
                    <TextFormField
                        name='guarantor.registrationNumber'
                        variant='outlined'
                        disabled={String(watch('guarantor.registrationNumber'))?.length >= 11}
                        label='CPF/CNPJ'
                        InputProps={{
                            inputComponent: MaskedInput,
                            inputProps: { mask: isLegalPersonGuarantor ? cnpjMask : cpfMaskAndSpace }
                        }}
                        fullWidth
                    />
                </SectionForm>}
            <SectionForm spacing={2}>
                <Typography variant="h5" fontWeight="700">
                    Desconto
                </Typography>
                <Grid>
                    <CurrencyFormField
                        label="Valor do desconto"
                        name="discount"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid>
                    <DatePickerFormField
                        label="Data limite desconto"
                        name="discountLimitDate"
                        fullWidth
                    />
                </Grid>
            </SectionForm>
            <SectionForm spacing={2}>
                <Typography variant="h5" fontWeight="700">
                    Valor da cobrança
                </Typography>
                <Grid>
                    <CurrencyFormField
                        label="R$"
                        name="amount"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </SectionForm>

            {allowEditTags &&
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    {categoriesFormatedFromDetails.length > 0 &&
                        <RenderAvatarsName
                            list={categoriesFormatedFromDetails}
                            title='Categorias'
                            maxAvatars={4}
                        />
                    }
                    <Button
                        variant='text'
                        onClick={handleUpdateTags}
                        startIcon={<DisplayRightIcon sx={{ ...iconSx }} />}
                        sx={{ color: theme.palette.common.black }}
                    >
                        {categoriesFormatedFromDetails.length > 0 ? "Alterar categorias" : "Adicionar categorias"}
                    </Button>
                </Stack>
            }

            <HorizontalInfo type="currency" value={amount} />

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3} mt={3}>
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    name="btn-continuar"
                    startIcon={<RightIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                    type="submit"
                >
                    Continuar
                </Button>
            </Stack>
        </Stack>
    )
}
