import { billingAxiosApi } from "services/axiosApi/axiosApi";
import { BankSlipDebtCollectionCancelType, BankSlipParamsProps, LiquidationPostType, UpdateOtherInformationsType } from "./bankSlip.types";

var url = `/BankSlip`;
export const getBankSlipListAsync = async (filters: BankSlipParamsProps, token?: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
    };

    return await billingAxiosApi.get(url, config);
};

export const getBankSlipListByBarCodeAsync = async (bankSlipId: string, token?: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await billingAxiosApi.get(`${url}/${bankSlipId}`, config);
};

export const downloadBankSlipByBarCode = async (barCode: string, walletCode?: string, token?: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }, 
        responseType: 'blob' as 'blob', 
        params: {walletCode}
    };

    return await billingAxiosApi.get(`${url}/${barCode}/DownloadPdf`, config);
};

export const updateDueDateBankSlipByBarCode = async (barCode: string, dueDate: string, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.post(`${url}/${barCode}/UpdateDueDate`, { dueDate }, config);
}

export const protestBankSlipByBarCode = async (barCode: string, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.post(`${url}/${barCode}/DebtCollection/Submit`,{}, config);
}

export const downBankSlipByBarCode = async (barCode: string, keepBillingOpen: boolean, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.post(`${url}/${barCode}/Cancel`, {keepBillingOpen},  config);
}

export const settlementSTRByBarCode = async (barCode: string, payload: LiquidationPostType,  token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.post(`${url}/${barCode}/SettlementSTR`, payload,  config);
}

export const insertAbatmentBankSlipByBarCode = async (barCode: string, value: string, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.post(`${url}/${barCode}/UpdateDeduction`, { deduction: value }, config);
}

export const transferBankSlipByBarCode = async (barCode: string, toWalletCode: string, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.post(`${url}/${barCode}/Transfer`, { toWalletCode }, config);
}

export const bankSlipDebtCollectionCancel = async (barCode: string, payload: BankSlipDebtCollectionCancelType, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.post(`${url}/${barCode}/DebtCollection/Cancel`, payload, config);
}

export const bankSlipUpdateOtherInformation = async (barCode: string, payload: UpdateOtherInformationsType, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.post(`${url}/${barCode}/UpdateOtherInformation`, payload, config);
}

export const bankSlipUpdateAmount = async (barCode: string, payload: {amount: number}, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.post(`${url}/${barCode}/UpdateAmount`, payload, config);
}

export async function postBankSlipAgainRegisterAsync(barCode: string, token: string) {
    const recordType = `${url}/${barCode}/RegisterCanceled`;

    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await billingAxiosApi.post(recordType, {}, axiosConfig);
}

export async function cancelAbatmentAsync (barCode: string, token: string) {
    const recordType = `${url}/${barCode}/CancelDeduction`;

    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await billingAxiosApi.post(recordType, {}, axiosConfig);
}