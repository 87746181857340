import { useMutation, useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { ApiResponseError, GetListApiResponse, MutateParamsWithRecordId, useApiRequest } from "contexts/apiRequestContext";
import { useFilterValues } from "contexts/filterValuesContext";
import { useIdentity } from "contexts/identityContext";
import { useTenant } from "contexts/tenantContext";
import { isEmpty } from "lodash";
import { handleErrorUseQuery, responseBlobFile } from "helpers";
import { cancelInstallmentsAsync, createInstallmentAsync, downloadPdfAsync, getInstallmentsByIdAsync, getInstallmentsListAsync, registerInstallmentAsync, transferInstallmentsAsync, updateCommunicationSettingByInstallmentAsync, updateInstallmentAsync } from "services/walletManagement/installment";
import { ICreateInstallment, IInstallmentFull, IParamsInstallment, IRegisterPayload, ITransferInstallment, IUpdateCommunicationByInstallmentSetting, IUpdateInstallment } from "services/walletManagement/installment/installment.types";

export function useGetInstallmentList(params?: IParamsInstallment) {
    const { currentTenantId } = useTenant();
    const { filterValues } = useFilterValues();
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const recordType = isEmpty(filterValues.recordType) ? 'installment' : filterValues.recordType;
    const listDataFilters = recordType === 'installment' && { ...filterValues.filters } as any;
    const filtersComplete = { ...params, tenant: currentTenantId, ...listDataFilters } as IParamsInstallment;

    return useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-installment-list', filtersComplete],
        queryFn: async (): Promise<GetListApiResponse<IInstallmentFull>> => {
            startRequest();
            const { data, status, statusText } = await getInstallmentsListAsync(filtersComplete, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as GetListApiResponse<IInstallmentFull>;
        },
    });
}

export function useGetInstallmentById(installmentId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-installment-byId', installmentId],
        queryFn: async (): Promise<IInstallmentFull> => {
            startRequest();
            const { data, status, statusText } = await getInstallmentsByIdAsync(installmentId!, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as IInstallmentFull;
        },
    });
}

export function useCreateInstallmentMutate(
    onSuccess: (data: IInstallmentFull) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (payload: ICreateInstallment) => {
            startRequest();
            const { data } = await createInstallmentAsync(payload, token!);
            endRequest(true);
            return data as IInstallmentFull;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

export function useUpdateInstallmentMutate(
    installmentId: string,
    onSuccess: (data: IInstallmentFull) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (payload: IUpdateInstallment) => {
            startRequest();
            const { data } = await updateInstallmentAsync(installmentId, payload, token!);
            endRequest(true);
            return data as IInstallmentFull;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

export function useTransferInstallmentMutation({ id, onError, onSuccess }: MutateParamsWithRecordId<IInstallmentFull>) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (payload: ITransferInstallment) => {
            startRequest();
            const { data } = await transferInstallmentsAsync(id!, payload, token!);
            endRequest(true);
            return data as IInstallmentFull;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

export function useCancelInstallmentMutation(
    onSuccess: (data: IInstallmentFull) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (installmentId?: string) => {
            startRequest();
            const { data } = await cancelInstallmentsAsync(installmentId!, token!);
            endRequest(true);
            return data as IInstallmentFull;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}


export function useRegisterInstallmentMutation(
    installmentId: string,
    onSuccess: (data: IInstallmentFull) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (payload: IRegisterPayload) => {
            startRequest();
            const { data } = await registerInstallmentAsync(installmentId, payload!, token!);
            endRequest(true);
            return data as IInstallmentFull;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}


export function useUpdateCommunicationSettingByInstallmentMutate(
    installmentId: string,
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (params: IUpdateCommunicationByInstallmentSetting) => {
            startRequest();
            const { data } = await updateCommunicationSettingByInstallmentAsync(installmentId, params, token!);
            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

export function useDownloadPdfInstallmentMutation(
    installmentId: string,
    onSuccess: (data: IInstallmentFull) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async () => {
            startRequest();

            return await downloadPdfAsync(installmentId, token!)
                .then(async (response: any) => {
                    await responseBlobFile(response);
                    endRequest(true);
                    onSuccess && onSuccess(response);
                }).catch((error: ApiResponseError) => {
                    handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                });
        },
    });
}

