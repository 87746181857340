/* eslint-disable no-mixed-operators */
import React from 'react';
import { useFormContext } from 'contexts/formContext';
import {
    Button as ButtonBase,
    Typography,
    SaveIcon,
    DeleteIcon,
    UpdateIcon,
    OperatorIcon,
    ApprovalIcon,
    ShareIcon,
    SimulationIcon,
    LowGraphIcon,
    DoneIcon,
    RejectIcon,
    ApproveIcon,
    CloseIcon,
    InvoiceReceivableIcon,
    EmailIcon,
    BankSlipIcon,
    ShowIcon,
    SettingsIcon,
    SignatureIcon,
    ReportIcon,
    ChartWaveRectangleIcon,
    ReloadRectangleIcon,
    ShieldWarningIcon,
    GraphicWithBarIcon,
    WalletIcon,
    CheckCircleIcon,
    BankingIcon,
    ZoomTextIcon,
    CompleteIcon,
    CalendarCheckIcon,
    LayoutIcon,
} from '@uy3/web-components';
import { useCreditNoteActions } from 'contexts/creditNote/creditNoteActions';
import { IUploadModel, UploadGet, iconSx } from 'contexts/apiRequestContext';
import { useAppConfig } from 'contexts/appConfig';
import { useParams } from 'react-router';
import { useCreditData } from 'contexts/creditNote/creditContext';
import { Actions } from 'components/Actions/Actions';
import { useUserPermissionData } from 'contexts/userContext';
import { checkActionsPerms } from 'services/permissions';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useComplianceChecklistVerifyPersonId } from 'contexts/creditNote/complianceCheckListContext';
import { useCreditNoteFormContext } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import { useTenant } from 'contexts/tenantContext';
import { canEnableClosure, shouldShowLiquidationClosureButton } from 'services/creditNote';
import { isZetraProduct } from 'services/zetra';

interface ButtonProps {
    label: any;
    onClick?: () => void;
    startIcon?: React.ReactNode;
    type?: 'button' | 'submit' | 'reset' | undefined;
    visible: boolean;
    target?: string;
    size?: 'primary' | 'secondary';
}

export const Button = ({
    label,
    onClick,
    startIcon,
    type,
    visible,
    size = 'secondary',
    ...props
}: ButtonProps): JSX.Element => {
    const { submitting } = useFormContext();
    return (
        <>
            {visible && (
                <ButtonBase
                    type={type}
                    formTarget={props.target}
                    sx={{
                        cursor: 'pointer',
                    }}
                    variant="text"
                    disabled={submitting}
                    size="large"
                    onClick={onClick}
                    startIcon={startIcon}
                    {...props}
                >
                    <Typography variant="sm" color="neutral.dark">
                        {label}
                    </Typography>
                </ButtonBase>
            )}{' '}
        </>
    );
};

const CreditNoteButtons = () => {
    const {
        assign,
        isNewCreditNote,
        assignmentPreview,
        draftPreview,
        submitApproval,
        approveLiquidation,
        rejectLiquidation,
        approveSignature,
        handleSigature,
        rejectSignatures,
        approvePaymentRevision,
        paymentRevisionLiquidationSchedule,
        sendSignatures,
        updateUploads,
        simulate,
        getSimulation,
        approveInstrument,
        rejectInstrument,
        approveCredit,
        rejectCredit,
        approveCompliance,
        rejectCompliance,
        cancel,
        deleteOp,
        manualUpdate,
        sendDocuments,
        clone,
        status,
        uploads,
        deleteCheckList,
        unlockOperation,
        transferReceiptPdf,
        manualWarrantyMutate,
        approveMarginReserve,
        disapproveMarginReserve,
        reserveToken,
        showTokensRegistered,
        reprocessByStatus,
        fraudRecord,
        handleMarginQuery,
        installmentCreditNote,
        approveLiquidationSchedule,
        checkAverbation,
        onLiquidationScheduleClosure,
        onLiquidationScheduleClosureRevision,
        onReleaseToPayment,
        handleConsignment,
        handleChangeCalendar, 
        showListWorkflows
    } = useCreditNoteActions();

    const { appConfig } = useAppConfig();
    const { watch, getValues } = useFormContext();
    const { id } = useParams();
    const { product } = useCreditNoteFormContext();
    const { refetch } = useCreditData(id!);
    const { data: permissionsData, hasPermission } = useUserPermissionData();
    const personId = getValues('personId');
    const personDiscriminator = getValues('personDiscriminator');
    const { data: complianceChecklist } = useComplianceChecklistVerifyPersonId(personId!);
    const hasPermissionCreateOrUpdate = hasPermission('CreditNote', id === 'nova' ? 'Create' : 'Update')
    const { isRootTenancy } = useTenant();

    const tenantUser = appConfig?.USER_POOL_ID;
    const subscriptionLink = !!appConfig?.SUBSCRIPTION_LINK;
    const isApproveAndRejectCredit = !!appConfig?.APPROVE_REJECT_CREDIT;
    const hasFund = !!watch('fundId');
    const liquidationMode = watch('liquidationMode');
    const isLiquidationSchedule = liquidationMode === 'LiquidationSchedule';
    const actionApproveLiquidation = isLiquidationSchedule
        ? approveLiquidationSchedule
        : approveLiquidation;
    const actionDoneRevision = isLiquidationSchedule
        ? paymentRevisionLiquidationSchedule
        : approvePaymentRevision;

    const hasTenantComplianceApprove = product?.complianceApprovalTenantName === tenantUser;
    const hasTenantLiquidationApprove = product?.liquidationApprovalTenantName === tenantUser;
    const instrumentType = product?.instrumentType?.toLowerCase();
    const warrantyRegistrationOffice = product?.warrantyRegistrationOffice?.toLowerCase();

    const hasActionPermission = (action: string) => checkActionsPerms(action, permissionsData, 'CreditNote')
    const hasPermLiquidationApproval = hasActionPermission('LiquidationApproval');
    const hasApproveLiqudationPermission = (status === 'Liquidation' || status === 'ManualLiquidation') && hasPermLiquidationApproval;

    const showCancelCreditNote = (): boolean => {
        const defaultStatus = [
            'Draft',
            'Revision',
            'ComplianceApproval',
            'InstrumentApproval',
            'CreditApproval',
            'Signatures',
            'SignaturesValidation',
            'MarginReserveApproval',
            'ManualWarranty',
        ];
        if (
            (isRootTenancy || hasTenantLiquidationApprove) &&
            hasPermission('CreditNote', 'Read') &&
            hasActionPermission('Cancel') &&
            hasActionPermission('AdminCancel')
        ) {
            return [
                ...defaultStatus,
                'ManualLiquidation',
                'WaitLiquidation',
                'Liquidation',
                'PaymentRevision',
                'Finished',
                'Error',
                'Warranty',
            ].includes(status!);
        } else if (hasPermission('CreditNote', 'Read') && hasActionPermission('Cancel')) {
            return defaultStatus.includes(status!);
        }

        return false;
    };

    const showCreditApproveAndOnReject = (status: string) => {
        if (
            status &&
            isApproveAndRejectCredit &&
            status === 'CreditApproval' &&
            hasActionPermission('CreditApproval')
        )
            return true;
        return;
    };
    const showComplianceApproveAndOnReject = (status: string) => {
        if (
            status &&
            isApproveAndRejectCredit &&
            (isRootTenancy || hasTenantComplianceApprove) &&
            status === 'ComplianceApproval' &&
            hasActionPermission('ComplianceApproval')
        )
            return true;
        return;
    };

    const showApproveInstrumentAndOnReject = (status: string) => {
        if (
            status &&
            isApproveAndRejectCredit &&
            ['InstrumentApproval'].includes(status) &&
            hasActionPermission('InstrumentApproval')
        )
            return true;
        return;
    };

    const showSubmitApproval = (status: string) => {
        if (
            !!appConfig.SEND_CREDIT_APPROVAL &&
            ['Disapproved', 'Error', 'Draft', 'Revision'].includes(status!) &&
            hasActionPermission('SubmitApproval')
        )
            return true;
        return;
    };

    const showButtonUnlock = (status: string) => {
        if (
            !isNewCreditNote &&
            hasActionPermission('ComplianceApproval') &&
            ['Draft', 'ComplianceApproval', 'Revision', 'ManualLiquidation'].includes(status!)
        ) {
            return true;
        }
        return;
    };

    const showButtonCompleteSignature = (): boolean => {
        const uploads = watch('uploads') ?? [];
        const hasSignedContract = uploads.filter((item: IUploadModel) => {
            return item.fileType.includes('SignedContract');
        });
        if (hasSignedContract?.length > 0) return true;
        return false;
    };

    const showButtonApproveOrDisapproveMargimReserve = (): boolean => {
        if (
            hasActionPermission('ApproveMarginReserve') &&
            ['MarginReserveApproval'].includes(status!)
        ) {
            return true;
        }
        return false;
    };

    const showButtonReserveToken = (): boolean => {
        if (
            checkActionsPerms('RequestDataSet', permissionsData, 'NaturalPerson') &&
            warrantyRegistrationOffice === 'zetra' &&
            !isNewCreditNote
        ) {
            return true;
        }
        return false;
    };

    const enableUpdateDocs = () => {
        const hasPermissionUploadDocs = hasActionPermission('UploadDocs');

        if (isRootTenancy && hasPermissionUploadDocs) {
            return true;
        } else if (
            !isRootTenancy &&
            hasPermissionUploadDocs &&
            ['InstrumentApproval', 'Signatures', 'Draft'].includes(status!)
        ) {
            return true;
        }
        return false;
    };

    const enableSiapeMarginQuery = (): boolean => {
        if (
            instrumentType === 'publicpayrollloan' &&
            warrantyRegistrationOffice === 'siape' &&
            isNewCreditNote &&
            hasActionPermission('ReadDataSet') &&
            personDiscriminator === 'NaturalPerson'
        ) {
            return true;
        }
        return false;
    };

    const showCheckAverbation = (): boolean => {
        if (
            hasActionPermission('AdminCancel') &&
            product?.amortizationType?.toLowerCase() === 'fgts' &&
            !isNewCreditNote
        ) {
            return true;
        }
        return false;
    };

    const enableBtnSiap = enableSiapeMarginQuery();

    const showManualWarranty = (status: string) => {
        return status === 'ManualWarranty' && hasActionPermission('ApproveWarranty');
    };

    const hasDraftDoc = uploads?.find((file: UploadGet) => file.fileType === 'Draft');
    const hasAssignmentDoc = uploads?.find((file: UploadGet) => file.fileType === 'Assignment');
    const showBtnSave =
        ['', 'Draft', 'Error', 'Disapproved', 'Revision'].includes(status!) &&
        hasPermissionCreateOrUpdate;

    const transitoryBankAccountId = watch('transitoryBankAccountId');
    const watchLiquidationClosureStatus = watch('liquidationClosureStatus');
    const showLiquidationClosureButton = shouldShowLiquidationClosureButton(watchLiquidationClosureStatus);

    const canEnableClosureAction = canEnableClosure(
        status,
        transitoryBankAccountId,
        showLiquidationClosureButton,
        isLiquidationSchedule,
        hasPermLiquidationApproval
    );

    const isInRevision = watchLiquidationClosureStatus === 'Revision';
    const canEnableRevisionAction = canEnableClosure(
        status,
        transitoryBankAccountId,
        isInRevision,
        isLiquidationSchedule,
        hasPermLiquidationApproval
    );

    const isZetra = isZetraProduct(product);
    const useReleaseToPaymentTrigger = product?.useReleaseToPaymentTrigger;

    return (
        <>
            <Actions
                numberOfButtons={4}
                buttonsActionsList={[
                    {
                        label: 'Enviar para aprovação',
                        action: submitApproval,
                        enable: showSubmitApproval(status!),
                        icon: <ApprovalIcon />,
                    },
                    {
                        label: 'Aprovar compliance',
                        action: approveCompliance,
                        enable: showComplianceApproveAndOnReject(status!),
                        icon: <ApproveIcon />,
                    },
                    {
                        label: 'Rejeitar compliance',
                        action: rejectCompliance,
                        enable: showComplianceApproveAndOnReject(status!),
                        icon: <RejectIcon />,
                    },
                    {
                        label: 'Aprovar instrumento',
                        action: approveInstrument,
                        enable: showApproveInstrumentAndOnReject(status!),
                        icon: <ApproveIcon />,
                    },
                    {
                        label: 'Rejeitar instrumento',
                        action: rejectInstrument,
                        enable: showApproveInstrumentAndOnReject(status!),
                        icon: <RejectIcon />,
                    },
                    {
                        label: 'Aprovar crédito',
                        action: approveCredit,
                        enable: showCreditApproveAndOnReject(status!),
                        icon: <ApproveIcon />,
                    },
                    {
                        label: 'Rejeitar crédito',
                        action: rejectCredit,
                        enable: showCreditApproveAndOnReject(status!),
                        icon: <RejectIcon />,
                    },
                    {
                        label: 'Aprovar reserva de margem',
                        action: approveMarginReserve,
                        icon: <ApproveIcon />,
                        enable: showButtonApproveOrDisapproveMargimReserve()
                    },
                    {
                        label: 'Rejeitar reserva de margem',
                        action: disapproveMarginReserve,
                        icon: <RejectIcon />,
                        enable: showButtonApproveOrDisapproveMargimReserve()
                    },
                    {
                        label: 'Adicionar token consignado',
                        action: reserveToken,
                        icon: <ChartWaveRectangleIcon />,
                        enable: showButtonReserveToken()
                    },
                    {
                        label: 'Verificar averbação',
                        action: checkAverbation,
                        icon: <CheckCircleIcon />,
                        enable: showCheckAverbation()
                    },
                    {
                        label: 'Aprovar liquidação',
                        action: actionApproveLiquidation,
                        enable:
                            hasApproveLiqudationPermission &&
                            (isRootTenancy || hasTenantLiquidationApprove),
                        icon: <ApproveIcon />,
                    },
                    {
                        label: 'Aprovar liberação de pagamento',
                        action: onReleaseToPayment,
                        enable:
                            useReleaseToPaymentTrigger &&
                            hasApproveLiqudationPermission &&
                            (isRootTenancy || hasTenantLiquidationApprove),
                        icon: <ApproveIcon />,
                    },
                    {
                        label: 'Rejeitar liquidação',
                        action: rejectLiquidation,
                        enable:
                            hasApproveLiqudationPermission &&
                            !isLiquidationSchedule &&
                            (isRootTenancy || hasTenantLiquidationApprove),
                        icon: <RejectIcon />,
                    },
                    {
                        label: 'Aprovar assinatura',
                        action: approveSignature,
                        enable:
                            status === 'SignaturesValidation' &&
                            (isRootTenancy || hasTenantComplianceApprove),
                        icon: <ApproveIcon />,
                    },
                    {
                        label: 'Rejeitar assinatura',
                        action: rejectSignatures,
                        enable:
                            status === 'SignaturesValidation' &&
                            (isRootTenancy || hasTenantComplianceApprove),
                        icon: <RejectIcon />,
                    },
                    {
                        label: 'Concluir revisão',
                        action: actionDoneRevision,
                        enable: status === 'PaymentRevision',
                        icon: <ApproveIcon />,
                    },
                    {
                        label: 'Concluir garantia',
                        action: manualWarrantyMutate,
                        enable: showManualWarranty(status!),
                        icon: <DoneIcon />,
                    },
                    // {
                    //     label: 'Verificar pagamento',
                    //     action: checkCredPayOperation,
                    //     enable:
                    //         status === 'CreditApproval' && hasActionPermission('CreditApproval'),
                    //     icon: <PaymentLinkIcon />,
                    // },
                    {
                        label: 'Concluir assinatura',
                        action: handleSigature,
                        enable: status === 'Signatures' && showButtonCompleteSignature(),
                        icon: <DoneIcon />,
                    },
                    {
                        label: 'Simular',
                        action: simulate,
                        enable: ['Draft', 'Revision', '', 'Disapproved'].includes(status!),
                        icon: <SimulationIcon />,
                    },
                    {
                        label: 'Salvar',
                        enable: showBtnSave,
                        icon: <SaveIcon />,
                        type: 'submit',
                    },
                    {
                        label: 'Revisar conta trasitória',
                        enable: canEnableRevisionAction,
                        icon: <ZoomTextIcon sx={iconSx} />,
                        action: onLiquidationScheduleClosureRevision,
                    },
                    {
                        label: 'Encerrar conta trasitória',
                        enable: canEnableClosureAction,
                        icon: <BankingIcon />,
                        action: onLiquidationScheduleClosure,
                    },
                    {
                        label: 'Atualizar',
                        enable: !isNewCreditNote,
                        icon: <UpdateIcon />,
                        action: refetch,
                    },
                    {
                        label: 'Visualizar tokens',
                        action: showTokensRegistered,
                        icon: <ShowIcon />,
                        enable: showButtonApproveOrDisapproveMargimReserve(),
                    },
                    {
                        label: 'Salvar documentos',
                        action: updateUploads,
                        enable: enableUpdateDocs() && !isNewCreditNote,
                        icon: <InvoiceReceivableIcon />,
                    },
                    {
                        label: 'Duplicar',
                        action: clone,
                        enable: !isNewCreditNote && hasActionPermission('Clone'),
                        icon: <ShareIcon />,
                    },
                    {
                        label: 'Definir status',
                        action: manualUpdate,
                        enable:
                            !isNewCreditNote &&
                            hasActionPermission('ManualUpdate') &&
                            isRootTenancy,
                        icon: <SettingsIcon />,
                    },
                    {
                        label: 'Atribuir',
                        action: () => assign(true),
                        enable: !isNewCreditNote && hasActionPermission('AssignRecord'),
                        icon: <OperatorIcon />,
                    },
                    {
                        label: 'Excluir',
                        enable: !isNewCreditNote && hasPermission('CreditNote', 'Delete'),
                        icon: <DeleteIcon />,
                        action: deleteOp,
                    },
                    {
                        label: 'Cancelar',
                        enable: showCancelCreditNote(),
                        icon: <CloseIcon />,
                        action: cancel,
                    },
                    {
                        label: 'Recuperar simulação',
                        action: getSimulation,
                        enable: ['Draft', ''].includes(status!),
                        icon: <LowGraphIcon />,
                    },
                    {
                        label: 'Remover restrições',
                        action: deleteCheckList,
                        enable: isRootTenancy && complianceChecklist?.length > 0,
                        icon: <DeleteIcon />,
                    },
                    {
                        label: 'Enviar e-mail',
                        action: sendDocuments,
                        enable:
                            ['Draft', 'Signatures'].includes(status!) &&
                            hasActionPermission('SendAssigmentEmail'),
                        icon: <EmailIcon />,
                    },

                    {
                        enable: enableBtnSiap || isZetra,
                        label: 'Consultar',
                        icon: <CompleteIcon htmlColor="#000000" />,
                        groupButtons: [
                            {
                                enable: true,
                                label: 'Margem',
                                action: handleMarginQuery,
                                icon: <GraphicWithBarIcon htmlColor="#000000" />,
                            },
                            {
                                enable: isZetra,
                                label: 'Consignação para compra',
                                action: handleConsignment,
                                icon: <BankingIcon htmlColor="#000000" />,
                            },
                        ],
                    },
                    {
                        enable: !isNewCreditNote && isLiquidationSchedule,
                        label: 'Calendário de troco',
                        action: handleChangeCalendar,
                        icon: <CalendarCheckIcon htmlColor="#000000" />,
                    },
                    {
                        label: 'Enviar link assinaturas',
                        action: sendSignatures,
                        enable: status === 'Signatures' && subscriptionLink,
                        icon: <SignatureIcon />,
                    },
                    {
                        label: 'Gerar contrato',
                        action: draftPreview?.onClick,
                        enable: ['Draft'].includes(status!) && !hasDraftDoc,
                        icon: <BankSlipIcon />,
                    },
                    {
                        label: 'Gerar cobrança',
                        action: installmentCreditNote,
                        enable: hasPermission('BillingAccount', 'Create'),
                        icon: <WalletIcon />
                    },
                    {
                        label: 'Reiniciar Esteira',
                        action: reprocessByStatus,
                        enable:
                            !isNewCreditNote &&
                            isRootTenancy &&
                            hasActionPermission('RestartWorkflow'),
                        icon: <ReloadRectangleIcon />,
                    },
                    {
                        label: 'Gerar comprovantes',
                        action: transferReceiptPdf,
                        enable: [
                            'Liquidation',
                            'ManualLiquidation',
                            'WaitLiquidation',
                            'Finished',
                        ].includes(status!),
                        icon: <ReportIcon />,
                    },
                    {
                        label: 'Exibir contrato',
                        link: {
                            isLink: draftPreview?.href ? draftPreview?.href?.length > 0 : false,
                            href: draftPreview?.href ?? '',
                        },
                        enable: hasDraftDoc && ['Draft'].includes(status!),
                        icon: <ShowIcon />,
                    },
                    {
                        label: 'Gerar cessão',
                        action: assignmentPreview?.onClick,
                        enable:
                            hasFund &&
                            [
                                'Draft',
                                'Liquidation',
                                'Signatures',
                                'SignaturesValidation',
                                'PaymentRevision',
                                'ManualLiquidation',
                                'Liquidation',
                            ].includes(status!) &&
                            !hasAssignmentDoc,
                        icon: <BankSlipIcon />
                    },
                    {
                        label: 'Exibir cessão',
                        link: {
                            isLink: assignmentPreview?.href
                                ? assignmentPreview?.href?.length > 0
                                : false,
                            href: assignmentPreview?.href ?? '',
                        },
                        enable:
                            hasAssignmentDoc &&
                            hasFund &&
                            ['Draft', 'Liquidation', 'Signatures'].includes(status!),
                        icon: <ShowIcon />,
                    },
                    {
                        label: 'Desbloquear operação',
                        action: unlockOperation,
                        enable: showButtonUnlock(status!),
                        icon: <LockOpenIcon fontSize="large" htmlColor="#373737" />,
                    },
                    {
                        label: 'Registrar fraude',
                        action: fraudRecord,
                        enable:
                            isRootTenancy &&
                            !isNewCreditNote &&
                            showComplianceApproveAndOnReject(status!),
                        icon: <ShieldWarningIcon sx={iconSx} />,
                    },
                    {
                        label: 'Esteiras',
                        action: showListWorkflows,
                        enable: !isNewCreditNote && hasActionPermission('RestartWorkflow'),
                        icon: <LayoutIcon sx={iconSx} />,
                    },
                ]}
            />
        </>
    );
};

export default CreditNoteButtons;
