export type ResourceNotificationType =
    | "Bankslip"
    | "BankAccount"
    | "CreditNote"

export type FiltersGetNotificationType = {
    from: number;
    size: number;
    onlyNew: boolean;
}

export const notificationLevelEnumOptions: { [type: string]: number } = {
    "None": 0,
    "OnlyMine": 1,
    "MyGroups": 2,
    "All": 3
}

export const entityEnabledFromRedirect = [
    "Cobranca",
    "RemessaFile",
    "RetornoFile",
    "ConvenioCedente",
    "BankAccount",
];