import { FormProvider } from 'contexts/formContext';
import React from 'react'
import { defaultValuesUpdateCommunicationSettingSchema, updateCommunicationSettingSchema } from './UpdateCommunicationSettingSchema';
import { IUpdateCommunicationByInstallmentSetting } from 'services/walletManagement/installment/installment.types';
import { UpdateCommunicationSettingForm } from './UpdateCommunicationSettingForm';
import { FieldValues } from 'react-hook-form';

type UpdateCommunicationSettingContainerProps = {
    rowData: FieldValues;
    onSubmit: (values: IUpdateCommunicationByInstallmentSetting) => void;
    onClose: () => void
    isLoading: boolean
    walletsCode: number[]
}
export const UpdateCommunicationSettingContainer = ({ rowData, onSubmit, onClose, isLoading, walletsCode }: UpdateCommunicationSettingContainerProps) => {
    return (
        <FormProvider {...{
            defaultValues: defaultValuesUpdateCommunicationSettingSchema,
            validationSchema: updateCommunicationSettingSchema(rowData!),
            onSubmit
        }}>
            <UpdateCommunicationSettingForm {...{onClose, isLoading, walletsCode }} />
        </FormProvider>
    )
}
