import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { GenerateFileLiquidationHeader } from './GenerateFileLiquidationHeader';
import { FormProvider } from 'contexts/formContext';
import { defaultValuesGenerateFileLiquidationSchema, validationSchemaGenerateFileLiquidationSchema } from './Forms/GenerateFileLiquidationSchema';
import { FieldValues } from 'react-hook-form';
import { useIdentity } from 'contexts/identityContext';
import { ApiResponseError, ToastType, handleOnError, toastState, useApiRequest } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { RefreshProgress } from 'components/RefreshProgress';
import { LiquidationReportTable, RemessaLiquidacaoForDateForm } from './Forms/RemessaLiquidacaoForDateForm/RemessaLiquidacaoForDateForm';
import { IRemessaLiquidacaoForDate, LiquidationFileApiResponse } from 'services/remessasVortx/remessasVortx.type';
import { remessaLiquidacaoForDate } from 'services/remessasVortx/remessasVortx';

export const GenerateFileLiquidationContainer = () => {
    const { token } = useIdentity();
    const { startRequest, endRequest, submitting: isLoading } = useApiRequest();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [reports, setReports] = useState<LiquidationFileApiResponse | null>(null)

    const handleSubmitForm = async (values: FieldValues) => {
        startRequest();
        try {
            var { data } = await remessaLiquidacaoForDate(values as IRemessaLiquidacaoForDate, token!);           
            const responseData = data as LiquidationFileApiResponse
            setReports(responseData)
            endRequest(true)
        } catch (error) {
            handleOnError(error as ApiResponseError, setToast)
            endRequest(false)
        }
    }

    return (
        <Stack>
            <Toast toast={toast} setToast={setToast} />
            <RefreshProgress refreshing={isLoading} />
            <FormProvider
                defaultValues={defaultValuesGenerateFileLiquidationSchema}
                validationSchema={validationSchemaGenerateFileLiquidationSchema()}
                onSubmit={handleSubmitForm}
            >
                <Stack marginBottom={2} >
                    <GenerateFileLiquidationHeader isLoading={isLoading} />
                    <RemessaLiquidacaoForDateForm />
                    {reports != null && <LiquidationReportTable reports={reports} />}
                </Stack>
            </FormProvider>
        </Stack>
    )
}
