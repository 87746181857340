import { Drawer } from '@uy3/web-components';
import { FormProvider } from 'contexts/formContext';
import { defaultValuesMarginZetra, validationSchemaUpdateMarginZetra } from './UpdateZetraSchema';
import UpdateZetraForm from './UpdateZetraForm';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { TUpdateZetraProps, ZetraMarginQueryUpdateModel } from 'services/zetra';
import { useState } from 'react';
import { ReserveTokenContainer } from 'components/ReserveToken/ReserveTokenContainer';
import { ToastType } from 'contexts/apiRequestContext';
import { FieldValues } from 'react-hook-form';

type UpdateZetraProps = {
    isUpdateZetra: boolean;
    onCloseDrawer: () => void;
    mutateUpdateZetra: UseMutateAsyncFunction<
        ZetraMarginQueryUpdateModel,
        unknown,
        TUpdateZetraProps,
        unknown
    >;
    registrationNumber: string;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    creditProductId?: string;
    employeeCode?: string
};

export function UpdateZetra({
    isUpdateZetra,
    onCloseDrawer,
    mutateUpdateZetra,
    registrationNumber,
    setToast,
    creditProductId,
    employeeCode
}: UpdateZetraProps) {
    const [isNewToken, setIsNewToken] = useState(false);
    const [tokenFormValues, setTokenFormValues] = useState<FieldValues[] | undefined>();

    const handleOnChangeToken = (values: FieldValues[]) => {
        setTokenFormValues(values);
        setIsNewToken(false);
    };

    const handleOnUpdateZetra = (values: FieldValues) => {
        const payload = {
            ...values,
            tokens: tokenFormValues || [],
            creditProductId,
            employeeCode: values?.employeeCode
        };

        mutateUpdateZetra(payload);
    };

    return (
        <Drawer
            anchor="right"
            open={isUpdateZetra}
            title="Atualizar margem"
            toggleDrawer
            onClose={onCloseDrawer}
        >
            {isNewToken ? (
                <ReserveTokenContainer
                    {...{
                        onClose: () => setIsNewToken(false),
                        setToast,
                        onChange: handleOnChangeToken,
                    }}
                />
            ) : (
                <FormProvider
                    validationSchema={validationSchemaUpdateMarginZetra}
                    defaultValues={defaultValuesMarginZetra(registrationNumber, employeeCode)}
                    onSubmit={handleOnUpdateZetra}
                >
                    <UpdateZetraForm
                        {...{
                            setIsNewToken,
                            onCloseDrawer,
                        }}
                    />
                </FormProvider>
            )}
        </Drawer>
    );
}
