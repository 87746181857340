import { styled } from '@mui/material';

export const CustomConnector = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'isCompleted',
})<{ isActive?: boolean; isCompleted?: boolean }>(({ theme, isActive, isCompleted }) => ({
  margin: 0,
  padding: 0,
  width: '100%',
  height: '2px',
  borderRadius: '1px',
  backgroundColor: theme.palette.neutral.light,
  transition: 'background-color 0.3s ease',

  ...(isActive && {
    backgroundColor: theme.palette.primary.main,
  }),

  ...(isCompleted && {
    backgroundColor: theme.palette.success.main,
  }),
}));

export const CustomStep = styled('div')`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 28px;
  width: 100%;
`;

export const CustomStepper = styled('div')`
  display: flex;
  gap: 28px;
`;
