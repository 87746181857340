import { css, styled } from '@mui/material';

export const CustomStepIcon = styled('div')<{
  active?: boolean;
  completed?: boolean;
  hasOnClick?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 28px;
  min-height: 28px;
  max-width: 28px;
  max-height: 28px;
  border-radius: 50%;
  font-size: ${({ theme }) => theme.typography?.md?.fontSize};
  font-weight: 700;
  color: ${({ theme, active }) =>
    active ? theme.palette.primary.main : theme.palette.neutral.light};
  background-color: transparent;
  border: 1px solid
    ${({ theme, active }) => (active ? theme.palette.primary.main : theme.palette.neutral.light)};

  ${({ completed, theme }) =>
    completed &&
    css`
      color: ${theme.palette.success.main};
      border-color: ${theme.palette.success.main};
    `}

  ${({ hasOnClick }) =>
    hasOnClick &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;
