import { IOption } from 'components/CardSelect/CardSelect';
import { CheckPurchaseContractsReadModel } from 'services/zetra';
import { TooltipConsignment } from './tooltipComponent';
import { formatDate, maskCurrencyInCents } from 'helpers';

export function summaryConsignment(data: CheckPurchaseContractsReadModel | undefined): IOption[] {
    const resumos = data?.resumos ?? [];

    const options: IOption[] = [];

    resumos.forEach((item, index) => {
        options.push({
            label: `ADE: ${item?.adeNumero} - Data Reserva: ${formatDate(item?.dataReserva)} - ${
                item?.situacao
            }`,
            description: `Valor da Parcela: ${maskCurrencyInCents(item?.valorParcela)} - Prazo: ${
                item?.prazo
            } - Pagas: ${item?.pagas} `,
            value: `${item?.adeNumero}-${item?.adeIdentificador}-${item?.prazo}`,
            disabled: false,
            tooltipComponent: {
                title: <TooltipConsignment values={item!} currentIndex={index+1}/>,
                placement: 'left',
                children: <></>,
            },
        });
    });

    return options;
}
