import { useMutation, useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { ApiResponseError, useApiRequest } from "contexts/apiRequestContext";
import { useAppConfig } from "contexts/appConfig";
import { useIdentity } from "contexts/identityContext";
import { handleErrorUseQuery } from "helpers";
import { getNotificationsAsync, getNotificationsSettingsAsync, postNotificationSetLastRead, postNotificationSettingsAsync } from "services/notifications";
import { FiltersGetNotificationType } from "services/notifications/types/generic";
import { NotificationsLastReadCreateModel } from "services/notifications/types/notificationsLastReadCreateModel";
import { NotificationSettingReadModel } from "services/notifications/types/notificationsReadModel";
import { isValidString } from "helpers/formats/StringFormats";
import { useState } from "react";
import { NotificationSettingsUpsertRequest } from "services/notifications/types/notificationsCreateModel";

export function useGetNotifications(params?: Partial<FiltersGetNotificationType>) {
    const { token } = useIdentity();
    const { appConfig } = useAppConfig();

    const [page, setPage] = useState<number>(1);
    const [from, setFrom] = useState<number>(0);

    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const hasSearchApiUrl = isValidString(String(appConfig?.SEARCH_API_URL ?? ""));

    const sizePaginationDefault = 20;

    const onNextPageNotification = () => {
        setPage((prev) => prev + 1);
        setFrom(page * sizePaginationDefault);
    };

    const restoreFiltersPagination = () => {
        setPage(1);
        setFrom(0);
    };

    const filters = {
        from: from,
        size: sizePaginationDefault,
        onlyNew: params?.onlyNew ?? false
    } as FiltersGetNotificationType;

    const queryContext = useQuery({
        enabled: hasSearchApiUrl && !!token,
        refetchIntervalInBackground: false,
        retry: false,
        refetchInterval: (appConfig.TIMER_TO_NOTIFICATION_IN_SECONDS ?? 30) * 1000,
        refetchOnWindowFocus: false,
        queryKey: ['get-notifications-list', filters, from, page],
        queryFn: async () => {
            startRequest();
            const { data, status, statusText } = await getNotificationsAsync(filters, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);

            return data;
        },
    });

    return {
        ...queryContext, 
        onNextPageNotification, 
        restoreFiltersPagination, 
        hasFilterApplied: page !== 1 && from !== 0
    }
}

export function useGetNotificationsSettings() {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-notifications-settings-list'],
        queryFn: async (): Promise<NotificationSettingReadModel> => {
            startRequest();
            const { data, status, statusText } = await getNotificationsSettingsAsync(token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as NotificationSettingReadModel;
        }
    });
}

export function useNotificationSettingMutations(
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (payload: NotificationSettingsUpsertRequest) => {
            startRequest();
            const { data } = await postNotificationSettingsAsync(payload, token!);
            endRequest(true);
            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

export function useNotificationLastReadMutation(
    onSuccess?: (data: unknown) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (payload: NotificationsLastReadCreateModel) => {
            startRequest();
            const { data } = await postNotificationSetLastRead(payload, token!);
            endRequest(true);
            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, () => onError&&onError(error as ApiResponseError));
        },
    });
}