import { onlyNumbers } from 'helpers';
import moment, { DurationInputArg2 } from 'moment';
import { useForm } from 'react-hook-form';
import { PayrollAgreementReadModel } from 'services/creditNote';
interface ICalculationProps {
    startDateString: string;
    executeAmortizationBasedOnType: boolean;
    setValue: ReturnType<typeof useForm>['setValue'];
    watch: ReturnType<typeof useForm>['watch'];
    isFirstPaymentAutoSet: number;
}

function isValidDayOfMonth(day: number): boolean {
    return typeof day === 'number' && day >= 1 && day <= 31;
}

export function calculateAndSetFirstPaymentDate({
    startDateString,
    executeAmortizationBasedOnType,
    setValue,
    watch,
    isFirstPaymentAutoSet,
}: ICalculationProps): void {
    const isValidFirstPaymentDay = isValidDayOfMonth(isFirstPaymentAutoSet);
    const paymentPeriodicity: number = watch('amortization.paymentPeriodicity.every');
    const periodicity: string = watch('amortization.paymentPeriodicity.periodicity');
    const existingFirstPaymentDate = watch('amortization.firstPaymentDate');

    const startDateMoment = moment(startDateString);
    const isUndefined = typeof existingFirstPaymentDate === 'undefined';
    const shouldCalculateFirstPaymentDate = paymentPeriodicity && !isValidFirstPaymentDay && executeAmortizationBasedOnType && startDateMoment.isValid();

    if ((isUndefined || startDateString) && isValidFirstPaymentDay) {
        const startDate: Date = new Date(startDateString);
        if (!isNaN(startDate.getTime())) {
            const firstDayOfNextMonth: Date = getFirstDayOfNextMonth(
                startDate,
                isFirstPaymentAutoSet
            );
            setValue('amortization.firstPaymentDate', firstDayOfNextMonth);
        }
    } else if (shouldCalculateFirstPaymentDate) {
        const addPeriodicity = getPeriodicityUnit(periodicity ?? 'days');
        const newDate = startDateMoment.clone().add(paymentPeriodicity, addPeriodicity);
        setValue('amortization.firstPaymentDate', newDate);
    }
}

function getPeriodicityUnit(periodicity: string): DurationInputArg2 {
    const objPeriodicity = {
        Daily: 'days',
        Monthly: 'months',
        Yearly: 'years',
    };
    return objPeriodicity[periodicity as keyof typeof objPeriodicity] as DurationInputArg2;
}

function getFirstDayOfNextMonth(date: Date, isFirstPaymentAutoSet: number): Date {
    const nextMonth = date.getMonth() + 2;
    const nextYear = date.getFullYear();
    return new Date(nextYear, nextMonth, isFirstPaymentAutoSet);
}

export function getPayrollLoanDetails(
    warranty: any[],
    payrollAgreements: PayrollAgreementReadModel[],
    isPublicPayrollLoan: boolean
) {
    let paymentTransferDayValue;
    let warrantyRegistrationCutoffDayValue;

    if (isPublicPayrollLoan && Array.isArray(payrollAgreements) && payrollAgreements.length > 0) {
        const codOrgao = onlyNumbers(warranty?.[0]?.codOrgao ?? "0");
        const payrollAgreement = payrollAgreements.find((pa) => pa.internalId === codOrgao);

        if (payrollAgreement) {
            paymentTransferDayValue = payrollAgreement.paymentTransferDay;
            warrantyRegistrationCutoffDayValue = payrollAgreement.warrantyRegistrationCutoffDay;
        }
    }

    return { paymentTransferDayValue, warrantyRegistrationCutoffDayValue };
}

export function setPaymentTransferAndCutoffDay(
    startDateString: string,
    paymentTransferDayValue: number,
    CutoffDayValue: number,
    setValue: ReturnType<typeof useForm>['setValue']
) {
    const startDate = new Date(startDateString);
    const cutoffDate = new Date(startDate);
    cutoffDate.setDate(CutoffDayValue);

    if (startDate > cutoffDate) {
        if (paymentTransferDayValue) {
            const firstDayOfNextMonth: Date = getFirstDayOfNextMonth(
                startDate,
                paymentTransferDayValue
            );
            setValue('amortization.firstPaymentDate', firstDayOfNextMonth);
        }
    } else {
        const nextMonth = startDate.getMonth() + 2;
        const firstDay = new Date(startDate.getFullYear(), nextMonth, paymentTransferDayValue);
        setValue('amortization.firstPaymentDate', firstDay);
    }
}

export function handlePaymentAndCutoffDays(
    startDateString: string | undefined,
    paymentTransferDay: number,
    cutoffDay: number,
    setValue: ReturnType<typeof useForm>['setValue']
) {
    if (startDateString && !!cutoffDay && !!paymentTransferDay) {
        setPaymentTransferAndCutoffDay(startDateString, paymentTransferDay, cutoffDay, setValue);
    }
}
