import { Grid, Stack } from '@mui/material'
import { Button, DocPdfIcon, LeftIcon, Typography } from '@uy3/web-components'
import moment from 'moment';
import React from 'react'
import { activeTheme } from 'services/theme';

type FinishPixKeyProps = {
  onClose: () => void; 
  finishingFlowByExternal: boolean;
  values: any;
}
const theme = activeTheme();
const FinishPixKey = ({ values, onClose, finishingFlowByExternal}:FinishPixKeyProps) => {
  return (
    <Stack spacing={4}>
      <Grid sx={{border: '1px solid grey', borderRadius: 4, padding: 3}}>
        <Typography fontSize={18} display='block' fontWeight='bold'>
          {`${moment().format('LLLL')}` }
        </Typography>
        <Typography fontSize={16} display='block' >Chave Pix: {values?.pixKey}</Typography>
      </Grid>

      <Stack direction={'row'} alignItems="center" justifyContent="flex-end" spacing={3}>
        <Button
          startIcon={<LeftIcon htmlColor={theme.palette.primary.main} />}
          variant="outlined"
          size="medium"
          onClick={onClose}
        >
          {finishingFlowByExternal ? "Voltar" : "Voltar para minhas chaves"}
        </Button>
        <Button
          startIcon={<DocPdfIcon htmlColor='#A6A6A6' />}
          variant="contained"
          size="medium"
          disabled
          onClick={onClose}
        >
          Salvar em PDF 
        </Button>
      </Stack>
    </Stack>
  )
}

export default FinishPixKey
