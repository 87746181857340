/* eslint-disable react-hooks/exhaustive-deps */
import { ReadNotificationsHeader } from './ReadNotificationsHeader'
import { Fragment, useEffect } from 'react';
import { StepRenderNotificationsEnum } from 'services/notifications/enums/notificationsEnum';
import { useNotificationLastReadMutation } from 'contexts/notifications/notificationsContext';
import { ReadNotificationsSkeleton } from 'components/Skeleton/ReadNotificationsSkeleton';
import { ListNotifications } from './ListNotifications/ListNotifications';
import { useNavigate } from 'react-router';
import { IMetadataNotifications, NotificationsReadModel } from 'services/notifications/types/notificationsReadModel';
import { toIsoStringWithTimezone } from 'helpers/formats/DateFormat';
import { GetListApiElasticSearch } from 'contexts/apiRequestContext';
import { useShowNotification } from 'contexts/showNotificationsContext';

type ReadNotificationsContainerProps = {
    onClose: () => void;
    setRenderStep: React.Dispatch<React.SetStateAction<StepRenderNotificationsEnum | undefined>>
    notificationsData: GetListApiElasticSearch<NotificationsReadModel, IMetadataNotifications> | undefined;
    isLoading: boolean;
    onNextPageNotification: () => void
    restoreFiltersPagination: () => void
    hasFilterApplied: boolean
}

export const ReadNotificationsContainer = ({ onClose, isLoading, notificationsData, onNextPageNotification, hasFilterApplied, restoreFiltersPagination }: ReadNotificationsContainerProps) => {
    const navigate = useNavigate();
    const { setEnableNotifications } = useShowNotification();
    const { mutateAsync: lastReadMutate } = useNotificationLastReadMutation();
    const enableRestoreFilters = hasFilterApplied && notificationsData?.data?.length === 0;
    const metadata = notificationsData?.metadata;

    useEffect(() => {
        if ((metadata?.newNotifications ?? 0) > 0 && !!notificationsData) {
            const mostRecentCreatedAt = notificationsData?.data[0]?.createdAt!;
            setTimeout(() => {
                lastReadMutate({
                    lastRead: toIsoStringWithTimezone(new Date(mostRecentCreatedAt))
                });
            }, 1000);
        }
    }, []);

    const handleLastReadAndRedirect = ({ entity, entityId, ...rest }: NotificationsReadModel) => {
        const optionsLink: { [entity: string]: string } = {
            "Cobranca": `/gestao-carteira/cobrancas/${entityId}`,
            "RemessaFile": `/gestao-carteira/carteiras/arquivos/${entityId}/remessa`,
            "ConvenioCedente": `/gestao-carteira/carteiras/${entityId}`,
            "BankAccount": `/contas-digitais/ativas/${entityId}`,
        };

        const pathRedirect = optionsLink[entity];
        navigate(pathRedirect);
        setEnableNotifications(false);
    }

    return (
        <Fragment>
            <ReadNotificationsHeader onClose={onClose} />
            <ReadNotificationsSkeleton {...{ isLoading }}>
                <ListNotifications
                    notificationsData={notificationsData?.data ?? []}
                    lastRead={metadata?.lastRead}
                    handleLastReadAndRedirect={handleLastReadAndRedirect}
                    enableRestoreFilters={enableRestoreFilters}
                    onNextPageNotification={onNextPageNotification}
                    restoreFiltersPagination={restoreFiltersPagination}
                />
            </ReadNotificationsSkeleton>
        </Fragment>
    )
}
