export enum NotificationTypeEnum {
    "NotInformed" = 0,
    "Notice" = 1,
    "Billing_Cobranca_Pago" = 11,
    "Billing_Cobranca_Registrado" = 12,
    "Billing_Cobranca_Cancelado" = 13,
    "Billing_Cobranca_Protestado" = 14,
    "Billing_Cobranca_EnviadoCartorio" = 15,
    "Billing_Carteira_TarifasAtualizadas" = 21,
    "Billing_ArquivoRemessa_ErroProcessamento" = 31,
    "Credit_CreditNote_StatusUpdate" = 41,
    "Credit_CreditNote_Liquidation_Success" = 42,
    "Credit_CreditNote_Liquidation_Failure" = 43,
    "Banking_AccountEntry_Success" = 61,
    "Banking_AccountEntry_Failure" = 62,
    "Banking_ReverseAccountEntry_Success" = 63,
    "Banking_ReverseAccountEntry_Failure" = 64,
    "Banking_OpenAccount_Success" = 65,
    "Banking_OpenAccount_Failure" = 66,
    "Banking_AccountClose_Success" = 67,
    "Banking_AccountClose_Failure" = 68,
    "Banking_Transfer_Approval_Success" = 69,
    "Banking_Transfer_Approval_Failure" = 70,
    "Banking_Transfer_Success" = 71,
    "Banking_Transfer_Failure" = 72,
    "Banking_BankSlip_Approval_Success" = 73,
    "Banking_BankSlip_Approval_Failure" = 74,
    "Banking_BankSlip_Payment_Success" = 75,
    "Banking_BankSlip_Payment_Failure" = 76   
}

export const NotificationTypeDescriptions: { [key in NotificationTypeEnum]: string } = {
    [NotificationTypeEnum.NotInformed]: "Não informado",
    [NotificationTypeEnum.Notice]: "Comunicados",
    [NotificationTypeEnum.Billing_Cobranca_Pago]: "Boletos recebido",
    [NotificationTypeEnum.Billing_Cobranca_Registrado]: "Boletos registrados",
    [NotificationTypeEnum.Billing_Cobranca_Cancelado]: "Boletos cancelados",
    [NotificationTypeEnum.Billing_Cobranca_Protestado]: "Boletos protestados",
    [NotificationTypeEnum.Billing_Cobranca_EnviadoCartorio]: "Boletos enviados para cartório",
    [NotificationTypeEnum.Billing_Carteira_TarifasAtualizadas]: "Tarifas da carteira atualizadas ",
    [NotificationTypeEnum.Billing_ArquivoRemessa_ErroProcessamento]: "Erro de arquivo remessa",
    
    [NotificationTypeEnum.Credit_CreditNote_StatusUpdate]: "Atualização de status",
    [NotificationTypeEnum.Credit_CreditNote_Liquidation_Success]: "Liquidação realizada",
    [NotificationTypeEnum.Credit_CreditNote_Liquidation_Failure]: "Falha na liquidação",
    [NotificationTypeEnum.Banking_AccountEntry_Success]: "Lançamento realizado",
    [NotificationTypeEnum.Banking_AccountEntry_Failure]: "Falha no lançamento",
    [NotificationTypeEnum.Banking_ReverseAccountEntry_Success]: "Estorno realizado",
    [NotificationTypeEnum.Banking_ReverseAccountEntry_Failure]: "Falha no estorno",
    [NotificationTypeEnum.Banking_OpenAccount_Success]: "Conta aberta",
    [NotificationTypeEnum.Banking_OpenAccount_Failure]: "Falha na abertura de conta",
    [NotificationTypeEnum.Banking_AccountClose_Success]: "Conta encerrada",
    [NotificationTypeEnum.Banking_AccountClose_Failure]: "Falha no encerramento de conta",
    [NotificationTypeEnum.Banking_Transfer_Approval_Success]: "Transferência aprovada",
    [NotificationTypeEnum.Banking_Transfer_Approval_Failure]: "Falha na aprovação de transferência",
    [NotificationTypeEnum.Banking_Transfer_Success]: "Transferência realizada",
    [NotificationTypeEnum.Banking_Transfer_Failure]: "Falha na transferência",
    [NotificationTypeEnum.Banking_BankSlip_Approval_Success]: "Pagamento de boleto aprovado",
    [NotificationTypeEnum.Banking_BankSlip_Approval_Failure]: "Falha na aprovação de pagamento de boleto",
    [NotificationTypeEnum.Banking_BankSlip_Payment_Success]: "Boleto pago",
    [NotificationTypeEnum.Banking_BankSlip_Payment_Failure]: "Falha no pagamento do boleto",

    
};

export function getNotificationTypeDescription(type: NotificationTypeEnum): string {    
    return NotificationTypeDescriptions[type];    
}