import { IconButton, InputAdornment } from '@mui/material';
import {
    BankingIcon,
    SearchIcon,
    TextField,
    TextFieldProps,
    Typography,
} from '@uy3/web-components';
import React, { ComponentType } from 'react';
import { activeTheme } from 'services/theme';
import { useFormContext } from 'contexts/formContext';
import { resolve } from 'services/arrayGetters';
import { NumericFormat, InputAttributes, NumericFormatProps } from 'react-number-format';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import { useAppConfig } from 'contexts/appConfig';
import {
    validateConditionalRequiredBySchema,
    getLabelField,
    isFieldDisable,
    isFieldRequired,
    formatMultiply,
    formatDivide
} from 'helpers';
import { isEmpty } from 'lodash';

interface ICurrencyFormFieldProps extends TextFieldProps {
    name: string;
    showEndAdornment?: boolean;
    onChangeField?: (value: any) => void;
}

interface ICustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    customInput?: ComponentType<any> | undefined;
    value?: string | number | undefined;
}

const theme = activeTheme();
export const NumberFormatCustom = React.forwardRef<NumericFormatProps<InputAttributes>, ICustomProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, customInput, value, name, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values: any) => {
                    onChange({
                        target: {
                            name: name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator="."
                decimalSeparator=","
                valueIsNumericString
                decimalScale={2}
                fixedDecimalScale={true}
                customInput={customInput}
                value={value}
            />
        );
    });

const CurrencyFormField: React.FC<ICurrencyFormFieldProps> = ({
    required,
    label,
    showEndAdornment,
    onChangeField,
    disabled,
    ...props
}) => {
    const { validationErrors, watch, setValue, readOnly } = useFormContext();
    const name = props.name;
    const { appConfig } = useAppConfig();
    const { formFieldsErrors } = useFormFieldsError();
    const formFieldsConfig = appConfig?.FORM_FIELDS;

    const responseError = formFieldsErrors?.find(({ key }) => {
        let fieldName = name.toLowerCase();
        return fieldName.includes(key);
    });

    let value = undefined;
    if (watch) {
        value = watch(name);
        if (value) {
            value = formatDivide(value);
        }
    }

    var error = validationErrors && resolve(name, validationErrors)?.message;

    const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = target;
        let newValue: number;

        if (!isEmpty(value)) {
            const parsedValue = parseFloat(value);
            newValue = formatMultiply(parsedValue, 'currency');
            setValue(name, newValue, { shouldDirty: true });
            onChangeField && onChangeField(newValue);
        } else {
            setValue(name, null, { shouldDirty: true });
        }
    };

    const isConditionalRequired = validateConditionalRequiredBySchema({
        type: validationErrors?.[name]?.type as string,
    });

    const isRequired = isConditionalRequired
        ? isConditionalRequired
        : !!required || isFieldRequired(name);
    const hasResponseError = Object.keys(responseError ?? {}).length > 0;
    const isFieldDisabledConfig = isFieldDisable(name, formFieldsConfig);

    const labelFieldConfig = getLabelField(name, formFieldsConfig);
    const labelField = labelFieldConfig ?? label;
    const labelWithRequired = isRequired ? `${labelField} *` : labelField;
    const isDisabled = isFieldDisabledConfig || readOnly || disabled;
    
    return (
        <>
            <TextField
                id={name}
                label={labelWithRequired}
                error={!!error || hasResponseError}
                disabled={isDisabled}
                value={value === null ? '' : value}
                onChange={handleChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <BankingIcon
                                htmlColor={`${isDisabled ? theme.palette?.grey['400'] : '#666666'}`}
                                sx={{ height: 18, width: 18 }}
                            />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <>
                            {showEndAdornment && (
                                <InputAdornment position="end">
                                    <IconButton type="submit">
                                        <SearchIcon
                                            sx={{ width: 25, height: 25 }}
                                            htmlColor="#666666"
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )}
                        </>
                    ),
                    inputComponent: NumberFormatCustom as any,
                }}
                {...props}
            />

            {!!error && (
                <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
                    <>{error}</>
                </Typography>
            )}
            {!!hasResponseError && (
                <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
                    <>{responseError?.helpMessage}</>
                </Typography>
            )}
        </>
    );
};

export default CurrencyFormField;
