import { Drawer, TextField } from '@uy3/web-components';
import { FormProvider, useFormContext } from 'contexts/formContext';
import React, { useState } from 'react';
import { WarrantyForm } from './WarrantyForm';
import { Grid } from '@mui/material';
import { UF } from 'services/zipCode/zipcode.types';
import { defaultValuesWarranty, validationSchemaWarranty } from './WarrantyFormSchema';
import { useCreditNoteFormContext } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import { onlyNumbers } from 'helpers/validations/formFieldValidator';
import { WarrantyType, IWarrantyReadModel, ConsortiumCreateModel } from 'services/creditNote';
import { useGetEmploymentStatus } from 'contexts/employmentStatusContext';
import { isZetraProduct } from 'services/zetra';

interface WarrantyFormContainerProps {
    selectedWarranty: number | undefined;
    onCloseDrawer: () => void;
}

const WarrantyFormContainer: React.FC<WarrantyFormContainerProps> = ({
    selectedWarranty,
    onCloseDrawer,
}) => {
    const [warrantyType, setWarrantyType] = useState<WarrantyType | undefined>();
    const { setValue, watch, validationErrors } = useFormContext();
    const { product } = useCreditNoteFormContext();

    const instrumentType = product?.instrumentType?.toLowerCase();
    const isPublicPayroll =
        warrantyType === 'PublicPayroll' || instrumentType === 'publicpayrollloan';
    const { employmentSttsAutoCompleteProps } = useGetEmploymentStatus(
        { page: 0, size: 10 },
        isPublicPayroll
    );

    const warrantyRegistrationOffice = product?.warrantyRegistrationOffice?.toLowerCase();

    const warrantyData = (watch('warranty') ?? [])?.map((item: IWarrantyReadModel) => {
        if (item?.warrantyType?.toLowerCase() === 'invoicefactoring') {
            return {
                ...item,
                invoiceValue: Number(item?.invoiceValue) ?? null,
                discountValue: Number(item?.discountValue) ?? null,
                presentValue: Number(item?.presentValue) ?? null,
                paymentAmountInCents: Number(item?.paymentAmountInCents) ?? null,
            };
        }
        return item;
    });

    const willUpdateRequestedAmount =
        instrumentType === 'publicpayrollloan' && warrantyRegistrationOffice === 'siape';
    const isZetra = isZetraProduct(product);

    const onSubmit = (values: IWarrantyReadModel) => {
        let currentWarranty = [...warrantyData];
        const warrantyType = values?.warrantyType;
        const paymentAmountInCents = Number(values?.paymentAmountInCents) ?? 0;
        const isConsortium = warrantyType === 'Consortium';

        if (isConsortium) {
            const consortium = values as ConsortiumCreateModel;
            const administratorRegistrationNumber =
                onlyNumbers(consortium?.administratorRegistrationNumber!) ?? null;
            const administratorName = consortium?.administratorName ?? null;
            currentWarranty[selectedWarranty!] = {
                ...values,
                administratorRegistrationNumber,
                administratorName,
            };
        } else {
            currentWarranty[selectedWarranty!] = values;
        }

        if (willUpdateRequestedAmount && isPublicPayroll) {
            setValue('amortization.requestedAmount', paymentAmountInCents);
        }

        if (!isZetra) {
            delete currentWarranty[selectedWarranty!]?.warrantyOption;
            delete currentWarranty[selectedWarranty!]?.warrantyDeadline;
            delete currentWarranty[selectedWarranty!]?.previousAdeNumber;

            setValue('warranty', currentWarranty);
        } else {
            setValue('warranty', currentWarranty);
        }
        onCloseDrawer();
    };

    const defaultValue =
        selectedWarranty !== undefined &&
        warrantyData?.length !== selectedWarranty &&
        warrantyData[selectedWarranty!]
            ? warrantyData[selectedWarranty!]
            : defaultValuesWarranty(warrantyType);

    let errorMsg = '';
    if (validationErrors !== undefined) {
        Object.keys(validationErrors).forEach((key) => {
            if (key === 'collateral') {
                errorMsg = validationErrors[key]!.message as string;
            }
        });
    }

    return (
        <>
            <Drawer
                anchor="right"
                title="Adicionar/Alterar Garantia"
                open={selectedWarranty !== undefined}
                onClose={onCloseDrawer}
            >
                <FormProvider
                    validationSchema={validationSchemaWarranty(!isZetra)}
                    defaultValues={defaultValue}
                    onSubmit={onSubmit}
                    onChangeField={[
                        {
                            fieldName: 'warrantyType',
                            delegate: (value: WarrantyType) => setWarrantyType(value),
                        },
                    ]}
                >
                    <WarrantyForm
                        onClose={onCloseDrawer}
                        ufList={UF}
                        employmentStatus={employmentSttsAutoCompleteProps}
                    />
                </FormProvider>
            </Drawer>
            <Grid mt={2} mr={3}>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={watch('collateral')}
                    onChange={({ target: { value } }) => setValue('collateral', value)}
                    label="Observações *"
                    margin="dense"
                    multiline={true}
                    error={errorMsg.length > 0}
                    helperText={errorMsg}
                    rows={4}
                />
            </Grid>
        </>
    );
};

export default WarrantyFormContainer;
