import { Stack } from '@mui/material';
import { Button, CloseIcon, Drawer, SaveIcon } from '@uy3/web-components';
import React from 'react';
import { activeTheme } from 'services/theme';
import ChangeCalendarForm from './ChangeCalendarForm';
import { FormProvider } from 'contexts/formContext';
import {
    defaultvaluesCalculateDueForm,
    TCalculateDueProps,
    validationSchemaCalculateDueForm,
} from './CalculateDuePaymentSchema';
import { useGetPublicPayrollLoan } from 'contexts/PayrollAgreementContext';
import { useCalculateDuePaymentMutation } from 'contexts/creditNote/creditContext';
import {
    ApiResponseError,
    handleOnError,
    showSuccessToast,
    ToastType,
} from 'contexts/apiRequestContext';
import ChangeCalendarList from './ChangeCalendarList';
import { CalculateDuePaymentResponse } from 'services/creditNote';

type ConsignmentContainerProps = {
    openDrawer: boolean;
    onClose: () => void;
    id: string;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
};

const palette = activeTheme().palette;
const ChangeCalendarContainer: React.FC<ConsignmentContainerProps> = ({
    openDrawer,
    onClose,
    id,
    setToast,
}) => {
    const { payrollLoanAutoCompleteProps } = useGetPublicPayrollLoan(id);
    const [calculateDueData, setCalculateDueData] = React.useState<
        CalculateDuePaymentResponse[] | undefined
    >();

    const onSucess = (data: CalculateDuePaymentResponse[]) => {
        setCalculateDueData(data);
        showSuccessToast(
            'Calendário de quitação calculado com sucesso!',
            'Ótimo! Agora você pode realizar a quitação.',
            setToast
        );
    };

    const { mutateAsync } = useCalculateDuePaymentMutation(id, onSucess, (err: ApiResponseError) =>
        handleOnError(err, setToast)
    );

    const handleOnSubmit = (value: TCalculateDueProps) => mutateAsync(value);
    const handleOnClose = () => {
        onClose();
        setCalculateDueData(undefined);
    };

    return (
        <Drawer
            anchor="right"
            open={openDrawer}
            onClose={handleOnClose}
            title="Calcular calendário de quitação"
            description="Para calcular o calendário de quitação, informe a data de referência e o número de dias."
        >
            <FormProvider
                validationSchema={validationSchemaCalculateDueForm()}
                defaultValues={defaultvaluesCalculateDueForm}
                onSubmit={handleOnSubmit}
            >
                <Stack spacing={2}>
                    <ChangeCalendarForm payrollLoanList={payrollLoanAutoCompleteProps} />
                    {calculateDueData && <ChangeCalendarList queryData={calculateDueData} />}

                    <Stack direction="row" spacing={2} justifyContent="right" alignItems="center">
                        <Button
                            variant="outlined"
                            startIcon={<CloseIcon htmlColor={palette.primary.main} />}
                            onClick={handleOnClose}
                        >
                            Fechar
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<SaveIcon htmlColor={palette.common.white} />}
                            type="submit"
                        >
                            Continuar
                        </Button>
                    </Stack>
                </Stack>
            </FormProvider>
        </Drawer>
    );
};

export default ChangeCalendarContainer;
