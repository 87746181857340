import { GetViewApiResponse } from 'contexts/apiRequestContext';
import { createAxiosConfig, creditAxiosApi } from 'services/axiosApi';
import { FieldValues } from 'react-hook-form';
import { toDataModel, onlyNumbers } from 'helpers';
import { AmortizationTypes, toAmortizationDataModel } from 'services/Amortization';
import { CheckAverbationResponseType, CosifReadModel } from './types/genericTypes';
import {
    LiquidationScheduleRequestModel,
    mapLiquidationScheduleData,
} from 'services/LiquidationSchedule';
import {
    ConsortiumCreateModel,
    FactoringCreateModel,
    HomeEquityCreateModel,
    InvoiceFactoringCreateModel,
    JudiciaryProcessCreateModel,
    mapUploadModelData,
    PublicPayrollCreateModel,
    UploadUpdateModel,
    VehicleCreateModel,
    warrantyToDataModel,
    CreditNoteOpCreateModel,
    CreditNoteOpUpdateModel,
    CreditNoteReadModel,
    CreditNoteProps,
    DonePaymentRevision,
    GeneratePaymentLinkProps,
    TransferReceiptPdfType,
    CalculateDuePaymentCreateModel,
    CalculateDuePaymentResponse,
    CreditNoteWorflowType,
} from './types';

var url = '/CreditNote';

export const getCreditNoteList = async (filters: CreditNoteProps, token?: string) => {
    const axiosConfig = createAxiosConfig(token!, filters);
    return await creditAxiosApi.get(url, axiosConfig);
};

export async function editOrCreateCreditNote(
    data: CreditNoteReadModel,
    id: string,
    token = '',
    tenant?: string
) {
    const isCreating = id === 'nova';
    const urlFull = isCreating ? url : `${url}/${id}`;

    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant },
    };

    const CreditNoteCreateOrUpdate = isCreating ? CreditNoteOpCreateModel : CreditNoteOpUpdateModel;
    const creditNoteCreateOrUpdate = toDataModel(
        data as Record<keyof CreditNoteReadModel, unknown>,
        CreditNoteCreateOrUpdate
    );

    if (creditNoteCreateOrUpdate?.amortization) {
        creditNoteCreateOrUpdate.amortization = toAmortizationDataModel<AmortizationTypes>(
            creditNoteCreateOrUpdate.amortization
        );
    }

    if (!!creditNoteCreateOrUpdate?.liquidationSchedule?.length) {
        creditNoteCreateOrUpdate.liquidationSchedule =
            creditNoteCreateOrUpdate.liquidationSchedule.map((i) =>
                mapLiquidationScheduleData<LiquidationScheduleRequestModel>(i)
            );
    }

    if (creditNoteCreateOrUpdate?.warranty && Array.isArray(creditNoteCreateOrUpdate?.warranty)) {
        creditNoteCreateOrUpdate.warranty = creditNoteCreateOrUpdate.warranty.map((item) => {
            const { warrantyType } = item ?? {};

            if (warrantyType === 'Vehicle') {
                const vehicleModel = warrantyToDataModel<VehicleCreateModel>(item);
                if (vehicleModel?.sellerRegistrationNumber) {
                    vehicleModel.sellerRegistrationNumber = onlyNumbers(
                        vehicleModel.sellerRegistrationNumber
                    );
                }
                if (vehicleModel?.taxNumberOfSupplier) {
                    vehicleModel.taxNumberOfSupplier = onlyNumbers(
                        vehicleModel.taxNumberOfSupplier
                    );
                }
                return vehicleModel;
            }

            if (warrantyType === 'InvoiceFactoring') {
                const invoiceFactoring = warrantyToDataModel(item as InvoiceFactoringCreateModel);
                if (invoiceFactoring?.payerRegistrationNumber) {
                    invoiceFactoring.payerRegistrationNumber = Number(
                        onlyNumbers(String(invoiceFactoring.payerRegistrationNumber))
                    );
                }
                return invoiceFactoring;
            }

            if (warrantyType === 'HomeEquity') {
                return warrantyToDataModel(item as HomeEquityCreateModel);
            }
            if (warrantyType === 'JudiciaryProcess') {
                return warrantyToDataModel<JudiciaryProcessCreateModel>(item);
            }
            if (warrantyType === 'Factoring') {
                return warrantyToDataModel<FactoringCreateModel>(item);
            }
            if (warrantyType === 'PublicPayroll') {
                return warrantyToDataModel(item as PublicPayrollCreateModel);
            }
            if (warrantyType === 'Consortium') {
                return warrantyToDataModel<ConsortiumCreateModel>(item);
            }

            return item;
        });
    }

    const payload = {
        ...creditNoteCreateOrUpdate,
        paymentScheduleItems:
            creditNoteCreateOrUpdate?.paymentScheduleItems?.length > 0
                ? creditNoteCreateOrUpdate.paymentScheduleItems
                : null,
    };

    const axiosFunction = isCreating ? creditAxiosApi.post : creditAxiosApi.put;

    return await axiosFunction<GetViewApiResponse<CreditNoteReadModel>>(
        urlFull,
        payload,
        axiosConfig
    );
}

export async function putCreateCreditNoteById(
    creditNoteId: string,
    paylod: CreditNoteReadModel,
    token: string,
    tenant?: string
) {
    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant },
    };
    return await creditAxiosApi.put(`/CreditNote/${creditNoteId}`, paylod, axiosConfig);
}

export async function getCreditNoteById(id: string, token = '') {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.get<CreditNoteReadModel>(url + `/${id}`, axiosConfig);
}

export async function deleteCreditNoteById(id: string, token?: string, tenant?: string) {
    const axiosConfig = createAxiosConfig(token!, tenant);
    return await creditAxiosApi.delete(url + `/${id}`, axiosConfig);
}

export async function submitApprovalCreditNoteById(
    id: string,
    token?: string,
    updateStartDate?: boolean
) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { updateStartDate },
    };

    return await creditAxiosApi.post(url + `/${id}/submitapproval`, {}, config);
}

export function duplicateCreditNote(data: any, token?: string, tenant?: string) {
    const filter = ['status', 'paymentScheduleItems', 'timeline', 'creditNoteNo', 'id', 'uploads'];
    filter.forEach((key) => delete data[key]);

    return editOrCreateCreditNote(data, 'nova', token, tenant);
}

export type DisapproveDataProps = {
    attributeTypes: string[];
    message: string;
    insertBlock: string;
    blockUntil: string;
    rejectionReasonCode: string[];
};

export async function disapproveCreditNoteById(
    data: DisapproveDataProps,
    id: string,
    token: string
) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(url + `/${id}/disapprove`, data, axiosConfig);
}

export type SignatureCreditNoteProps = {
    id: string;
    token: string;
    message?: string;
    reject?: boolean;
    type: 'signature' | 'approveSignature';
};

export async function signatureCreditNoteById(props: SignatureCreditNoteProps) {
    const { id, token, message, reject = false, type = 'signature' } = props;
    const endpoint = type !== 'signature' ? 'approveSignature' : 'signature';
    var urlParams = reject ? `/${id}/${endpoint}?reprove=true` : `/${id}/${endpoint}`;
    const axiosConfig = createAxiosConfig(token);

    return await creditAxiosApi.post(url + urlParams, { message }, axiosConfig);
}

export async function sendSignatoryCreditNoteById(id: string, token: string) {
    const axiosConfig = createAxiosConfig(token);

    return await creditAxiosApi.post(url + `/${id}/sendNotification`, {}, axiosConfig);
}

export async function sendAssignmenteEmailCreditNoteById(id: string, token: string, email: string) {
    const axiosConfig = createAxiosConfig(token);

    return await creditAxiosApi.post(
        url + `/${id}/sendassignmentemail?sendOnlyTo=${email}`,
        {},
        axiosConfig
    );
}

export async function updateFiles(files: UploadUpdateModel[], id: string, token: string) {
    const axiosConfig = createAxiosConfig(token);
    const uploads = mapUploadModelData(files);
    return await creditAxiosApi.put(url + `/${id}/upload`, { uploads }, axiosConfig);
}

export async function paymentRevisionCreditNoteById(
    id: string,
    payload: DonePaymentRevision,
    token: string
) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(url + `/${id}/donePaymentRevision`, payload, axiosConfig);
}

export async function installmentCreditNoteById(id: string, payload: object, token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(url + `/${id}/installment`, payload, axiosConfig);
}

export async function checkCreditCardOperationsById(id: string, token: string) {
    var url = `/CreditCardOperations/${id}`;
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(url, {}, axiosConfig);
}

export async function cancelCreditNoteById(data: any, id: string, token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(url + `/${id}/cancel`, data, axiosConfig);
}

export async function manualUpdateCreditNote(
    data: FieldValues,
    id: string,
    token: string,
    tenant: string | undefined
) {
    const axiosConfig = createAxiosConfig(token, tenant);
    return await creditAxiosApi.post(url + `/${id}/manualUpdate`, data, axiosConfig);
}

export async function approveCreditNoteById(
    id: string,
    token: string,
    status: string,
    payload?: FieldValues
) {
    if (status === 'ManualLiquidation') {
        status = 'Liquidation';
    }

    var urlFull = url + `/${id}/approve${status.replace('Approval', '')}`;
    const axiosConfig = createAxiosConfig(token);

    return await creditAxiosApi.post(urlFull, payload, axiosConfig);
}

export async function assignmentPreviewCreditNoteById(
    id: string,
    token: string,
    useWatermark: boolean,
    createPdf: boolean
) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { useWatermark, createPdf },
    };

    return await creditAxiosApi.post(url + `/${id}/assignmentpreview`, {}, config);
}

export async function draftPreviewCreditNoteById(
    id: string,
    token: string,
    useWatermark: boolean,
    createPdf: boolean
) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { useWatermark, createPdf },
    };

    return await creditAxiosApi.post(url + `/${id}/draftpreview`, {}, config);
}

export async function getRecoverSimulationCreditNote(simulationId: string, token: string) {
    var url = `/Amortization/${simulationId}`;
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.get(url, axiosConfig);
}

export async function sendWebCredCreditNote(id: string, token: string, tenant: string | undefined) {
    const url = `/WebCred/${id}`;
    const axiosConfig = createAxiosConfig(token, tenant);

    return await creditAxiosApi.post(url, {}, axiosConfig);
}

export async function restoreCreditFromDeleted(id: string, token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(`${url}/${id}/restore`, {}, axiosConfig);
}

export async function approveliquidCreditNoteById(id: string, token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(`${url}/${id}/approveLiquidation`, {}, axiosConfig);
}

export async function generateBatchAssignment(data: any, token: string) {
    const axiosConfig = createAxiosConfig(token);
    return creditAxiosApi.post(`/BatchAssignment`, data, axiosConfig);
}

export async function generatePaymentLink(data: GeneratePaymentLinkProps, token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(
        `/CreditCardOperations/generatePaymentLink`,
        data,
        axiosConfig
    );
}

export async function getCosifAccountTransaction(token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.get<Array<CosifReadModel>>(
        `/CosifAccount?transactionType=Transfer`,
        axiosConfig
    );
}

export async function postRemoveBlock(creditNoteId: string, token = '') {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(url + `/${creditNoteId}/RemoveBlock`, {}, axiosConfig);
}

export async function postTransferReceiptPdf(
    creditNoteId: string,
    params: TransferReceiptPdfType,
    token: string
) {
    const axiosConfig = createAxiosConfig(token, params);
    return await creditAxiosApi.post(url + `/${creditNoteId}/TransferReceiptPdf`, {}, axiosConfig);
}

export const getSignaturesCreditNote = async (creditNoteId: string, token: string) => {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.get(`${url}/${creditNoteId}/SignUrl`, axiosConfig);
};

export const putFinishedManualWarranty = async (
    creditNoteId: string,
    payload: FieldValues | null,
    token: string
) => {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(
        `${url}/${creditNoteId}/approveWarranty`,
        payload,
        axiosConfig
    );
};

export const postApproveMarginReserve = async (
    creditNoteId: string,
    token: string,
    payload?: FieldValues
) => {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(
        `${url}/${creditNoteId}/approveMarginReserve`,
        payload,
        axiosConfig
    );
};

export const postDisapproveMarginReserve = async (
    creditNoteId: string,
    payload: FieldValues,
    token: string
) => {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(
        `${url}/${creditNoteId}/disapproveMarginReserve`,
        payload,
        axiosConfig
    );
};

export const postReprocessByStatus = async (
    creditNoteSequence: string,
    payload: string[],
    token: string
) => {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(
        `${url}/ReprocessByStatus/${creditNoteSequence}`,
        payload,
        axiosConfig
    );
};

export async function checkAverbationCreditNoteFGTS(creditNoteId: string, token: string) {
    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            restartWf: false,
            ignoreRuleDays: false,
        },
    };
    return await creditAxiosApi.post<CheckAverbationResponseType>(
        `${url}/${creditNoteId}/CheckAverbacao`,
        {},
        axiosConfig
    );
}

export async function releaseToPaymentAsync(creditNoteId: string, body: boolean, token: string) {
    const urlFull = `${url}/${creditNoteId}/releaseToPayment`;
    const axiosConfig = createAxiosConfig(token!);

    return await creditAxiosApi.post(urlFull, { Canceled: body }, axiosConfig);
}

export async function getCreditNoteWorkflows(creditNoteId: string, token: string) {
    const urlFull = `${url}/${creditNoteId}/workflows`;
    const axiosConfig = createAxiosConfig(token!);
    return await creditAxiosApi.get<CreditNoteWorflowType[]>(urlFull, axiosConfig);
}

export async function postCalculateDuePaymentAsync(
    creditNoteId: string,
    body: CalculateDuePaymentCreateModel,
    token: string
) {
    const urlFull = `${url}/${creditNoteId}/DuePaymentSchedule`;
    const axiosConfig = createAxiosConfig(token!);
    const payload = toDataModel(
        body as Record<keyof CalculateDuePaymentCreateModel, unknown>,
        CalculateDuePaymentCreateModel
    );

    return await creditAxiosApi.post<CalculateDuePaymentResponse[]>(urlFull, payload, axiosConfig);
}
