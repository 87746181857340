import { date, string } from 'yup';
import { array, object } from 'yup';

const TokensObjSchema = object().shape({
    warrantyCode: string().typeError('Código ADE: Precisa ser preenchido').nullable(),
    warrantyRegistrationOffice: string()
        .typeError('Sistema de averbação: Precisa ser preenchido')
        .nullable(),
    expirationDate: date().typeError('Data inválida').nullable(),
});

export const validationSchemaCompleteManualWarrantySchema = () => {
    return object().shape({
        tokens: array().of(TokensObjSchema).notRequired(),
        selectedTokens: array()
            .of(string())
            .typeError('Selecione ao menos 1 token')
            .required('Selecione ao menos 1 token')
            .min(1, 'Selecione ao menos um token'),
    });
};

export const defaultvaluesCompleteManualWarrantySchema = {
    selectedTokens: [],
};
