import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { ReactNode } from 'react';

interface CardContentProps {
    title: string;
    value: string | ReactNode;
    isHorizontal?: boolean
}

export const CardContent: React.FC<CardContentProps> = ({ title, value, isHorizontal }) => {
    return (
        <Stack direction={isHorizontal? 'row' : 'column'} alignItems={isHorizontal? 'center' : 'start'} justifyContent={isHorizontal ? 'space-between' : 'start'} spacing={1} padding='3px 0'>
            <Typography fontSize='16px' color="neutral.dark" fontWeight={600}>
                {title}
            </Typography>
            <Typography variant="md" color="neutral.medium">{value}</Typography>
        </Stack>
    );
};