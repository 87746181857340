import { Stack } from '@mui/material';
import { Button, CloseIcon, Typography } from '@uy3/web-components';
import { CardContent } from 'components/Card/CardContent';
import { iconSx } from 'contexts/apiRequestContext';
import { CreditNoteWorflowType } from 'services/creditNote';
import { activeTheme } from 'services/theme';

type CreditNoteWorkflowsProps = {
    onClose: () => void;
    creditNoteWorkflows: CreditNoteWorflowType[];
};

type ShowStatusProps = {
    status: string;
};

const theme = activeTheme();

export const CreditNoteWorkflows = ({ onClose, creditNoteWorkflows }: CreditNoteWorkflowsProps) => {
    return (
        <Stack spacing={2}>
            {creditNoteWorkflows?.map((workflow) => {
                return (
                    <Stack borderRadius="8px" border="1px solid grey" padding="10px">
                        <CardContent title={'Id'} value={workflow.id} isHorizontal />
                        <CardContent title={'Workflow'} value={workflow.workflow} isHorizontal />
                        <CardContent
                            title={'Status'}
                            value={<ShowStatus status={workflow.status} />}
                            isHorizontal
                        />
                    </Stack>
                );
            })}

            {(creditNoteWorkflows ?? [])?.length === 0 && 
                <Typography fontSize='16px' fontWeight='bold'>Lista de workflow não contém informação.</Typography> }

            <Stack direction="row" justifyContent="end" alignItems="center">
                <Button
                    variant="outlined"
                    onClick={onClose}
                    startIcon={<CloseIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
                >
                    Fechar
                </Button>
            </Stack>
        </Stack>
    );
};

const ShowStatus = ({ status }: ShowStatusProps) => {
    const bgColor: { [type: string]: string } = {
        pending: theme.palette.warning.dark,
        ok: theme.palette.success.dark,
        nok: theme.palette.error.light,
        timeout: theme.palette.info.dark,
        error: theme.palette.error.dark,
    };

    return (
        <Typography
            sx={{ 
                background: bgColor[status.toLocaleLowerCase()], 
                color: theme.palette.common.white, 
                fontWeight: 800, 
                padding: '5px',
                borderRadius: '5px', 
                fontSize: '15px'
            }}
        >
            {status}
        </Typography>
    );
};
