import { genericMessageRequired } from 'helpers';
import { InferType, object, string } from 'yup';

export const validationSchemaMarginQueryForm = () => {
    return object().shape({
        registrationNumber: string()
            .typeError('Informe um CPF válido.')
            .required(`CPF: ${genericMessageRequired}`)
            .cpfCnpjValidation('CPF é inválido.'),
    });
};

const inferedValidation = validationSchemaMarginQueryForm();
export type TMarginQueryProps = InferType<typeof inferedValidation>;

export const defaultvaluesMarginQueryForm = {
    registrationNumber: null,
};
