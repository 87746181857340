import { billingAxiosApi } from "services/axiosApi/axiosApi";
import { ICreateInstallment, IParamsInstallment, IRegisterPayload, ITransferInstallment, IUpdateCommunicationByInstallmentSetting, IUpdateInstallment } from "./installment.types";

const url = "/Installment"


export async function getInstallmentsListAsync(params: IParamsInstallment, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: params
    };
    return await billingAxiosApi.get(`${url}`, config);
}

export async function getInstallmentsByIdAsync(installmentId: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    
    return await billingAxiosApi.get(`${url}/${installmentId}`, config);
}

export async function transferInstallmentsAsync(installmentId: string, payload: ITransferInstallment, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.post(`${url}/${installmentId}/transfer`, payload, config);
}

export async function cancelInstallmentsAsync(installmentId: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.post(`${url}/${installmentId}/Cancel`, {}, config);
}

export async function createInstallmentAsync(payload: ICreateInstallment, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await billingAxiosApi.post(`${url}`, payload, config);
}

export async function updateInstallmentAsync(installmentId: string, payload: IUpdateInstallment, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await billingAxiosApi.patch(`${url}/${installmentId}`, payload, config);
}

export async function registerInstallmentAsync(installmentId: string, payload: IRegisterPayload, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await billingAxiosApi.post(`${url}/${installmentId}/register`, payload, config);
}

export async function updateCommunicationSettingByInstallmentAsync(installmentId: string, params: IUpdateCommunicationByInstallmentSetting, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }, 
        params
    };
    return await billingAxiosApi.put(`${url}/${installmentId}/UpdateCommunicationSetting`, {}, config);
}

export async function downloadPdfAsync(installmentId: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob' as 'blob',
    };
    return await billingAxiosApi.get(`${url}/${installmentId}/DownloadPdf`, config);
}

