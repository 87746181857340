import { useAppConfig } from 'contexts/appConfig';
import { isFieldHidden } from 'helpers';
import React, { ReactNode } from 'react';

interface ConfigurableHiddenFieldProps {
    fieldName?: string;
    children: ReactNode;
    hidden?: boolean;
}

const ConfigurableHiddenField: React.FC<ConfigurableHiddenFieldProps> = ({
    fieldName,
    children,
    hidden
}) => {
    const { appConfig } = useAppConfig();

    const formFieldsConfig = appConfig?.FORM_FIELDS;
    const isHidden = isFieldHidden(fieldName, formFieldsConfig);

    if (isHidden || hidden) {
        return null;
    }

    return <>{children}</>;
};

export default ConfigurableHiddenField;