import { Stack } from '@mui/material'
import { Button, CloseIcon, RightIcon } from '@uy3/web-components'
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { useCommunicationSettingList } from 'contexts/wallet/communicationSetting/communicationSettingContext';
import { activeTheme } from 'services/theme'

const theme = activeTheme();
type UpdateCommunicationSettingFormProps = {
    onClose: () => void;
    isLoading: boolean
    walletsCode: number[]
}
export const UpdateCommunicationSettingForm = ({ onClose, isLoading, walletsCode }: UpdateCommunicationSettingFormProps) => {
    const { communicationSettingAutocomplete } = useCommunicationSettingList({ walletsCode }, 'always');

    return (
        <Stack spacing={2}>
            <AutocompleteField
                label="Régua de comunicação"
                name="communicationSettingId"
                displayName="communicationSettingIdDisplay"
                required
                {...communicationSettingAutocomplete!}
            />

            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    type='submit'
                    disabled={isLoading}
                    startIcon={
                        <RightIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 23, width: 23 }}
                        />
                    }
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    )
}
