import { currentDateWithSetHours, genericMessageRequired, toIsoStringWithTimezone } from 'helpers';
import { InferType, number, object, string } from 'yup';
import { date } from 'yup';

export const validationSchemaCalculateDueForm = () => {
    return object().shape({
        creditNoteId: string()
            .required(`Selecione uma nota de crédito`)
            .typeError('Selecione uma nota de crédito.'),
        referenceDate: date()
            .typeError('Data de referência inválida.')
            .required(`Data de referência: ${genericMessageRequired}`),
        numberOfDays: number()
            .required(`Número de dias: ${genericMessageRequired}`)
            .typeError('Número de dias inválido.')
            .min(1, 'Informe ao menos 1 dia.'),
    });
};

const inferedValidation = validationSchemaCalculateDueForm();
export type TCalculateDueProps = InferType<typeof inferedValidation>;

export const defaultvaluesCalculateDueForm = {
    creditNoteId: null,
    referenceDate: toIsoStringWithTimezone(new Date(currentDateWithSetHours)),
    numberOfDays: 0,
};
