enum EWarrantyType {
    Warranty = 0,
    Vehicle = 1,
    HomeEquity = 2,
    JudiciaryProcess = 3,
    Factoring = 4,
    PublicPayroll = 5,
    InvoiceFactoring = 6,
}

enum EInstrumentType {
    NotDefined = 0,
    CCB = 1,
    CommercialPapper = 2,
    PublicPayrollLoan = 3,
    CreditAssignment = 4,
}

enum EWarrantyRegistrationOffice {
    None,
    Fgts,
    Zetra,
    Siape,
    Others,
}

enum ESignaturePortalProvider {
    RBM = 0,
    ClickSign = 1,
    QuickSoft = 2,
    UnicoCheck = 3,
    UnicoCloud = 4,
}

export {
    EInstrumentType,
    EWarrantyType,
    EWarrantyRegistrationOffice,
    ESignaturePortalProvider,
};

export enum ComplianceRulesTypeEnum {
    AutomaticApproval = 0,
    RegistrationNumberBlock = 1,
    Uy3Blacklist = 2,
    RegistrationNumberRegularity = 3,
    NameValidation = 4,
    RiskScore = 5,
    ComplianceEngine = 6,
    Kyc = 7,
    R6 = 8,
    RecentApprovals = 9,
}

export enum ComplianceRulesActionType {
    Reject = 0,
    ManualApproval = 1,
}