import { Typography } from '@uy3/web-components';
import { TimelineType, useApiRequest } from 'contexts/apiRequestContext';
import moment from 'moment';
import { Grid, Stack } from '@mui/material';
import {
    CustomTimeline,
    CustomTimelineConnector,
    CustomTimelineContent,
    CustomTimelineDot,
    CustomTimelineItem,
    CustomTimelineSeparator,
    TimelineDescription,
} from './Timeline.styled';
import { getBackgroundColor, getColor } from './colors';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import OperatorViewInfo from 'components/OperatorViewInfo/OperatorViewInfo';

interface ITimelineProps {
    title: string;
    timeline: TimelineType[];
    status: string;
}

const Timeline: React.FC<ITimelineProps> = ({ title, timeline, status }) => {
    const { submitError } = useApiRequest();

    const getFormattedDate = (date?: string): string | undefined => {
        return date ? moment(date).format('DD/MM/YYYY - HH:mm:ss') : undefined;
    };

    const handleDescription = (description: string) => {
        if (description?.includes('concluída com sucesso e com Score')) {
            const indexOf = description.indexOf("Score:");
            const toBold = description.slice(indexOf);
            return <>{description.slice(0, indexOf)}<span style={{ fontWeight: 900, color: 'black' }}>{toBold}</span></>
        }
        return (
            <>{description} </>
        )
    }

    const onlySearchOfList = timeline as any;
    const searchByLastElementExistStartedAt = onlySearchOfList?.findLastIndex((item: any) => {
        return getFormattedDate(item.startedAt) !== undefined
    })

    const targetIndex = searchByLastElementExistStartedAt; 

    const isNotCanceledOrFinished = !["cancelada", "encerrada", "concluída", "ativa", "encerrado"].includes(status?.toLowerCase()!);

    const renderTimelineItem = (item: TimelineType, index: number, activeStep: number) => {
        const isActiveStep = targetIndex === item.index;

        const activeStepColor = isActiveStep && isNotCanceledOrFinished ? getColor(status) : '#212b36';

        const startDateFormatted = getFormattedDate(item.startedAt);
        const endDateFormatted = getFormattedDate(item.finishedAt);
        const createdByUser = item?.createdBy?.userIdDisplay ?? 'Sistema';
        const updatedBy = item?.updatedBy?.userIdDisplay ?? 'Sistema';

        const notHasStartAndEndDate = !startDateFormatted && !endDateFormatted;

        const dotBackgroundColor =

            notHasStartAndEndDate
                ? !isNotCanceledOrFinished ? getColor(status) : '#919EAB'
                : !isActiveStep || index === timeline.length - 1
                    ? getColor(status)
                    : !isNotCanceledOrFinished ? getColor(status) : '#fff';

        const dotBorderColor =
            notHasStartAndEndDate
                ? !isNotCanceledOrFinished ? getColor(status) : '#919EAB'
                : isActiveStep
                    ? getColor(status)
                    : '#919EAB';

        const contentBackgroundColor = isActiveStep && isNotCanceledOrFinished ? getBackgroundColor(status) : '#fff';

        return (
            <CustomTimelineItem key={index}>
                <CustomTimelineSeparator>
                    <CustomTimelineDot
                        backgroundColor={dotBackgroundColor}
                        borderColor={dotBorderColor}
                    />
                    {timeline.length !== index + 1 && (
                        <CustomTimelineConnector
                            color={notHasStartAndEndDate ?
                                !isNotCanceledOrFinished ? getColor(status) : '#919EAB' :
                                (isActiveStep && isNotCanceledOrFinished) ? '#919EAB' : getColor(status)}
                            display={index === timeline.length - 1 ? 'none' : 'block'}
                        />
                    )}
                </CustomTimelineSeparator>
                <CustomTimelineContent backgroundColor={contentBackgroundColor}>
                    <Typography variant="sm" fontWeight={600} color={activeStepColor}>
                        {item.name}
                    </Typography>
                    {notHasStartAndEndDate ? (
                        <Typography variant="xs" color="#919EAB">
                            {isNotCanceledOrFinished && "Etapa não iniciada"}
                        </Typography>
                    ) : (
                        <>
                            <Typography variant="xs" color="neutral.medium">
                                {handleDescription(item.description)}
                            </Typography>
                            <TimelineDescription color={activeStepColor}>
                                <Typography variant="sm" fontWeight={600}>
                                    {`Iniciado em ${startDateFormatted || 'N/D'} ${createdByUser ? `por ${createdByUser}` : ''
                                        } `}
                                </Typography>
                                <Typography variant="sm" fontWeight={700}>
                                    {endDateFormatted &&
                                        `Finalizado em ${endDateFormatted} ${updatedBy ? `por ${updatedBy}` : ''
                                        }`}
                                </Typography>
                            </TimelineDescription>
                        </>
                    )}
                </CustomTimelineContent>
            </CustomTimelineItem>
        );
    };

    let activeStepIndex = 0;

    if (timeline) {
        timeline.forEach((item, index) => {
            const isOngoingStep = item.startedAt && !item.finishedAt;
            const isCompletedStep = item.startedAt && item.finishedAt;
            const isNoStepInfo = !item.startedAt && !item.finishedAt;
            const isMatchingStatus = status?.includes(item.name) || item.name === 'Cedido' || item.name === 'Reprovada';

            if (isOngoingStep || isCompletedStep || isNoStepInfo) {
                if (isMatchingStatus) {
                    activeStepIndex = index;
                }
            }
        });
    };
    
    const isCanceled = status?.toLowerCase() === 'cancelada'

    return (
        <Stack>
            <Stack spacing={1.5} alignItems="start" direction="column">
                <Typography variant="xxl" color="common.back" lineHeight="28.8px" fontWeight={600}>
                    {`Andamento da ${title}`}
                </Typography>
                <Typography variant="xs" color="neutral.medium" fontWeight={600}>
                    {`Monitore o progresso e o estado atual da ${title}`}
                </Typography>
            </Stack>
            <Grid m='10px 0'>
                <OperatorViewInfo />
            </Grid>
            <Stack alignItems="start" direction="column" ml={-3}>
                <CustomTimeline>
                    <ErrorBoundary fallback={title} error={submitError?.message} typeMessage='textMessage'>
                        {timeline
                        .map(item => {return {...item, name: isCanceled && item.name === 'Encerrado' ? "Cancelada" : item.name }})
                        .map((item, index) => renderTimelineItem(item, index, activeStepIndex))}
                    </ErrorBoundary>
                </CustomTimeline>
            </Stack>
        </Stack>
    );
};
export default Timeline;