/* eslint-disable react-hooks/exhaustive-deps */
import { RefreshProgress } from 'components/RefreshProgress';
import { useState } from 'react';
import { useCreditList } from 'contexts/creditNote/creditContext';
import { useParams } from 'react-router-dom';
import { GridRowId, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';
import { ToastType, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { useTenant } from 'contexts/tenantContext';
import Group, { useGroupListData } from 'contexts/groupContext';
import { PersonFull, usePersonsList } from 'contexts/personContext';
import Toast from 'components/Toast/Toast';
import CreditNoteHeader from './CreditNoteHeader';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import PopupDelete from './PopupDelete/PopupDelete';
import CreditNoteListWithFilters from './CreditNoteListWithFilters/CreditNoteListWithFilters';
import { useEnumContext } from 'contexts/enumContext';
import { Stack } from '@mui/material';
import { useCreditProdutList } from 'contexts/creditProductContext';
import { CreditProductReadModel } from 'services/creditProduct';

export function CreditNoteListContainer() {
    const { userId } = useParams();
    const { currentTenantId } = useTenant();
    const { tenantAutoCompleteProps } = useTenant();
    const { submitting, submitError } = useApiRequest();
    const [rowsPerPage, setRowsPerPage] = useState(
        parseInt(localStorage.getItem('rowsPerPage')!) || 5
    );
    const [page, setPage] = useState(0);
    const [rowData, setRowData] = useState<any | undefined>(undefined);
    const { data: typeOfRelationship } = useEnumContext({ enumName: 'TypeOfRelationship', size: 50 });
    const { personAutoCompleteProps, data: personsData } = usePersonsList({ page: 0, size: 10 }, 'always');
    const { data: productData, creditProductAutoCompleteProps } = useCreditProdutList({ page: 0, size: 10 }, 'always');
    const { isRootTenancy } = useTenant();
    const { groupAutoCompleteProps, data } = useGroupListData({ page, size: 10, tenant: currentTenantId }, 'always');
    const [selectedStatusOfTable, setSelectedStatusOfTable] = useState<string[] | GridRowId[]>();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [orderBy, setOrderBy] = useState<GridSortModel>([]);
    const order = orderBy?.map((item) => `${item?.field}_${item?.sort?.toUpperCase()}`) || [];
    const { creditStatus, creditData, error, creditFetch, refetch, isLoading } = useCreditList({
        personId: userId,
        page,
        size: rowsPerPage,
        orderBy: order[0],
    });


    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
    };

    const onChangePage = (page: number) => setPage(page);

    const handleSelectionModelChange = (selectionModel: GridSelectionModel) =>
        setSelectedStatusOfTable(selectionModel);

    let groupAutocomplete: any = {
        ...groupAutoCompleteProps,
        listOptions:
            data?.data?.map((i: Group) => {
                return { label: i.groupName, value: i.groupName };
            }) || [],
    };

    let dataPerson = personsData as any;

    const personAutocompleteOptions: any = {
        ...personAutoCompleteProps,
        listOptions:
            dataPerson?.data?.map((person: PersonFull) => {
                return { label: person?.name, value: person?.id };
            }) || [],
    };

    let dataproduct = productData as any;
    const productAutocompleteList: any = {
        ...creditProductAutoCompleteProps,
        listOptions:
            dataproduct?.data?.map((item: CreditProductReadModel) => {
                return { label: isRootTenancy
                    ? `${item?.name || 'Não informado'} (${item?.tenantDisplay})`
                    : item?.name, value: item.id };
            }) || [],
    };

    let optionsTenantList =
        tenantAutoCompleteProps?.listOptions?.map((item: any) => {
            return { label: item?.label, value: item?.value };
        }) || [];


    const ErrorThrower = () => {
        if (creditStatus === 'error') {
            throw error;
        }
        return null;
    }

    return (
        <ErrorBoundary fallback="operações">
            <ErrorThrower />
            <Toast toast={toast} setToast={setToast} />
            <ApiErrorAlert error={submitError} />
            <Stack mb={3}>
                <CreditNoteHeader
                    {...{
                        creditData,
                        refetch,
                        setToast,
                        toast,
                        setSelectedStatusOfTable,
                        selectedStatusOfTable,
                    }}
                />
            </Stack>
            {!isLoading && <RefreshProgress refreshing={submitting || creditFetch} />}

            {optionsTenantList.length > 0 && (
                <CreditNoteListWithFilters
                    creditData={creditData}
                    isLoading={isLoading}
                    typeOfRelationship={typeOfRelationship ?? []}
                    groupAutocomplete={groupAutocomplete}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    optionsTenantList={optionsTenantList}
                    page={page}
                    personAutocompleteOptions={personAutocompleteOptions}
                    productAutocompleteList={productAutocompleteList}
                    rowsPerPage={rowsPerPage}
                    selectedStatusOfTable={selectedStatusOfTable}
                    setOrderBy={setOrderBy}
                    setRowData={setRowData}
                    setSelectedStatusOfTable={setSelectedStatusOfTable}
                    handleSelectionModelChange={handleSelectionModelChange}
                />
            )}

            <PopupDelete
                rowData={rowData}
                onClose={() => setRowData(undefined)}
                refetch={refetch}
                setToast={setToast}
            />
        </ErrorBoundary>
    );
}
