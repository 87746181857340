import React from 'react';
import { Stack, Grid } from "@mui/material"
import { Button, CloseIcon, ReportIcon, Typography } from '@uy3/web-components';
import { CheckboxFormField } from 'components/Forms/FormFields';
import { activeTheme } from 'services/theme';
import { iconSx } from 'contexts/apiRequestContext';
import GetLabelForField from 'components/GetLabelForField';
import { useAppConfig } from 'contexts/appConfig';
import _ from 'lodash';

type PrintConfigurationProps = {
    removedFromPrintList: string[] | undefined,
    onCloseModal: () => void,
    handleOnChange: (item: string) => void;
}

const theme = activeTheme();
const PrintConfiguration = ({
    handleOnChange,
    onCloseModal,
    removedFromPrintList
}: PrintConfigurationProps) => {
    const { appConfig } = useAppConfig();

    const formFieldsConfig = appConfig?.FORM_FIELDS;

    const isFieldsCommHidden = (fieldName: string): boolean | undefined => {
        const fieldValue = _.get(formFieldsConfig, fieldName);
        return fieldValue?.Hidden;
    }; 

    const isHiddenGrossValue = isFieldsCommHidden("amortization.grossValue");
    const isHiddenCommTc = isFieldsCommHidden("amortization.commTc") ?? true;
    const isHiddenCommNonTc = isFieldsCommHidden("amortization.commNonTc") ?? true;

    const handlePrint = () => {
        window.print();
    }

    return (
        <>
            <Typography variant="h5" color="neutral.dark"
                sx={{
                    fontSize: { xs: '18px', md: '18px' },
                    fontWeight: { xs: '700', md: '700' },
                    fontStyle: { xs: 'normal', md: 'normal' }
                }}
            >
                Informações da Operação
            </Typography>
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{
                    mt: 1,
                    mb: 5
                }}
            >
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='startDate'
                        checked={!removedFromPrintList?.includes("startDate")}
                        onChange={() => handleOnChange('startDate')}
                        label="Data de Emissão"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='termInMonths'
                        checked={!removedFromPrintList?.includes("termInMonths")}
                        onChange={() => handleOnChange('termInMonths')}
                        label="Prazo"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='monthlyInterest'
                        checked={!removedFromPrintList?.includes("monthlyInterest")}
                        onChange={() => handleOnChange('monthlyInterest')}
                        label="Taxa A.M"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='dueDate'
                        checked={!removedFromPrintList?.includes("dueDate")}
                        onChange={() => handleOnChange('dueDate')}
                        label="Data de vencimento"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='finTax'
                        checked={!removedFromPrintList?.includes("finTax")}
                        onChange={() => handleOnChange('finTax')}
                        label="IOF"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='yearlyInterest'
                        checked={!removedFromPrintList?.includes("yearlyInterest")}
                        onChange={() => handleOnChange('yearlyInterest')}
                        label="Taxa A.A"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='initialValue'
                        checked={!removedFromPrintList?.includes("initialValue")}
                        onChange={() => handleOnChange('initialValue')}
                        label="Valor do Contrato"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='comm'
                        checked={!removedFromPrintList?.includes("comm")}
                        onChange={() => handleOnChange('comm')}
                        label="Custo de emissão"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='effectiveMonthlyCost'
                        checked={!removedFromPrintList?.includes("effectiveMonthlyCost")}
                        onChange={() => handleOnChange('effectiveMonthlyCost')}
                        label="CET A.M"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='liquidValue'
                        checked={!removedFromPrintList?.includes("liquidValue")}
                        onChange={() => handleOnChange('liquidValue')}
                        label="Valor Líquido"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='indexer'
                        checked={!removedFromPrintList?.includes("indexer")}
                        onChange={() => handleOnChange('indexer')}
                        label="Indexador"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='effectiveYearlyCost'
                        checked={!removedFromPrintList?.includes("effectiveYearlyCost")}
                        onChange={() => handleOnChange('effectiveYearlyCost')}
                        label="CET A.A"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='paymentFixedCosts'
                        checked={!removedFromPrintList?.includes("paymentFixedCosts")}
                        onChange={() => handleOnChange('paymentFixedCosts')}
                        label={GetLabelForField("addedFixedCosts", "Tarifas")}
                    />
                </Grid>
                {!isHiddenGrossValue && <Grid item xs={3}>
                    <CheckboxFormField
                        name='grossValue'
                        checked={!removedFromPrintList?.includes("grossValue")}
                        onChange={() => handleOnChange('grossValue')}
                        label={GetLabelForField("amortization.grossValue", "Valor Futuro")}
                    />
                </Grid>}

                {!isHiddenCommTc &&<Grid item xs={3}>
                    <CheckboxFormField
                        name='commTc'
                        checked={!removedFromPrintList?.includes("commTc")}
                        onChange={() => handleOnChange('commTc')}
                        label={GetLabelForField("amortization.commTc", "Tarifa de Cadastro")}
                    />
                </Grid>}

                {!isHiddenCommNonTc && <Grid item xs={3}>
                    <CheckboxFormField
                        name='commNonTc'
                        checked={!removedFromPrintList?.includes("commNonTc")}
                        onChange={() => handleOnChange('commNonTc')}
                        label={GetLabelForField("amortization.commNonTc", "Tarifas/Seguro")}
                    />
                </Grid>}
            </Grid>

            <Typography variant="h5" color="neutral.dark"
                sx={{
                    fontSize: { xs: '18px', md: '18px' },
                    fontWeight: { xs: '700', md: '700' },
                    fontStyle: { xs: 'normal', md: 'normal' }
                }}
            >
                Informações da Tabela de Pagamento
            </Typography>

            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{
                    mt: 1,
                    mb: 3
                }}
            >
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='installment'
                        checked={!removedFromPrintList?.includes("installment")}
                        onChange={() => handleOnChange('installment')}
                        label="Parcela"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='dueDateTable'
                        checked={!removedFromPrintList?.includes("dueDateTable")}
                        onChange={() => handleOnChange('dueDateTable')}
                        label="Vencimento"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='principalAmountInCents'
                        checked={!removedFromPrintList?.includes("principalAmountInCents")}
                        onChange={() => handleOnChange('principalAmountInCents')}
                        label="Saldo Devedor"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='amortization'
                        checked={!removedFromPrintList?.includes("amortization")}
                        onChange={() => handleOnChange('amortization')}
                        label="Amortização"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='interest'
                        checked={!removedFromPrintList?.includes("interest")}
                        onChange={() => handleOnChange('interest')}
                        label="Juros"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='payment'
                        checked={!removedFromPrintList?.includes("payment")}
                        onChange={() => handleOnChange('payment')}
                        label="Pagamento"
                    />
                </Grid>
                <Grid item xs={3}>
                    <CheckboxFormField
                        name='valueTotal'
                        checked={!removedFromPrintList?.includes("valueTotal")}
                        onChange={() => handleOnChange('valueTotal')}
                        label="Total"
                    />
                </Grid>
            </Grid>

            <Stack
                direction={'row'}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
                mt={3}
            >
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    variant="outlined"
                    size="medium"
                    onClick={() => {
                        handleOnChange("restore");
                        onCloseModal();
                    }}
                >
                    Fechar
                </Button>
                <Button
                    type='submit'
                    variant='contained'
                    onClick={() => {
                        onCloseModal();
                        window.setTimeout(handlePrint, 40);
                    }}
                    startIcon={<ReportIcon htmlColor={theme.palette.primary.contrastText} sx={iconSx} />}
                    sx={{ justifyContent: 'center' }}
                >
                    Imprimir
                </Button>
            </Stack>
        </>
    )
}

export default PrintConfiguration