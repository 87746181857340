import { Box } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { TooltipComponent } from 'components/Tooltip/Tooltop';
import { attributesTypeFormat } from 'helpers/formats/StringFormats';
import { AttributeTypeChecklist } from 'services/creditNote/complianceChecklist';

type ComplianceChecklistMessageProps = {
  complianceChecklistData: AttributeTypeChecklist[];
  name: string;
}

export const ComplianceChecklistMessage = ({ complianceChecklistData, name }: ComplianceChecklistMessageProps) => {
  const attributes = complianceChecklistData.map(({ attributeType, ...props }) => {
    return {
      ...props,
      attributeType: attributesTypeFormat(attributeType)
    }
  });
  let color = 'rgba(208, 77, 39, 0.08)';
  return (
    <Box sx={{
      backgroundColor: color,
      border: `#D04D27 1px solid`,
      padding: '23px 20px',
      borderRadius: 2
    }}>
      <Typography variant='sm' color='#D34D27'>
        <span style={{ fontWeight: 'bold' }}>
          {name} &nbsp;
        </span>
        possui restrições de compliance nos seguintes tipos:
        {attributes.map(x => {
          return (
            <TooltipComponent key={x.id} title={x.message} placement="top" arrow>
              <span style={{ fontWeight: 'bold' }}> &nbsp; {x.attributeType}</span>
            </TooltipComponent>)
        })}
      </Typography>
    </Box>
  )
}