import { Stack } from '@mui/material';
import { FormProvider, useFormContext } from 'contexts/formContext';
import React, { useState } from 'react';
import {
    defaultvaluesMarginQueryForm,
    validationSchemaMarginQueryForm,
} from './MarginQueryFormSchema';
import { MarginQueryForm } from './MarginQueryForm';
import { FieldValues } from 'react-hook-form';
import { ISiapeMarginQueryReadModel, ResultMarginQuery } from 'services/datasets';
import {
    ApiResponseError,
    handleOnError,
    showInfoToast,
    showSuccessToast,
    ToastType,
} from 'contexts/apiRequestContext';
import { useSiapeMarginMutation, useSiapeMarginQuery } from 'contexts/datasetContext';
import { Alert, Drawer } from '@uy3/web-components';
import { SelectWarrantyContainer } from './SelectWarranty/SelectWarrantyContainer';
import { isValidCPF, isValidString, onlyNumbers } from 'helpers';
import { useCreditNoteFormContext } from 'pages/Product/CreditProduct';
import { useUpdateZetraMarginQuery, useZetraMarginQuery } from 'contexts/zetra';
import { isZetraProduct } from 'services/zetra';
import { UpdateZetra } from './Drawer';
import { CreditProductReadModel } from 'services/creditProduct/types/creditProductReadModel';

type MarginQueryProps = {
    onClose: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    productData?: CreditProductReadModel;
};

export const MarginQueryFormContainer: React.FC<MarginQueryProps> = ({
    onClose,
    setToast,
    productData,
}) => {
    const { product } = useCreditNoteFormContext();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isUpdateZetra, setIsUpdateZetra] = useState(false);
    const { getValues } = useFormContext();
    const [employeeCode, setEmployeeCode] = useState<string | undefined>(undefined);
    const [getRegistrationNumber, setGetRegistrationNumber] = useState<string | undefined>(
        getValues('registrationNumber') ?? ''
    );
    const registrationNumber = onlyNumbers(getRegistrationNumber!);
    const isZetra = isZetraProduct(product ?? productData);
    const personId = getValues('personId');
    const creditProductId = getValues('productId');

    const { data: siapeMarginQuery, refetch } = useSiapeMarginQuery({
        registrationNumber: isZetra ? undefined : registrationNumber,
        creditProductId,
    });

    const { zetraMarginQueryData, zetraMarginQueryRefecth } = useZetraMarginQuery(
        { registrationNumber, employeeCode },
        isZetra,
        setToast,
        openDrawer
    );

    const onError = (error: ApiResponseError) => handleOnError(error, setToast);
    const successMessage = 'Consulta de margem realizada com sucesso!';
    const onSuccessSiape = (props: ISiapeMarginQueryReadModel[]) => {
        refetch();
        const response = props[0] as ISiapeMarginQueryReadModel;
        if (resultIncludeWarnningMessage(response?.result!)) return;
        showSuccessToast(successMessage, undefined, setToast);
    };

    const resultIncludeWarnningMessage = (result: ResultMarginQuery): boolean => {
        if (result.Nome === null || !result.DsRetCode.includes('realizado com sucesso')) {
            showInfoToast(
                result.DsRetCode,
                `Código de retorno: ${result.CdRetCode} - data consulta: ${result.DtOperacao}`,
                setToast
            );
            return true;
        }
        return false;
    };

    const { mutateAsync: siapeMarginMutation, isLoading } = useSiapeMarginMutation(
        onSuccessSiape,
        onError
    );

    const handleSubmit = (values: FieldValues) => {
        const registrationNumber = values?.registrationNumber?.replace(/\D/g, '');
        setGetRegistrationNumber(registrationNumber);
        setOpenDrawer(true);
        isZetra ? zetraMarginQueryRefecth() : refetch();
    };

    const marginQueryData = isZetra ? zetraMarginQueryData?.[0] : siapeMarginQuery?.[0];

    const openDrawerZetra = () => setIsUpdateZetra((prev) => !prev);

    const onSuccessZetra = () => {
        if (!personId) openDrawerZetra();
        zetraMarginQueryRefecth();
        showSuccessToast(
            'Margem atualizada com sucesso!',
            'Ótimo! Agora você pode selecionar umas das opções de margem disponíveis.',
            setToast
        );
    };

    const { mutateUpdateZetra } = useUpdateZetraMarginQuery(onSuccessZetra, onError);

    return (
        <Stack spacing={2}>
            <FormProvider
                defaultValues={defaultvaluesMarginQueryForm}
                validationSchema={validationSchemaMarginQueryForm()}
                onSubmit={handleSubmit}
                onChangeField={[
                    {
                        fieldName: 'registrationNumber',
                        delegate: (value: string) => {
                            if (isValidCPF(value)) {
                                setGetRegistrationNumber(value);
                            }
                        },
                    },
                    {
                        fieldName: 'employeeCode',
                        delegate: (value: string) => {
                            if (isValidString(value)) {
                                setEmployeeCode(value);
                            }
                        },
                    },
                ]}
            >
                <MarginQueryForm
                    {...{
                        onClose,
                        isLoading,
                        registrationNumber,
                        setGetRegistrationNumber,
                        personId,
                        isZetra,
                    }}
                />
            </FormProvider>

            <UpdateZetra
                {...{
                    isUpdateZetra,
                    onCloseDrawer: openDrawerZetra,
                    mutateUpdateZetra,
                    registrationNumber,
                    setToast,
                    creditProductId,
                    employeeCode
                }}
            />

            <Drawer anchor="right" open={openDrawer} title="Selecione uma das margens disponíveis">
                <Stack spacing={2}>
                    {!marginQueryData && (
                        <Alert
                            text="Não há dados de margem disponíveis para esse CPF. Clique no botão 'Obter margem' para fazer a primeira consulta"
                            severity="info"
                        />
                    )}
                    <SelectWarrantyContainer
                        setToast={setToast}
                        marginQueryData={marginQueryData}
                        onBackStep={() => setOpenDrawer(false)}
                        onCloseAll={onClose}
                        handleMarginQuery={async () => {
                            if (isZetra && !personId) {
                                openDrawerZetra();
                            } else if (isZetra && personId) {
                                await mutateUpdateZetra({
                                    personId,
                                    creditProductId,
                                });
                            } else if (!isZetra) {
                                await siapeMarginMutation({ registrationNumber, creditProductId });
                            }
                        }}
                        isZetra={isZetra}
                        registrationNumber={registrationNumber}
                    />
                </Stack>
            </Drawer>
        </Stack>
    );
};
