import React from 'react';
import { CustomStepIconProps } from './StepIcon.interface';
import { CustomStepIcon } from './StepIcon.styled';

export const StepIcon: React.FC<CustomStepIconProps> = ({ active, completed, step, onClick }) => {
  return (
    <CustomStepIcon
      completed={completed}
      active={active}
      onClick={() => onClick?.(step)}
      hasOnClick={!!onClick}
    >
      {step + 1}
    </CustomStepIcon>
  );
};
