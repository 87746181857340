import { Grid, Stack } from '@mui/material';
import CustomAccordionContainer from 'components/Custom/CustomAccordion/CustomAccordionContainer';
import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';

type ReserveTokenType = {
    index: number
}

export function ReserveToken({ index }: ReserveTokenType) {
    const { watch } = useFormContext();
    const name = watch(`details.${index}.token`);

    return (
        <CustomAccordionContainer
            index={index}
            description={name ?? `Token #${index + 1}`}
        >
            <Stack spacing={2}>
                <Grid>
                    <SelectFormField
                        name={`details.${index}.warrantyRegistrationOffice`}
                        label='Sistema de averbação'
                        required
                        options={[
                            { label: "FGTS", value: "Fgts" },
                            { label: "Zetra", value: "Zetra" },
                            { label: "SIAPE", value: "Siape" },
                            { label: "Outros", value: "Others" },
                        ]}
                    />
                </Grid>
                <Grid>
                    <SelectFormField
                        name={`details.${index}.tokenType`}
                        label='Tipo do token'
                        required
                        options={[
                            { label: "Reserva", value: "Reserve" },
                            { label: "Pecúlio", value: "NestEgg" },
                            { label: "Averbação", value: "Warranty" }
                        ]}
                    />
                </Grid>
                <Grid>
                    <TextFormField
                        name={`details.${index}.tokenValue`}
                        variant='outlined'
                        label="Token"
                        required
                        fullWidth
                    />
                </Grid>
                {/* <Grid>
                    <TextFormField
                        name={`details.${index}.warrantyCode`}
                        variant='outlined'
                        label="Código ADE"
                        required
                        fullWidth
                    />
                </Grid> */}
                <Grid>
                    <DatePickerFormField
                        name={`details.${index}.expirationDate`}
                        label="Data de expiração"
                        required
                        fullWidth
                    />
                </Grid>
            </Stack>
        </CustomAccordionContainer>
    );
}
