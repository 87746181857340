import { Grid, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { CardData, DataTable } from '@uy3/web-components';
import { DatePickerFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { useFormContext } from 'contexts/formContext';
import { useFundsList } from 'contexts/fundContext';
import { useTenant } from 'contexts/tenantContext';
import { maskCurrencyInCents } from 'helpers';
import moment from 'moment';
import React from 'react';
import { LiquidationFileApiResponse } from 'services/remessasVortx/remessasVortx.type';

export const RemessaLiquidacaoForDateForm: React.FC = () => {
    const { currentTenantId } = useTenant()
    const { fundAutoCompleteProps } = useFundsList({ page: 0, size: 10, tenant: currentTenantId! }, 'always');

    const { watch } = useFormContext();

    return (
        <Grid container spacing={2} columns={6}>
            <Grid item xs={3}>
                <DatePickerFormField
                    label='Data de liquidação inicial'
                    fullWidth
                    name='dateMin'
                />
            </Grid>
            <Grid item xs={3}>
                <DatePickerFormField
                    label='Data de liquidação final'
                    fullWidth
                    name='dateMax'
                />
            </Grid>
            <Grid item xs={3}>
                <AutocompleteField
                    name="fundId"
                    label="Fundo"
                    displayName="fundIdDisplay"
                    link={`/cadastro/fundos/${watch('fundId')}`}
                    showEndIcon={watch('fundId')}
                    endIconType="link"
                    {...fundAutoCompleteProps!}
                />
            </Grid>
        </Grid>
    );
}

export type LiquidationReportTableProps = {
    reports: LiquidationFileApiResponse
}

const columns: GridColDef[] = [
    {
        field: 'fund',
        headerName: 'Fundo',
        hideSortIcons: true,
        editable: false,
        flex: 2,
        maxWidth: 500,
        renderCell: ({ value }) => value.name
    },
    {
        field: 'date',
        headerName: 'Data da baixa',
        hideSortIcons: true,
        editable: false,
        flex: 2,
        maxWidth: 500,
        renderCell: ({ value }) => moment(value).format('DD/MM/YYYY')
    },
    {
        field: 'liquidatedValueInCentsDisplay',
        headerName: 'Valor liquidado',
        hideSortIcons: true,
        editable: false,
        flex: 2,
        maxWidth: 500
    },
    {
        field: 'futureValueInCentsDisplay',
        headerName: 'Valor futuro',
        hideSortIcons: true,
        editable: false,
        flex: 2,
        maxWidth: 500
    },
    {
        field: 'liquidationFileUrl',
        headerName: 'Relatório',
        hideSortIcons: true,
        editable: false,
        flex: 2,
        maxWidth: 500,
        renderCell: ({ value }) => <a href={value} target='_blank'>Download</a>
    }
];

export const LiquidationReportTable: React.FC<LiquidationReportTableProps> = ({ reports }) => {
    return <Stack spacing={4} mt={2}>                
        <DataTable
            getRowId={(row) => row.liquidationFileUrl}
            headerBarWrapper={{
                header: 'Relatórios'
            }}
            columns={columns}
            rows={reports}
            page={0}
            rowCount={reports.length ?? 0}
            rowsPerPage={5}
            setPage={() => { }}
            setRowsPerPage={() => { }}
            hideFooter
            enableJumpAction={false}
        />
    </Stack>
}