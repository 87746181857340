import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { PayrollAgreementsList } from './PayrollAgreementsList';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { PayrollAgreementsListHeader } from './PayrollAgreementsListHeader';
import { Drawer } from '@uy3/web-components';
import {
    defaultValuesAddNewPayrollAgreements,
    validationSchemaAddNewPayrollAgreements,
} from './AddNewPayrollAgreements/AddNewPayrollAgreementsSchema';
import { AddNewPayrollAgreementsForm } from './AddNewPayrollAgreements/AddNewPayrollAgreementsForm';
import { useGetListPayrollAgreement } from 'contexts/PayrollAgreementContext';
import { FieldValues } from 'react-hook-form';
import { PayrollAgreementReadModel } from 'services/creditNote';
import { removeDuplicateData } from 'helpers';

export const PayrollAgreementsListContainer = () => {
    const [handleAction, setHandleAction] = useState<string | undefined>(undefined);

    const onClose = () => setHandleAction(undefined);

    const { watch, setValue } = useFormContext();
    const payrollAgreements = (watch('payrollAgreements') as PayrollAgreementReadModel[]) ?? [];

    const { payrollAgreementAutocompleteProps, data: payrollAgreementsData } =
        useGetListPayrollAgreement();

    const handleCratePayrollAgreements = (values: FieldValues) => {
        const payrollAgreementsIds = values?.payrollAgreementsIds;
        const findPayroll = payrollAgreementsData?.data?.filter((x) =>
            payrollAgreementsIds.includes(x.id)
        );

        if (payrollAgreements.length > 0) {
            let currentList = payrollAgreements;
            findPayroll?.forEach((item) => currentList.push(item));
            const valuesNotDuplicateValue = removeDuplicateData(currentList, 'id');
            setValue('payrollAgreements', valuesNotDuplicateValue);
            setValue(
                'payrollAgreementIds',
                valuesNotDuplicateValue.map((x) => x.id)
            );
        } else {
            setValue('payrollAgreements', findPayroll);
            setValue('payrollAgreementIds', payrollAgreementsIds);
        }

        onClose();
    };

    const handlePayrollAgreements = (index: number) => {
        const listItemRemoved = payrollAgreements?.filter(
            (_, currentIndex) => index !== currentIndex
        );
        setValue('payrollAgreements', listItemRemoved);
    };

    return (
        <Stack spacing={2}>
            <PayrollAgreementsListHeader handleAddNew={() => setHandleAction('create')} />

            <PayrollAgreementsList
                handleDeleteItem={handlePayrollAgreements}
                queryData={payrollAgreements}
            />

            <Drawer
                open={handleAction === 'create'}
                anchor="right"
                title="Adicionar orgão"
                onClose={onClose}
            >
                <FormProvider
                    validationSchema={validationSchemaAddNewPayrollAgreements()}
                    defaultValues={defaultValuesAddNewPayrollAgreements}
                    onSubmit={handleCratePayrollAgreements}
                >
                    <AddNewPayrollAgreementsForm
                        onClose={onClose}
                        payrollAgreementsAutocomplete={payrollAgreementAutocompleteProps}
                    />
                </FormProvider>
            </Drawer>
        </Stack>
    );
};
