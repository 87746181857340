import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import React, { ReactNode } from 'react';

interface CardRootProps {
    children: ReactNode;
    className?: string;
}

const CustomCardContainer = styled(Stack)`
    padding: 2rem;
    border-radius: 0.5rem;
    border: 1px solid #e0e3ea;
    border-left: 2px solid #ff4500;
    width: 100%;
    height: 500px;
    overflow: auto;
    flex-wrap: wrap;
    position: relative;
`;

const ContentWrapper = styled.div`
    max-width: 100%;
    word-wrap: break-word;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    min-height: '200px';
`;

export const CardRoot: React.FC<CardRootProps> = ({ children, className }) => {
    return (
        <CustomCardContainer className={className}>
            <ContentWrapper>{children}</ContentWrapper>
        </CustomCardContainer>
    );
};
