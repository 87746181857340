/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack, SvgIconProps } from '@mui/material';
import React, { Fragment, FunctionComponent } from 'react';
import { SelectFormField } from 'components/Forms/FormFields';
import CustomAccordionContainer from 'components/Custom/CustomAccordion/CustomAccordionContainer';
import { useFormContext } from 'contexts/formContext';
import { AvailableCashIcon, BankIcon, BankSlipIcon, NotificationIcon, Typography } from '@uy3/web-components';
import { ResourceNotificationType } from 'services/notifications/types/generic';
import { INotificationCustomFromSchemaType, INotificationToFormType } from 'services/notifications/types/notificationsCreateModel';

type SettingListFormProps = {
    fields: Record<'id', string>[];
};

type SettingFormByResourceProps = {
    index: number, 
    title: string, 
    resource: ResourceNotificationType
}

export const SettingListForm = ({ fields }: SettingListFormProps) => {
    //@ts-ignore
    const fieldsMappedType = (fields ?? []) as INotificationCustomFromSchemaType[];
    return (
        <Fragment>
            <Stack direction="column" spacing={2}>
                {fieldsMappedType.map((field: any , index) => {
                    const data = field as INotificationCustomFromSchemaType;
                    return (
                        <Stack key={field.id} direction="row" alignItems="center">
                            <SettingFormByResource
                                key={field.id}
                                index={index}
                                title={data?.title ?? ""}
                                resource={data?.resource ?? ""}
                            />
                        </Stack>
                    )
                })}
            </Stack>
        </Fragment>
    )
};

const SettingFormByResource: React.FC<SettingFormByResourceProps> = ({ index: referenceIndex, title, resource }) => {
    const { watch } = useFormContext();
    const prefixFields = `notificationSubscriptions.${referenceIndex}.fields`;
    const nameControlValueEnableConfiguration = `notificationSubscriptions.${referenceIndex}.isEnableConfiguration`

    const notificationSubscriptions = (watch(`${prefixFields}`) as INotificationToFormType[]) ?? [];

    const getIconByResource: {[type: string]: FunctionComponent<SvgIconProps>} = {
        "Bankslip": BankSlipIcon, 
        "Communication": NotificationIcon,
        "BankAccount": BankIcon,
        "CreditNote": AvailableCashIcon 
    };

    return (
        <Fragment>
            <CustomAccordionContainer
                index={referenceIndex}
                swithAccordionProps={{
                    Icon: getIconByResource[resource],
                    name: nameControlValueEnableConfiguration
                }}                
                description={title}
                descriptionVariant='xl'
                sxAccordion={{
                    padding: '8px'
                }}
            >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {notificationSubscriptions.map((field, indexField) => {
                        return (
                            <Grid item xs={6}>
                                <Typography fontSize='16px' fontWeight='700'>{field.typeDisplay}</Typography>
                                <Grid  mt={1}>
                                    <SelectFormField {...{
                                        label: "Filtrar por",
                                        name: `${prefixFields}.${indexField}.level`,
                                        options: [
                                            { label: 'Nenhum', value: "None" },
                                            { label: 'Só os meus', value: "OnlyMine" },
                                            { label: 'Meus grupos', value: "MyGroups" }, 
                                            { label: 'Todos', value: "All" }
                                        ],
                                        fullWidth: true,
                                        required: true
                                    }} />
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </CustomAccordionContainer>
        </Fragment>
    );
};
