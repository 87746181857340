enum ESignatureValidation {
    EletronicSignatureAndDocument = 'EletronicSignatureAndDocument', // Assinatura eletrônica + Documento
    SelfieSignature = 'SelfieSignature', // Assinatura com selfie
    SelfieDocumentSignature = 'SelfieDocumentSignature', // Assinatura com selfie + documento
    FacialBiometrics = 'FacialBiometrics', // Biometria facial
    DigitalCertificate = 'DigitalCertificate', // Certificado digital
    EletronicSignature = 'EletronicSignature', // Assinatura eletrônica (manuscrita)
}

enum ESignatureType {
    Email = 'email',
    SMS = 'sms',
    WhatsApp = 'whatsapp',
}

enum EViewType {
    BottomToTop = 0, //Fechado
    TopToBottom = 1, //Aberto
}

enum ESignaturePortalProvider {
    RBM = 0,
    ClickSign = 1,
    QuickSoft = 2,
    UnicoCheck = 3,
    UnicoCloud = 4,
}

enum ETypeOfRelationship {
    Issuer = 'Issuer', 
    PartnerGuarantor = 'PartnerGuarantor',
    Guarantor = 'Guarantor',
    Proxy = 'Proxy',
    ProxyGuarantor = 'ProxyGuarantor',
    Partner = 'Partner',
    Spouse = 'Spouse',
    Consenter = 'Consenter',
    Witness = 'Witness',
    Assignor = 'Assignor',
    Assignee = 'Assignee',
    Endorser = 'Endorser',
    Employer = 'Employer',
    Creditor = 'Creditor',
    Debtor = 'Debtor',
    PartnerDebtor = 'PartnerDebtor',
    BillingAgent = 'BillingAgent',
    BillingAgentApproverUser = 'BillingAgentApproverUser',
    Beneficiary = 'Beneficiary',
    NotaryAgent = 'NotaryAgent',
    SettlementAgent = 'SettlementAgent',
    CreditorEndorser = 'CreditorEndorser',
    FaithfulCustodian = 'FaithfulCustodian',
    JointGuarantor = 'JointGuarantor',
    ThirdPartyGuarantor = 'ThirdPartyGuarantor',
    LegalRepresentative = 'LegalRepresentative',
}

enum ECommissionType {
    Absolute = 0,
    Percentage = 1,
}

enum ECommissionBaseValue {
    InitialValue = 0,
    RequestedValue = 1,
}

enum ECalculationType {
    V252DiasUteis = 'V252DiasUteis',
    V252MesesX21 = 'V252MesesX21',
    V360DiasCorridos = 'V360DiasCorridos',
    V360Meses = 'V360Meses',
    V365DiasCorridos = 'V365DiasCorridos',
    V365Meses = 'V365Meses',
    Irregular = 'Irregular',
}

enum EPaymentMonth {
    NotSet = 'NotSet',
    January = 'January',
    February = 'February',
    March = 'March',
    April = 'April',
    May = 'May',
    June = 'June',
    July = 'July',
    August = 'August',
    September = 'September',
    October = 'October',
    November = 'November',
    December = 'December',
}

enum EPeriodicity {
    Monthly = 'Monthly',
    Yearly = 'Yearly',
    Daily = 'Daily',
}

enum ECalculateByValueType {
    Gross = 'Gross',
    Liquid = 'Liquid',
    Payment = 'Payment',
}

enum EOperationType {
    Transfer = 1,
    Pix = 2,
}

enum EPixKeyType {
    NaturalRegistrationNumber = "NaturalRegistrationNumber",
    LegalRegistrationNumber = "LegalRegistrationNumber",
    Phone = "Phone",
    Email = "Email",
    Automatic = "Automatic",
    AgencyAndAccount = "AgencyAndAccount",
}

enum ERelatedBankAccountType {
    NaturalCheckingAccount = 'NaturalCheckingAccount', // Conta de Pessoa Física
    NaturalSimpleAccount = 'NaturalSimpleAccount', // Conta Simples de Pessoa Física
    LegalCheckingAccount = 'LegalCheckingAccount', // Conta Pessoa Jurídica
    PublicEntities = 'PublicEntities', // Entidades Públicas
    FinancialInstitutionsDeposits = 'FinancialInstitutionsDeposits', // Depósitos Instituições Financeiras
    NaturalSavingsAccount = 'NaturalSavingsAccount', // Poupança de Pessoa Física
    LegalSavingsAccount = 'LegalSavingsAccount', // Poupança de Pessoa Jurídica
    RealEstateSavingsAccount = 'RealEstateSavingsAccount', // Poupança de Crédito Imobiliário
    LotteryDeposits = 'LotteryDeposits', // Depósitos Lotéricos
    EscrowAccount = 'EscrowAccount', // Conta Escrow
}

enum EPaymentType {
    QrCode = 'QrCode',
    Bankslip = 'Bankslip',
    BankTransfer = 'BankTransfer',
    Pix = 'Pix',
}

enum EAccountEntryType {
    BilletPayment = 1,
    TransferFeeDebit = 2,
}

export {
    EAccountEntryType,
    ESignatureValidation,
    ESignatureType,
    EViewType,
    ESignaturePortalProvider,
    ETypeOfRelationship,
    ECommissionType,
    ECommissionBaseValue,
    ECalculationType,
    EPaymentMonth,
    EPeriodicity,
    ECalculateByValueType,
    EOperationType,
    EPixKeyType,
    ERelatedBankAccountType,
    EPaymentType,
};
