import { CloudUpload } from '@mui/icons-material';
import { Box, Grid, InputAdornment } from '@mui/material';
import { Stack } from '@mui/system';
import { Typography } from '@uy3/web-components';
import { CheckboxFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { useFormContext } from 'contexts/formContext';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { TooltipComponent } from 'components/Tooltip/Tooltop';

type InformationBankAccountProductTabProps = {
    handleOpen: () => void;
    id: string;
};

function InformationBankAccountProductTab({
    handleOpen,
    id,
}: InformationBankAccountProductTabProps) {
    const { watch } = useFormContext();

    const watchSignature = watch('signaturePortalProvider');
    return (
        <Box py={3}>
            <Typography
                variant="h5"
                color="neutral.dark"
                sx={{
                    lineHeight: { xs: '24px', md: '24px' },
                    fontWeight: { xs: '700', md: '700' },
                    fontStyle: { xs: 'normal', md: 'normal' },
                }}
            >
                Dados do produto de conta
            </Typography>
            <Stack direction="column" mt={2}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextFormField
                            required
                            name="name"
                            label="Nome do produto"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFormField
                            fullWidth
                            label="Arquivo Minuta"
                            name="templateDoc.fileName"
                            variant="outlined"
                            disabled
                            required={watch('useSignaturePortal') === true}
                            InputLabelProps={{
                                shrink:
                                    watch('templateDoc.fileName')?.toString()?.length > 0
                                        ? true
                                        : false,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        {id !== 'novo' && (
                                            <InputAdornment position="end">
                                                <TooltipComponent
                                                    title="Download do Arquivo"
                                                    placement="top"
                                                    arrow
                                                >
                                                    <a
                                                        href={watch('templateDoc.tempGetUrl')}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <CloudDoneIcon />
                                                    </a>
                                                </TooltipComponent>
                                            </InputAdornment>
                                        )}
                                        <InputAdornment position="end">
                                            <TooltipComponent
                                                title="Atualizar Arquivo"
                                                placement="top"
                                                arrow
                                            >
                                                <span
                                                    onClick={handleOpen}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <CloudUpload />
                                                </span>
                                            </TooltipComponent>
                                        </InputAdornment>
                                    </>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectFormField
                            required
                            name="bankingAccountType"
                            label="Tipo de conta"
                            options={[
                                { label: 'Conta de Livre Movimentação', value: 'Payment' },
                                { label: 'Conta Escrow', value: 'Escrow' },
                                { label: 'Escrow +', value: 'EscrowPlus' },
                                { label: 'Escrow multicredores', value: 'EscrowMulticreditors' },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectFormField
                            required
                            name="viewType"
                            label="Visibilidade do Produto"
                            options={[
                                { label: 'Aberto', value: 'TopToBottom' },
                                { label: 'Fechado', value: 'BottomToTop' },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFormField
                            label="ID do Pacote"
                            name="rateExternalId"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CurrencyFormField
                            name="packageValue"
                            label="Valor do Pacote"
                            fullWidth
                            required
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </Stack>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Aprovação
                </Typography>
                <Stack direction="row" alignItems="center" spacing={4}>
                    <Grid container columns={10}>
                        <Grid item xs={3}>
                            <CheckboxFormField
                                color="primary"
                                name="instrumentApproval"
                                label="Etapa aprovação de instrumento"
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Parâmetros de Assinatura
                </Typography>
                <Stack direction="column" mt={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={2}>
                            <CheckboxFormField
                                color="primary"
                                label="Coletar assinatura"
                                name="useSignaturePortal"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckboxFormField
                                label="Assinatura com Selfie"
                                color="primary"
                                name="selfieSignature"
                            />
                        </Grid>
                        {watch('useSignaturePortal') === true && (
                            <>
                                <Grid item xs={2}>
                                    <SelectFormField
                                        required={watch('useSignaturePortal') === true}
                                        label="Forma de envio para assinatura"
                                        name="signatureType"
                                        fullWidth
                                        options={[
                                            { label: 'Email', value: 'email' },
                                            { label: 'Sms', value: 'sms' },
                                            { label: 'Whatsapp', value: 'whatsapp' }
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <SelectFormField
                                        required={watch('useSignaturePortal') === true}
                                        label="Certificadora"
                                        name="signaturePortalProvider"
                                        fullWidth
                                        options={[
                                            { label: 'RBM', value: 'RBM' },
                                            { label: 'ClickSign', value: 'ClickSign' },
                                            { label: 'QuickSoft', value: 'QuickSoft' },
                                            { label: 'UnicoCheck', value: 'UnicoCheck' },
                                            { label: 'UnicoCloud', value: 'UnicoCloud' }
                                        ]}
                                    />
                                </Grid>
                                {watchSignature === 'UnicoCheck' && (
                                    <>
                                        <Grid item xs={2}>
                                            <TextFormField
                                                label="Nome template"
                                                name="unicoTemplateName"
                                                variant="outlined"
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextFormField
                                                type="number"
                                                variant="outlined"
                                                label="Score mínimo"
                                                fullWidth
                                                name="unicoMinimumScoreForSignatureValidation"
                                                required
                                            />
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                    </Grid>
                </Stack>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                        m: '20px 0 ',
                    }}
                >
                    Validação de Assinatura
                </Typography>

                <Stack direction="row" alignItems="center" spacing={4}>
                    <Grid container columns={10}>
                        <Grid item xs={3}>
                            <CheckboxFormField
                                color="primary"
                                name="signatureValidationStep"
                                label="Validação de Assinatura"
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>

            <Box>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                        m: '20px 0 ',
                    }}
                >
                    Notificação por E-mail
                </Typography>
                <CheckboxFormField
                    name="sendEmailNotification"
                    color="primary"
                    label="Notificação por email"
                />
            </Box>
        </Box>
    );
}

export default InformationBankAccountProductTab;
