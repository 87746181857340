import { creditAxiosApi, createAxiosConfig } from 'services/axiosApi';
import {
    IFiltersPayrollAgreements,
    PayrollAgreementReadModel,
} from './types/PayrollAgreementReadModel';
import { GetListApiResponseSuccess } from 'contexts/apiRequestContext';
import { onlyNumbers } from 'helpers';
import {
    GetPublicPayrollLoanReadModel,
    IGetPublicPayrollLoanParams,
} from './types/GetPublicPayrollLoanReadModel';

const url = '/PayrollAgreement';
export async function getPayrollAgreementList(params: IFiltersPayrollAgreements, token: string) {
    const axiosConfig = createAxiosConfig(token, params);
    return await creditAxiosApi.get<GetListApiResponseSuccess<PayrollAgreementReadModel>>(
        `/PayrollAgreement`,
        axiosConfig
    );
}

export async function getByInternalId(internalId: string, token: string) {
    const axiosConfig = createAxiosConfig(token);
    const query = onlyNumbers(internalId);

    return await creditAxiosApi.get<PayrollAgreementReadModel>(
        `${url}/GetByInternalId/${query}`,
        axiosConfig
    );
}

export async function getGetPublicPayrollLoan(
    id: string,
    params: IGetPublicPayrollLoanParams,
    token: string
) {
    const axiosConfig = createAxiosConfig(token, params);

    const urlFull = `CreditNote/${id}/GetPublicPayrollLoan`;
    return await creditAxiosApi.get<GetListApiResponseSuccess<GetPublicPayrollLoanReadModel>>(
        urlFull,
        axiosConfig
    );
}
