import { useGetCreditNoteWorkflows } from 'contexts/creditNote/creditContext';
import { CreditNoteWorkflows } from './CreditNoteWorkflows';

type CreditNoteWorkflowsContainerType = {
    onClose: () => void;
    creditNoteId: string;
};

export const CreditNoteWorkflowsContainer = ({onClose, creditNoteId}: CreditNoteWorkflowsContainerType) => {
    const { data } = useGetCreditNoteWorkflows(creditNoteId);
    return (
        <CreditNoteWorkflows
            {...{
                onClose,
                creditNoteWorkflows: data ?? [],
            }}
        />
    );
};
