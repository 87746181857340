import { IOption } from 'components/CardSelect/CardSelect';
import {  maskCurrencyInCents } from 'helpers';
import { ISiapeMarginQueryReadModel } from 'services/datasets';
import { TooltipSiape } from '../SelectWarranty/TooltipWarrantyProductSelected';

export function summarySiape(data: ISiapeMarginQueryReadModel | undefined): IOption[] {
    const vinculoFuncionals = data?.result?.VinculoFuncionals ?? [];
    const createdAt = data?.createdAt!;

    const options: IOption[] = [];

    vinculoFuncionals.forEach((item) => {
        const margens = item?.Produtos ?? [];

        margens.forEach((margem) => {
            const vlMargemDisp = maskCurrencyInCents(margem?.VlMargemDisp);

            options.push({
                label: `${item?.NmOrgao} (${margem?.DsRubrica})`,
                description: `Matrícula: ${item.CdMatricula} | Data da consulta: ${new Date(
                    createdAt
                ).toLocaleDateString()} | Valor da margem: ${vlMargemDisp} - Rúbrica: ${
                    margem?.CdRubrica
                }`,
                value: `${item?.CnpjOrgao}-${item?.CodOrgao}-${margem?.CdConvenio}`,
                disabled: false,
                tooltipComponent: {
                    title: (
                        <TooltipSiape
                            values={item}
                            rubrica={{
                                DsRubrica: margem?.DsRubrica ?? '',
                                CdRubrica: margem?.CdRubrica ?? '',
                            }}
                        />
                    ),
                    placement: 'left',
                    children: <></>,
                },
            });
        });
    });

    return options;
}
