import { IconButton, Stack } from '@mui/material';
import {
    Button,
    CloseIcon,
    SaveIcon,
    Typography,
    ReceiveIcon,
    Alert,
    DeleteIcon,
} from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';
import CommissionFormCosts, {
    CommissionFormCostsAccordionMetadataKey,
} from './CommissionFormCosts';
import CommissionFormManager from './CommissionFormManager';
import { useFormContext } from 'contexts/formContext';
import { useFieldArray } from 'react-hook-form';

type CommissionRangeFormProps = {
    onClose: () => void;
};

type CostsForCommissionProps = {
    fields: Record<'id', string>[];
    remove: (index: number) => void;
    disabled: boolean;
};

const CostsForCommission = ({ fields, remove, disabled }: CostsForCommissionProps) => (
    <>
        <Typography variant="h6" component="h2" fontWeight={600} color="common.black">
            Informe os custos que compõe essa emissão
        </Typography>
        <Stack direction="column" spacing={2}>
            {fields.map((item, index) => (
                <Stack key={item.id} direction="row" alignItems="center">
                    <CommissionFormCosts key={item.id} index={index} />
                    <IconButton onClick={() => remove(index)} disabled={disabled}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ))}
        </Stack>
    </>
);

const theme = activeTheme();
export const CommissionRangeForm = ({ onClose }: CommissionRangeFormProps) => {
    const { control, setValue, watch, readOnly } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'details',
    });

    const currentAccordion = watch(CommissionFormCostsAccordionMetadataKey);

    const removeComm = (index: number) => {
        if (index < currentAccordion) {
            setValue(CommissionFormCostsAccordionMetadataKey, currentAccordion - 1);
        }
        if (index === currentAccordion) {
            setValue(CommissionFormCostsAccordionMetadataKey, null);
        }
        remove(index);
    };

    const addNewComm = () => {
        if (fields.length < 5) {
            append({
                baseValue: 'InitialValue',
                chargeCommissionFrom: 'All',
                isCustomerRegistrationFee: true,
            });
        }
        setValue(CommissionFormCostsAccordionMetadataKey, fields.length);
    };

    const htmlColor = readOnly ? theme.palette?.grey['400'] : theme.palette.primary.main;

    return (
        <Stack spacing={4}>
            <CommissionFormManager />
            {fields.length === 5 && (
                <Alert
                    severity="info"
                    text="Você atingiu o limite de custos para essa comissão."
                    variant="filled"
                />
            )}
            <CostsForCommission fields={fields} remove={removeComm} disabled={!!readOnly} />
            <Button
                sx={{
                    justifyContent: 'flex-start',
                    fontWeight: 600,
                    fontSize: '15px',
                }}
                disabled={readOnly}
                variant="text"
                onClick={addNewComm}
                startIcon={<ReceiveIcon htmlColor={htmlColor} sx={iconSx} />}
            >
                Adicionar custo
            </Button>

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                >
                    Fechar
                </Button>
                <Button
                    startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                    variant="contained"
                    size="medium"
                    type="submit"
                >
                    Salvar
                </Button>
            </Stack>
        </Stack>
    );
};
