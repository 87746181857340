import { isEmpty } from 'lodash';
import { NotificationLevelEnum } from 'services/notifications/enums/notificationLevelEnum';
import {
    getNotificationTypeDescription,
    NotificationTypeEnum,
} from 'services/notifications/enums/notificationTypeEnum';
import { INotificationCustomFromSchemaType } from 'services/notifications/types/notificationsCreateModel';
import {
    NotificationSettingReadModel,
    NotificationSubscription,
} from 'services/notifications/types/notificationsReadModel';
import { array, boolean, object, string } from 'yup';

const NotificationSubscriptionSchema = object().shape({
    type: string()
        .typeError('Tipo: precisa ser preenchido')
        .required('Tipo: precisa ser preenchido'),
    level: string()
        .typeError('Nível: precisa ser preenchido')
        .required('Nível: precisa ser preenchido'),
    typeDisplay: string().nullable().notRequired(),
});

const ListSchema = object().shape({
    resource: string().nullable(),
    title: string().nullable(),
    fields: array().of(NotificationSubscriptionSchema).nullable(),
    isEnableConfiguration: boolean().nullable(),
});

export function validationSchemaSettingNotificationForm() {
    return object().shape({
        notificationSubscriptions: array()
            .of(ListSchema)
            .min(1, 'É necessário informar ao menos uma definição de preferência.'),
    });
}

const mapperObj = (notification: NotificationSubscription) => {    
    return {
        typeDisplay: getNotificationTypeDescription(NotificationTypeEnum[notification.type as keyof typeof NotificationTypeEnum]),
        type: notification.type,
        level: notification.level,
    };
};

export const defaultValuesSettingNotification = (
    notificationData: NotificationSettingReadModel
) => {
    const list = notificationData?.notificationSubscriptions ?? [];

    // filter type includes in NotificationTypeEnum to bankslip
    const notificationListBankSlip =
        list?.filter((x) => [
            "Billing_Cobranca_Pago",
            "Billing_Cobranca_Registrado",
            "Billing_Cobranca_Cancelado",
            "Billing_Cobranca_Protestado",
            "Billing_Cobranca_EnviadoCartorio",
            "Billing_Carteira_TarifasAtualizadas",
            "Billing_ArquivoRemessa_ErroProcessamento"
        ].includes(x.type))?.map((x) => mapperObj(x)) ?? [];

    const notificationListCreditNoteOp =
        list?.filter((x) => [
            "Credit_CreditNote_StatusUpdate",
            "Credit_CreditNote_Liquidation_Success",
            "Credit_CreditNote_Liquidation_Failure",
        ].includes(x.type))?.map((x) => mapperObj(x)) ?? [];

    const notificationListBanking =
        list?.filter((x) => [
            "Banking_AccountEntry_Success",
            "Banking_AccountEntry_Failure",
            "Banking_ReverseAccountEntry_Success",
            "Banking_ReverseAccountEntry_Failure",
            "Banking_OpenAccount_Success",
            "Banking_OpenAccount_Failure",
            "Banking_AccountClose_Success",
            "Banking_AccountClose_Failure",
            "Banking_Transfer_Approval_Success",
            "Banking_Transfer_Approval_Failure",
            "Banking_Transfer_Success",
            "Banking_Transfer_Failure",
            "Banking_BankSlip_Approval_Success",
            "Banking_BankSlip_Approval_Failure",
            "Banking_BankSlip_Payment_Success",
            "Banking_BankSlip_Payment_Failure"
        ].includes(x.type))?.map((x) => mapperObj(x)) ?? [];


    // filter type includes in NotificationTypeEnum to communication
    const notificationListCommunication = list?.filter((x) => x.type === "Notice")?.map((x) => mapperObj(x)) ?? [];
    
    return {        
        notificationSubscriptions: [
            {
                resource: 'Bankslip',
                title: 'Boletos',
                isEnableConfiguration: !notificationListBankSlip?.every((x) => x.level === "None"),
                fields: !isEmpty(notificationListBankSlip) ? notificationListBankSlip : defaultListBankSlip,
            },
            {
                resource: 'Communication',
                title: 'Comunicado',
                isEnableConfiguration: !notificationListCommunication?.every((x) => x.level === "None"),
                fields: notificationListCommunication,
            },
            {
                resource: 'BankAccount',
                title: 'Conta digital',
                isEnableConfiguration: !notificationListBanking?.every((x) => x.level === "None"),
                fields: notificationListBanking,
            },
            {
                resource: 'CreditNote',
                title: 'Operações de crédito',
                isEnableConfiguration: !notificationListCreditNoteOp?.every((x) => x.level === "None"),
                fields: notificationListCreditNoteOp,
            },
        ] as INotificationCustomFromSchemaType[],
    };
};

const defaultListBankSlip = [
    {
        typeDisplay: getNotificationTypeDescription(NotificationTypeEnum.Billing_Cobranca_Pago),
        type: NotificationTypeEnum.Billing_Cobranca_Pago,
        level: NotificationLevelEnum.None,
    },
    {
        typeDisplay: getNotificationTypeDescription(
            NotificationTypeEnum.Billing_Cobranca_Registrado
        ),
        type: NotificationTypeEnum.Billing_Cobranca_Registrado,
        level: NotificationLevelEnum.None,
    },
    {
        typeDisplay: getNotificationTypeDescription(
            NotificationTypeEnum.Billing_Cobranca_EnviadoCartorio
        ),
        type: NotificationTypeEnum.Billing_Cobranca_EnviadoCartorio,
        level: NotificationLevelEnum.None,
    },
    {
        typeDisplay: getNotificationTypeDescription(
            NotificationTypeEnum.Billing_Cobranca_Protestado
        ),
        type: NotificationTypeEnum.Billing_Cobranca_Protestado,
        level: NotificationLevelEnum.None,
    },
];

const defaultListCommunication = [
    {
        typeDisplay: getNotificationTypeDescription(NotificationTypeEnum.Notice),
        type: NotificationTypeEnum.Notice,
        level: NotificationLevelEnum.None,
    },
];
