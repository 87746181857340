export const assignmentCalculationModeOptions = [
    { label: 'Padrão', value: 'Default' },
    { label: 'Valor Inicial ', value: 'InitialValue' },
    { label: 'Valor Presente', value: 'PresentValueCreditNote' },
    { label: 'Valor Presente Parcela', value: 'PresentValuePayment' },
];

export const liquidationModeOptions = [
    { label: 'Liquidação Simples', value: 'Simple' },
    { label: 'Roteiro de Liquidação', value: 'LiquidationSchedule' },
];

export const filterByOperationOptions = [
    { label: 'Todos', value: 'searchString' },
    { label: 'Modo de liquidação', value: 'liquidationMode' },
    { label: 'Status', value: 'status' },
    { label: 'Categoria do produto', value: 'productCategoryDisplay' },
    { label: 'Produto', value: 'productId' },
    { label: 'Tomador', value: 'personId' },
    { label: 'Envolvidos', value: 'relatedPersons' },
    { label: 'Grupos', value: 'filterByOwnerGroup' },
    { label: 'Data de início', value: 'initialDate' },
    { label: 'Data de pagamento', value: 'initialPaymentDate' },
    { label: 'Valor máximo', value: 'maxValue' },
    { label: 'Valor mínimo', value: 'minValue' },
    { label: 'Correspondente', value: 'tenant' },
    { label: 'Número da operação', value: 'creditNoteNo' },
    { label: 'Liquidação Própria?', value: 'ownLiquidation' },
    { label: 'Aprovação compliance própria?', value: 'ownCompliance' },
];
