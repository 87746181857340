import React from 'react';
import { CustomConnector, CustomStep, CustomStepper } from './Stepper.styled';
import { CustomStepperProps } from './Stepper.interface';
import { StepIcon } from '../../atoms';

export const Stepper: React.FC<CustomStepperProps> = ({ activeStep, totalSteps, onStepClick }) => {
  const steps = Array.from({ length: totalSteps }, (_, index) => index);
  return (
    <CustomStepper>
      {steps.map((step) => {
        const isCompleted = step < activeStep;
        const isActive = activeStep === step;
        return (
          <CustomStep
            key={`step-${step}`}
            style={{ width: step === steps.length - 1 ? 'min-content' : '' }}
          >
            <StepIcon active={isActive} completed={isCompleted} step={step} onClick={onStepClick} />
            {step !== steps.length - 1 && (
              <CustomConnector isActive={isActive} isCompleted={isCompleted} />
            )}
          </CustomStep>
        );
      })}
    </CustomStepper>
  );
};
