import { Stack } from '@mui/material';
import { CancelIcon, DownloadIcon, RegisterIcon, RulerIcon, SearchIcon, TransferIcon, Typography, UpdateIcon } from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { iconSx } from 'contexts/apiRequestContext';
import { useUserPermissionData } from 'contexts/userContext';
import React from 'react';
import { activeTheme } from 'services/theme';
import { IInstallmentFull } from 'services/walletManagement/installment/installment.types';

interface IInstallmentFormHeaderProps {
    isFetching: boolean;
    refetch: () => void;
    downloadPdf: () => void;
    setHandleAction: React.Dispatch<React.SetStateAction<string | undefined>>
    installmentData: IInstallmentFull; 
    redirectFromBilling: () => void;
    isLoadingDownloadPdf: boolean;
}

const theme = activeTheme();
const InstallmentFormHeader: React.FC<IInstallmentFormHeaderProps> = ({
    isFetching,
    refetch,
    downloadPdf,
    isLoadingDownloadPdf = false,
    setHandleAction, 
    installmentData, 
    redirectFromBilling
}) => {
    const { hasPermission } = useUserPermissionData();
    const color = (!isFetching && !isLoadingDownloadPdf) ? theme.palette.common.black : theme.palette?.grey['400'];

    return (
        <Stack justifyContent="space-between" alignItems="center" direction="row">
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="32px"
                lineHeight="38.4px"
            >
                Parcelamento
                <Typography
                    variant="h6"
                    color="neutral.medium"
                    sx={{
                        lineHeight: { xs: '20px', md: '20px' },
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '16px',
                        opacity: '87%',
                    }}
                >
                    Confira as informações essenciais do parcelamento e suas cobranças
                </Typography>
            </Typography>
            <Actions
                buttonsActionsList={[
                    {
                        label: "Atualizar",
                        action: refetch,
                        icon: <UpdateIcon htmlColor={color} sx={iconSx} />,
                        enable: true,
                        disabled: isLoadingDownloadPdf
                    },
                    {
                        label: "Download Carnê",
                        action: downloadPdf,
                        icon: <DownloadIcon htmlColor={color} sx={iconSx} />,
                        enable: true,
                        disabled: isLoadingDownloadPdf
                    },
                    {
                        action: () => setHandleAction('cancel'),
                        label: "Cancelar",
                        disabled: false,
                        icon: <CancelIcon sx={iconSx} />, 
                        enable: true
                    },
                    {
                        action: () => setHandleAction('transfer'),
                        label: "Transferir",
                        disabled: false,
                        icon: <TransferIcon sx={iconSx} />, 
                        enable: true
                    }, 
                    {
                        action: () => setHandleAction('updateCommunicationSetting'),
                        label: "Atualizar régua de comunicação",
                        disabled: false,
                        icon: <RulerIcon sx={iconSx} />, 
                        enable: hasPermission('BillingAccount', 'Update')
                    }, 
                    {
                        action: () => setHandleAction('register'),
                        label: "Registrar",
                        disabled: false,
                        icon: <RegisterIcon sx={iconSx} />, 
                        enable: !installmentData?.registered
                    }, 
                    {
                        action: redirectFromBilling,
                        label: "Ver cobranças",
                        disabled: false,
                        icon: <SearchIcon sx={iconSx} />, 
                        enable: true
                    }, 
                ]}
                numberOfButtons={5}
            />
        </Stack>
    );
};
export default InstallmentFormHeader;
