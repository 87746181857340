export class FraudRecordCreateModel {
    referenceId?: string;
    evidenceType?: string;
    fraudModality?: string;
    relatedActivity?: string;
    eventDateTime?: string;
    details?: Details;
    location?: Location;
    transactionValue?: number;
    description?: string;
    operationType?: string;

    constructor(init?: Partial<FraudRecordCreateModel>) {
        Object.assign(this, init);
    }
}

export interface Details {
    reporter: Reporter;
    executor: Executor;
    toAccount: ToAccount;
}

export interface Reporter {
    detailsType: string;
    taxPayer: string;
    documentNumber: number;
    fullName: string;
    tradeName: string;
    cpfLegalRepresentatives: number[];
    email: string;
    phoneCountryCode: number;
    phoneAreaCode: number;
    phoneNumber: number;
    phoneFullValue: number;
    involvement: string;
}

export interface Executor {
    detailsType: string;
    taxPayer: string;
    documentNumber: number;
    fullName: string;
    tradeName: string;
    cpfLegalRepresentatives: number[];
    email: string;
    phoneCountryCode: number;
    phoneAreaCode: number;
    phoneNumber: number;
    phoneFullValue: number;
}

export interface ToAccount {
    detailsType: string;
    taxPayer: string;
    documentNumber: number;
    fullName: string;
    tradeName: string;
    cpfLegalRepresentatives: number[];
    email: string;
    phoneCountryCode: number;
    phoneAreaCode: number;
    phoneNumber: number;
    phoneFullValue: number;
    institutionCode: number;
    accountType: string;
    agencyCode: string;
    accountNumber: string;
    involvement: string;
}

export interface Location {
    channel: string;
    local: string;
    ipAddress: number;
    device: string;
}

export interface FraudDataset {
    registrationNumber: string;
    requestType: string;
    clearSaleR6Id: string;
    responseAPI: ResponseApi;
    error: boolean;
}

export interface ResponseApi {
    id: string;
    results: ResultR6[];
    createdAt: string;
}

export interface ResultR6 {
    institution: Institution;
    datasource: number;
    datasourceDisplay: string;
    evidenceType: number;
    evidenceTypeDisplay: string;
    fraudModality: number;
    fraudModalityDisplay: string;
    relatedActivity: number;
    relatedActivityDisplay: string;
    contractValue: any;
    description: any;
    role: number;
    roleDisplay: string;
    details: Details;
    location: Location;
    eventDateTime: string;
    createdAt: string;
}

export interface Institution {
    cnpj: number;
    companyName: string;
}

export interface Details {
    taxPayer: number;
    taxPayerDisplay: string;
    documentNumber: number;
    cpfLegalRepresentatives: any;
    fullName: string;
    tradeName: any;
    email: any;
    phoneCountryCode: any;
    phoneAreaCode: any;
    phoneNumber: any;
    phoneFullValue: any;
    involvement: number;
    involvementDisplay: string;
    institutionCode: any;
    accountType: any;
    accountTypeDisplay: any;
    agencyCode: any;
    accountNumber: any;
}
