import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...rest }: TooltipProps) => (
    <Tooltip {...rest} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: "13px"
    },
}));

export type TooltipComponentProps = {
    title: string | React.ReactNode;
    children?: React.JSX.Element;
    size?: "small" | "defaullt" | "large";
} & TooltipProps;

export const TooltipComponent = ({ children, title, size, ...rest }: TooltipComponentProps) => {
    return (
        <BootstrapTooltip {...{ title, ...rest }}>
            {children}
        </BootstrapTooltip>
    );
}