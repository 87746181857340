/* eslint-disable sonarjs/no-all-duplicated-branches */
import type { AutocompleteFieldProps } from './Autocomplete.interface';
import { FunctionComponent } from 'react';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { Link } from 'react-router-dom';
import { Autocomplete } from './Autocomplete.styled';
import { TextField } from '../TextField';
import { SearchIcon } from '../../icons';
import { Typography } from '../Typography';

type EndAdornmenProps = {
  showEndIcon: boolean;
  endIconType: 'link' | 'submit' | undefined;
  link: string | undefined;
};

export const AutocompleteField: FunctionComponent<AutocompleteFieldProps> = (props) => {
  const {
    options = [],
    onChange,
    label,
    multiple = false,
    value,
    error,
    loading,
    link,
    showEndIcon = false,
    endIconType,
    onChangeTextField,
    name,
    ...selectProps
  } = props;
  const arrayValue = Array.isArray(value) && !!multiple ? value : !multiple ? value : [];

  const renderOption = (props: object, option: any) => {
    return (
      <Typography {...props} variant="h6" fontFamily={'"Lato", sans-serif'}>
        {option.label}
      </Typography>
    );
  };

  const EndAdornmen = ({ showEndIcon, link = '', endIconType }: EndAdornmenProps) => {
    return (
      <InputAdornment position="end" sx={{ pr: 1, lineHeight: 0 }}>
        {showEndIcon && endIconType === 'link' && (
          <Link to={link} style={{ cursor: 'pointer' }}>
            <SearchIcon htmlColor="#D04D27" sx={{ display: 'inline-block', width: '2.1rem' }} />
          </Link>
        )}
        {showEndIcon && endIconType === 'submit' && (
          <IconButton type="submit">
            <SearchIcon htmlColor="#D04D27" sx={{ display: 'inline-block', width: '2.1rem' }} />
          </IconButton>
        )}
      </InputAdornment>
    );
  };

  return (
    <Autocomplete
      renderOption={renderOption}
      {...selectProps}
      options={options}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      onChange={(event, value, reason, details) => {
        if (multiple) {
          onChange && onChange(event, value, reason, details);
        } else {
          onChange && onChange(event, value, reason, details);
        }
      }}
      clearText="Remover"
      loadingText="Carregando"
      noOptionsText="Nenhum registro encontrado"
      filterSelectedOptions
      clearOnEscape
      value={arrayValue}
      renderInput={(params) => (
        <TextField
          error={error}
          {...params}
          name={`autocomplete-${name}`}
          variant="outlined"
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
                <EndAdornmen showEndIcon={showEndIcon} link={link} endIconType={endIconType} />
              </>
            ),
            onChange: onChangeTextField,
          }}
        />
      )}
    />
  );
};

export default AutocompleteField;
