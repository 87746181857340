import { Grid, Stack } from '@mui/material';
import { AvailableCashIcon, Button, CardData, CloseIcon, Typography } from '@uy3/web-components';
import { DatePickerFormField } from 'components/Forms/FormFields';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { useFormContext } from 'contexts/formContext';
import moment from 'moment';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { activeTheme } from 'services/theme';
import ViewTransactionalLimitsDrawer from '../../ViewTransactionalLimitsDrawer';
import { Radio } from 'components/Forms/Radio/Radio';
import { PaymentBankSlip } from 'contexts/bankAccount/bankAccountType';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { isEmpty } from 'lodash';

type PaySlipDetailsProps = {
    bankSlipData: PaymentBankSlip;
    onCloseDrawer: () => void;
    withPaymentAmount?: boolean;
};

const theme = activeTheme();
export const PaySlipDetails = ({ bankSlipData, onCloseDrawer, withPaymentAmount = true }: PaySlipDetailsProps) => {
    const { watch, setValue, submitting } = useFormContext();

    const transferDateRadioValue = () => {
        var check = watch('paymentDate') === moment().format('yyyy-MM-DD');
        return check ? 'today' : 'otherDay';
    };

    const totalValue = bankSlipData?.totalValue;
    const differentMaximumAndMinimumValues = bankSlipData?.minimumValue !== bankSlipData?.maximumValue;
    const showPaymentValueField = withPaymentAmount && differentMaximumAndMinimumValues;

    const totalPayValueFormated = differentMaximumAndMinimumValues ?
        formatCurrencyInCents(watch('paymentValue')) : formatCurrencyInCents(totalValue)

    const detailsBankSlip = [
        {
            id: '1',
            title: 'Data de vencimento',
            value: `${moment(bankSlipData?.dueDate).format('DD/MM/yyyy') ?? "N/D"}`,
        },
        {
            id: '2',
            title: 'Valor nominal',
            value: `${formatCurrencyInCents(bankSlipData?.nominalValue) ?? "N/D"}`,
        },
        {
            id: '3',
            title: 'Encargos/juros',
            value: `${formatCurrencyInCents(bankSlipData?.fee) ?? "N/D"}`,
        },
        {
            id: '4',
            title: 'Descontos',
            value: `${formatCurrencyInCents(bankSlipData?.discount) ?? "N/D"}`,
        },
        {
            id: '5',
            title: 'Multa',
            value: `${formatCurrencyInCents(bankSlipData?.trafficTicket) ?? "N/D"}`,
        },
        {
            id: '6',
            title: 'Valor total',
            value: `${isEmpty(totalValue)
                ? formatCurrencyInCents(totalValue)
                : "N/D"
                }`,
        },
    ]


    if (differentMaximumAndMinimumValues) {
        detailsBankSlip.push(
            {
                id: '7',
                title: 'Valor minimo de pagamento',
                value: `${formatCurrencyInCents(bankSlipData?.minimumValue) ?? "N/D"}`,
            },
            {
                id: '8',
                title: 'Valor máximo de pagamento',
                value: `${formatCurrencyInCents(bankSlipData?.maximumValue) ?? "N/D"}`,
            },
        )
    }

    return (
        <>
            <Stack spacing={3} pt={2}>
                <Typography variant="lg" fontWeight="700" mb={2}>
                    Dados do beneficiário e pagador original
                </Typography>
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid width="100%">
                        <CardData
                            listItem={[
                                { id: '1', title: 'Beneficiário' },
                                {
                                    id: '2',
                                    title: 'CPF/CNPJ',
                                    value: `${isEmpty(bankSlipData?.beneficiary?.document)
                                        ? 'N/D'
                                        : formatDocumentNumber(bankSlipData?.beneficiary?.document)
                                        }`,
                                },
                                {
                                    id: '3',
                                    title: 'Nome/Razão Social',
                                    value: `${isEmpty(bankSlipData?.beneficiary?.name)
                                        ? 'N/D'
                                        : bankSlipData?.beneficiary?.name
                                        }`,
                                },
                            ]}
                        />
                    </Grid>
                    <Grid width="100%">
                        <CardData
                            listItem={[
                                { id: '1', title: 'Pagador' },
                                {
                                    id: '2',
                                    title: 'Nome completo',
                                    value: `${isEmpty(bankSlipData?.payer?.name)
                                        ? 'N/D'
                                        : bankSlipData?.payer?.name
                                        }`,
                                },
                                {
                                    id: '3',
                                    title: 'CPF/CNPJ',
                                    value: `${isEmpty(bankSlipData?.payer?.document)
                                        ? 'N/D'
                                        : formatDocumentNumber(bankSlipData?.payer?.document)
                                        }`,
                                },
                            ]}
                        />
                    </Grid>

                    <Grid container justifyContent={'flex-start'}>
                        <Typography variant="lg" fontWeight="700">
                            Detalhes do boleto
                        </Typography>
                    </Grid>

                    <Grid width="100%">
                        <CardData
                            listItem={detailsBankSlip}
                        />
                    </Grid>

                    <Grid container justifyContent={'flex-start'}>
                        <Typography variant="lg" fontWeight="700">
                            Quando pagar?
                        </Typography>
                    </Grid>

                    <Stack spacing={2} width="100%">
                        <Grid
                            item
                            display="flex"
                            alignItems="center"
                            mb={3}
                            width={500}
                            height={30}
                        >
                            <Radio
                                options={[
                                    {
                                        label: `Hoje ${moment().format('DD/MM/YYYY')}`,
                                        value: 'today',
                                    },
                                    { label: `Agendar para`, value: 'otherDay' },
                                ]}
                                onChange={(event) => {
                                    let option = event.target.value;
                                    option === 'today'
                                        ? setValue('paymentDate', moment().format('yyyy-MM-DD'))
                                        : setValue('paymentDate', null);
                                }}
                                value={transferDateRadioValue()}
                                row
                            />
                        </Grid>
                        {transferDateRadioValue() === 'otherDay' && (
                            <Grid>
                                <DatePickerFormField
                                    label="Data do Pagamento"
                                    name="paymentDate"
                                    fullWidth
                                    shrink
                                    required
                                    disablePast
                                />
                            </Grid>
                        )}
                        {showPaymentValueField &&
                            <Grid item mt={3} xs={12}>
                                <CurrencyFormField
                                    label="Valor do pagamento"
                                    fullWidth
                                    name="paymentValue"
                                    variant="outlined"
                                    required
                                />
                            </Grid>}
                    </Stack>
                </Stack>

                <Stack justifyContent={'flex-end'} alignItems={'flex-end'}>
                    <ViewTransactionalLimitsDrawer />
                </Stack>

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={3}
                    mt={3}
                >
                    <Button
                        startIcon={
                            <CloseIcon
                                htmlColor={theme.palette.primary.main}
                                sx={{ height: 19, width: 20 }}
                            />
                        }
                        variant="outlined"
                        size="medium"
                        onClick={onCloseDrawer}
                    >
                        Fechar
                    </Button>
                    <Button
                        name="btn-pay"
                        type="submit"
                        variant="contained"
                        disabled={submitting}
                        startIcon={
                            <AvailableCashIcon
                                htmlColor={theme.palette.common.white}
                                sx={{ height: 19, width: 20 }}
                            />
                        }
                        sx={{ justifyContent: 'center' }}
                    >
                        Pagar {totalPayValueFormated}
                    </Button>
                </Stack>
            </Stack>
        </>
    );
};