export class CalculateDuePaymentCreateModel {
    creditNoteId?: string;
    referenceDate?: string;
    numberOfDays?: number;

    constructor(init?: Partial<CalculateDuePaymentCreateModel>) {
        Object.assign(this, init);
    }
}
export interface CalculateDuePaymentResponse {
    liquidationDate: string;
    dueValue: number;
    liquidValue: number;
    changeValue: number;
}
