import { Grid, IconButton, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataTable, InfoIcon } from '@uy3/web-components';
import { TooltipComponent } from 'components/Tooltip/Tooltop';
import { iconSx } from 'contexts/apiRequestContext';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import React from 'react';
import { IServidores, Margen } from 'services/zetra';

type ZetraMarginQueryListProps = {
    queryData: IServidores[];
    handleShowDetail: (row: IServidores) => void;
};

export const ZetraMarginQueryList: React.FC<ZetraMarginQueryListProps> = ({
    handleShowDetail,
    queryData,
}) => {
    const columns: GridColDef[] = [
        {
            field: 'orgao',
            headerName: 'Nome do órgão',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'orgaoCodigo',
            headerName: 'Código do órgão',
            hideSortIcons: true,
            minWidth: 120,
            flex: 2,
            editable: false,
        },
        {
            field: 'matricula',
            headerName: 'Matrícula',
            hideSortIcons: true,
            editable: false,
            minWidth: 150,
            flex: 2,
        },
        {
            field: 'margens',
            headerName: 'Valor da margem',
            hideSortIcons: true,
            minWidth: 210,
            editable: false,
            flex: 1,
            renderCell: ({ row }) => {
                const values = row?.margens?.map((x: Margen) => x?.valorDisponivel) as number[];
                const totalValue = values?.reduce((acc, current) => acc + current);
                return formatCurrencyInCents(totalValue);
            },
        },

        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 50,
            align: 'center',
            headerAlign: 'center',
            editable: false,
            renderCell: ({ row }) => {
                return (
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        <TooltipComponent title="Ver mais" placement="right">
                            <Grid>
                                <IconButton onClick={() => handleShowDetail(row as IServidores)}>
                                    <InfoIcon sx={iconSx} />
                                </IconButton>
                            </Grid>
                        </TooltipComponent>
                    </Stack>
                );
            },
        },
    ];
    return (
        <Stack spacing={2}>
            <DataTable
                columns={columns}
                rows={queryData ?? []}
                page={0}
                rowCount={queryData?.length ?? 0}
                rowsPerPage={5}
                setPage={() => {}}
                setRowsPerPage={() => {}}
                getRowId={() => Math.random().toString()}
            />
        </Stack>
    );
};
