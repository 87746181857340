import styled from '@emotion/styled';
import { Grid, Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { CheckboxFormField, DatePickerFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import React from 'react';

const FileStyled = styled('input')({
    display: 'block',
    padding: '10px',
    borderRadius: '8px',
    background: '#E5E7ED',
    textAlign: 'center'
})

export const ConciliacaoLiquidacaoForm: React.FC = () => {
    const { setValue } = useFormContext();
    return (
        <Stack spacing={2} mt={4}>

            <Grid sx={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                maxWidth: '100%',
                gap: 2
            }}>
                <DatePickerFormField
                    label='Data Repasse'
                    name='transferDate'
                    fullWidth
                />
                <DatePickerFormField
                    label='Data Garantia'
                    name='dueDate'
                    fullWidth
                />

            </Grid>
            <Typography
                variant="h6"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="23px"
                lineHeight="38.4px"
            >
                Arquivo
            </Typography>
            <FileStyled
                type='file'
                name='file'
                onChange={(values) => {
                    const files = values?.target.files as FileList;
                    if (files.length === 0 || files === null) return;
                    setValue('file', values!.target!.files![0]);
                }}
            />

            <Grid sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2
            }}>
                <CheckboxFormField
                    name='persist'
                    label='Persistir'
                />
                <CheckboxFormField
                    name='queryCaixa'
                    label='Consultar Caixa'
                />

            </Grid>
        </Stack>
    );
}