import { Grid } from '@mui/material';
import {
    DeleteIcon,
    UpdateIcon,
    TotalValueIcon,
    CancelIcon,
    ReceiveIcon,
    AddIcon,
} from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { useTenant } from 'contexts/tenantContext';
import { useUserPermissionData } from 'contexts/userContext';
import { activeTheme } from 'services/theme';

type BatchAssignmentButtonListProps = {
    onCancel: () => void;
    onDelete: () => void;
    onTotalValues: () => void;
    addOrUpdateFGTSAssignment: () => void;
    includeBatchAssignment: () => void;
    refetch: () => void;
    selectedStatusOfTable: string[];
};

const theme = activeTheme();

const BatchAssignmentButtonList = ({
    onCancel,
    onDelete,
    refetch,
    addOrUpdateFGTSAssignment,
    includeBatchAssignment, 
    onTotalValues,
    selectedStatusOfTable
}: BatchAssignmentButtonListProps) => {
    const { hasPermission } = useUserPermissionData();
    const { isRootTenancy } = useTenant();

    const actionsCreditNoteList = [
        {
            enable: true,
            label: 'Atualizar',
            action: refetch,
            icon: <UpdateIcon htmlColor={theme.palette.common.black} />,
        },
        {
            label: 'Incluir/Atualizar Cessão FGTS',
            action: addOrUpdateFGTSAssignment,
            enable: isRootTenancy,
            icon: <ReceiveIcon />,
        },
        {
            label: 'Nova cessão',
            action: includeBatchAssignment,
            enable: hasPermission('BatchAssignment', 'Create'),
            icon: <AddIcon />,
        },
        {
            enable: selectedStatusOfTable?.length && isRootTenancy,
            label: 'Excluir',
            action: onDelete,
            icon: <DeleteIcon htmlColor={theme.palette.common.black} />,
        },
        {
            enable: selectedStatusOfTable?.length && isRootTenancy,
            label: 'Cancelar',
            action: onCancel,
            icon: <CancelIcon htmlColor={theme.palette.common.black} />,
        },
        {
            enable: selectedStatusOfTable?.length && isRootTenancy,
            label: 'Total',
            action: onTotalValues,
            icon: <TotalValueIcon htmlColor={theme.palette.common.black} />,
        },
    ];

    return (
        <>
            <Grid sx={{ mr: -1 }}>
                <Actions numberOfButtons={4} buttonsActionsList={actionsCreditNoteList} />
            </Grid>
        </>
    );
};

export default BatchAssignmentButtonList;
