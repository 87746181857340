import { Grid } from '@mui/material';
import { TextFormField } from 'components/Forms/FormFields';
import { AddIcon, Button, CloseIcon, RightIcon } from '@uy3/web-components';
import { activeTheme } from 'services/theme';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';

type UpdateZetraFormProps = {
    onCloseDrawer: () => void;
    setIsNewToken: (openDrawer: boolean) => void;
};

const theme = activeTheme().palette;
const colorMain = theme.primary.main;
export default function UpdateZetraForm({ onCloseDrawer, setIsNewToken }: UpdateZetraFormProps) {
    const { submitting } = useApiRequest();

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <TextFormField
                    variant="outlined"
                    name="registrationNumber"
                    label="CPF"
                    required
                    disabled
                />
            </Grid>

            <Grid item xs={12}>
                <TextFormField
                    label="Matrícula"
                    variant="outlined"
                    name="employeeCode"
                    fullWidth
                />
            </Grid>

            <Grid container sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                <Button
                    variant="text"
                    onClick={() => setIsNewToken(true)}
                    startIcon={<AddIcon htmlColor={colorMain} />}
                    sx={{ color: colorMain }}
                >
                    Adicionar token
                </Button>
            </Grid>

            <Grid item container sx={{ justifyContent: 'flex-end', display: 'flex', gap: 2 }}>
                <Button
                    startIcon={<CloseIcon htmlColor={theme.primary.main} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    onClick={onCloseDrawer}
                    disabled={submitting}
                >
                    Fechar
                </Button>
                <Button
                    name="btn-update-zetra"
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    startIcon={<RightIcon htmlColor={theme.common.white} sx={iconSx} />}
                >
                    Consultar
                </Button>
            </Grid>
        </Grid>
    );
}
